import {
  Card,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AuthContext from "../../../contexts/AuthContext";
import { FirestoreDB } from "../../../utils/firebase/firebase";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// import "./PatientUploadInfo.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--clr-theme-purple)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.action.hover,
  },
}));

function createData(Dosage, Drug_name, Givendosage, Tradename, Usage) {
  return { Dosage, Drug_name, Givendosage, Tradename, Usage };
}

const PatientUploadInfo = ({ videoDetails, patientID }) => {
  const { user, typeOfUser } = useContext(AuthContext);
  const [patientInformation, setPatientInformation] = useState();

  const getVideoFeedback = async (id) => {
    onSnapshot(
      doc(FirestoreDB, "videoLogs", id, "patientNotes", videoDetails.id),
      (querySnapshot) => {
        setPatientInformation(querySnapshot.data());
      }
    );
  };

  useEffect(() => {
    if (patientID) {
      getVideoFeedback(patientID);
    } else {
      getVideoFeedback(user.uid);
    }
  }, [patientID, videoDetails]);

  return (
    <div style={{ overflowY: "auto" }}>
      {patientInformation ? (
        <>
          <Typography
            variant="h6"
            sx={{
              color: "var(--clr-theme-purple)",
              fontFamily: "revert-layer",
              fontSize: "16px",
            }}
          >
            Patient Video Notes:
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontFamily: "revert-layer",
              color: "var(--clr-theme-gray)",
            }}
          >
            {patientInformation?.notes}
          </Typography>

          <Typography
            variant="h6"
            sx={{
              color: "var(--clr-theme-purple)",
              fontFamily: "revert-layer",
              fontSize: "16px",
            }}
          >
            Symptom:
          </Typography>
          {patientInformation?.symptoms.map((item, i) => (
            <ListItem dense color="red" key={i}>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="10px" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={{ color: "var(--clr-theme-gray)" }}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))}
          <Typography
            variant="h6"
            sx={{
              color: "var(--clr-theme-purple)",
              fontFamily: "revert-layer",
              fontSize: "16px",
            }}
          >
            Medication:
          </Typography>

          <TableContainer component={Paper}>
            <Table
              sx={{
                width: "99%",
              }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Drug_name</StyledTableCell>
                  <StyledTableCell align="right">Dosage</StyledTableCell>
                  <StyledTableCell align="right">Tradename</StyledTableCell>
                  <StyledTableCell align="right">Givendosage</StyledTableCell>
                  <StyledTableCell align="right"> Usage</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientInformation?.meds.map((item, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      {item?.drug_name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="right">
                      {item?.dosage}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item?.tradeName}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {item?.givenDosage}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {item?.usage}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Card>"This video has not been information"</Card>
      )}
    </div>
  );
};

export default PatientUploadInfo;
