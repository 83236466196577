import {
  AccessTime,
  Add,
  CalendarMonth,
  DuoOutlined,
  Google,
  People,
  RemoveCircleOutline,
  Room,
  Subject,
  Title,
  VideocamOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AuthContext from "../../contexts/AuthContext";
import { gapi } from "gapi-script";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const AddEvent = ({ close, reloadEvents }) => {
  const { user } = useContext(AuthContext);
  const zoom = {
    conferenceSolution: {
      key: { type: "addOn" },
      name: "Zoom",
    },
    entryPoints: [
      {
        entryPointType: "video",
        passcode: uuidv4().substring(0, 6),
        uri: `https://zoom.us/j/${user?.contactNo}?pwd=UHRqU0VwMjF3d1B0VXU5bFBRYWIxdz09`,
      },
    ],
  };
  const google = {
    createRequest: {
      requestId: "some16453",
      conferenceSolutionKey: {
        type: "hangoutsMeet",
      },
    },
  };

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const [title, setTitle] = useState();
  const [Loading, setLoading] = useState(false);
  const [date, setDate] = useState();
  const [startTime, setStartTime] = useState({
    time: Date.now(),
    stringFormat: new Date().toLocaleTimeString(),
  });
  const [endTime, setEndTime] = useState({
    time: new Date(Date.now() + 3600000),
    stringFormat: new Date(Date.now() + 3600000).toLocaleTimeString(),
  });
  const [location, setLocation] = useState("");
  const [meetType, setMeetType] = useState(google);
  const [selectMeetType, setSelectMeetType] = useState("hangoutsMeet");
  const [description, setDescription] = useState("");
  const [attendie, setAttendie] = useState();
  const [attendies, setAttendies] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    titleError: "",
    titleErrorShow: false,
    dateError: "",
    dateErrorShow: false,
    startTimeError: "",
    startTimeErrorShow: false,
    endTimeError: "",
    endTimeErrorShow: false,
    attendieError: "",
    attendieErrorShow: false,
    descriptionError: "",
    descriptionErrorShow: false,
    allErros: "",
    allErrosShow: false,
  });

  const addAttendie = (attendieEmail) => {
    const attendiesArray = [...attendies];
    if (attendieEmail?.trim().length > 8) {
      attendiesArray.push({ email: attendie });
      setAttendies(attendiesArray);
      setAttendie("");
      setErrorMessages({
        ...errorMessages,
        attendieError: "Please Enter Email",
        attendieErrorShow: false,
      });
    } else {
      setErrorMessages({
        ...errorMessages,
        attendieError: "Please Enter Email",
        attendieErrorShow: true,
      });
    }
  };

  const removeAttendie = (email) => {
    setAttendies([
      ...attendies.filter(
        (attendieEmailData) => attendieEmailData.email !== email
      ),
    ]);
  };

  const createNewEvent = () => {
    // setLoading(true)
    if (validatingEventFields()) {
      try {
        const event = {
          summary: title,
          location: location,
          description: description,
          start: {
            dateTime: date + "T" + startTime?.stringFormat,
            timeZone: timeZone,
          },
          end: {
            dateTime: date + "T" + endTime?.stringFormat,
            timeZone: timeZone,
          },
          conferenceData: meetType,
          recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
          attendees: attendies,
          //   attachments: [{
          //     'fileUrl': 'https://drive.google.com/open?id=' + user?.photoURL,
          //     'title': 'Moon Docs'
          // }],
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: 24 * 60 },
              { method: "popup", minutes: 10 },
            ],
          },
        };

        const request = gapi.client.calendar.events.insert({
          calendarId: "primary",
          resource: event,
          sendNotifications: true,
          conferenceDataVersion: 1,
          // supportsAttachments: true,
        });

        request.execute(function (event) {
          if (event?.htmlLink) {
            reloadEvents();
            close(false);
            setErrorMessages({
              ...errorMessages,
              allErros: "",
              allErrosShow: false,
            });
          } else {
            setErrorMessages({
              ...errorMessages,
              allErros: "! Event Creation Failed",
              allErrosShow: true,
            });
          }
        });
      } catch (err) {
        setErrorMessages({
          ...errorMessages,
          allErros: "! Event Creation Failed",
          allErrosShow: true,
        });
      }
    }
    // setLoading(false)
  };

  const validatingEventFields = () => {
    if (
      title?.trim().length > 0 &&
      (attendie?.length > 0 || attendies.length > 0) &&
      date?.length > 0
    ) {
      if (attendie?.length > 0) {
        setAttendies([...attendies, { email: attendie }]);
      }
      setErrorMessages({ ...errorMessages, allErros: "", allErrosShow: false });
      return true;
    } else {
      setErrorMessages({
        ...errorMessages,
        allErros: "Please Enter all * Mandatory Fields",
        allErrosShow: true,
      });
      return false;
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: 8,
        backgroundColor: "var(--clr-theme-white)",
        paddingTop: 10,
        paddingBottom: 5,
      }}
    >
      <Box className="textFieldBox-css" sx={{ paddingLeft: "15px" }}>
        {errorMessages?.allErrosShow && (
          <Typography color={"error"}>{errorMessages?.allErros}</Typography>
        )}
      </Box>
      <Box className="textFieldBox-css">
        <Title />
        <TextField
          sx={{ width: "90%" }}
          label="*Add Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          helperText={
            errorMessages.titleErrorShow && (
              <Typography color="error">{errorMessages.titleError}</Typography>
            )
          }
        />
      </Box>
      <Box className="textFieldBox-css">
        <CalendarMonth />
        {/* <Box sx={{ width: "90%", display: "flex", gap: "5px" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="*Choose Date"
              inputFormat="yyyy-mm-dd"
              value={date?.time}
              onChange={(e) => {
                console.log("date checking : ", new Date(e.target.value).getDate())
                if (new Date(e.target.value) > new Date() || (new Date(e.target.value).getDate() === new Date().getDate() && new Date(e.target.value).getFullYear() === new Date().getFullYear())) {
                  setDate(e.target.value);
                  setErrorMessages({ ...errorMessages, dateError: "", dateErrorShow: false })
                } else {
                  setErrorMessages({ ...errorMessages, dateError: "date must be grater than or equal to today", dateErrorShow: true })
                }

              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Box> */}
        <TextField
          sx={{ width: "90%" }}
          value={date}
          type={"date"}
          onChange={(e) => {
            if (
              new Date(e.target.value) > new Date() ||
              (new Date(e.target.value).getDate() === new Date().getDate() &&
                new Date(e.target.value).getFullYear() ===
                  new Date().getFullYear())
            ) {
              setDate(e.target.value);
              setErrorMessages({
                ...errorMessages,
                dateError: "",
                dateErrorShow: false,
              });
            } else {
              setErrorMessages({
                ...errorMessages,
                dateError: "date must be grater than or equal to today",
                dateErrorShow: true,
              });
            }
          }}
          helperText={
            errorMessages?.dateErrorShow && (
              <Typography color={"error"}>
                {errorMessages?.dateError}
              </Typography>
            )
          }
        />
      </Box>
      <Box className="textFieldBox-css">
        <AccessTime />
        <Box sx={{ width: "90%", display: "flex", gap: "5px" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              label="*Start-Time"
              value={startTime?.time}
              onChange={(newValue) => {
                setStartTime({
                  ...startTime,
                  time: newValue._d,
                  stringFormat: new Date(newValue._d).toLocaleTimeString(),
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <TimePicker
              label="*End-Time"
              value={endTime?.time}
              onChange={(newValue) => {
                if (newValue._d > startTime?.time) {
                  setEndTime({
                    ...endTime,
                    time: newValue._d,
                    stringFormat: new Date(newValue._d).toLocaleTimeString(),
                  });
                  setErrorMessages({
                    ...errorMessages,
                    endTimeError: "",
                    endTimeErrorShow: false,
                  });
                } else {
                  setErrorMessages({
                    ...errorMessages,
                    endTimeError: "end time must be grater than start time",
                    endTimeErrorShow: true,
                  });
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box className="textFieldBox-css" style={{ justifyContent: "center" }}>
        {errorMessages?.endTimeErrorShow && (
          <Typography color={"error"}>{errorMessages?.endTimeError}</Typography>
        )}
      </Box>
      <Box className="textFieldBox-css ">
        <Room />
        <TextField
          sx={{ width: "90%" }}
          value={location}
          label="Location - Optional"
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />
      </Box>
      <Box className="textFieldBox-css">
        <People />
        <TextField
          sx={{ width: "88%" }}
          value={attendie}
          label="*Enter Guest Mail"
          onChange={(e) => {
            setAttendie(e.target.value);
          }}
          helperText={
            errorMessages?.attendieErrorShow && (
              <Typography color={"error"}>
                {errorMessages?.attendieError}
              </Typography>
            )
          }
        />
        <Button
          startIcon={<Add />}
          onClick={() => {
            addAttendie(attendie);
          }}
        ></Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          paddingLeft: "38px",
        }}
      >
        {attendies?.map((item, i) => {
          return (
            <ListItem key={i} sx={{ padding: "0px" }}>
              <ListItemText>{item?.email}</ListItemText>
              <ListItemButton
                onClick={() => {
                  removeAttendie(item?.email);
                }}
              >
                <RemoveCircleOutline color="error" fontSize="18px" />
              </ListItemButton>
            </ListItem>
          );
        })}
      </Box>

      <Box className="textFieldBox-css">
        <VideocamOutlined />
        <Select
          sx={{ width: "90%" }}
          value={selectMeetType}
          onChange={(e) => {
            setSelectMeetType(e.target.value);
            if (e.target.value === "hangoutsMeet") {
              setMeetType(google);
            } else {
              setMeetType(zoom);
            }
          }}
        >
          <MenuItem value={"hangoutsMeet"} defaultChecked>
            <Google color="success" />
            {"  - Google Meeting"}
          </MenuItem>
          {/* <MenuItem value={"zoomMeet"}>
            <DuoOutlined color="primary" />
            {"  - Zoom Meeting"}
          </MenuItem> */}
        </Select>
      </Box>
      <Box className="textFieldBox-css">
        <Subject />
        <TextField
          sx={{ width: "90%" }}
          label="Description - Optional"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Box>
      <Box
        className="textFieldBox-css"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ borderRadius: 10 }}
          onClick={createNewEvent}
          startIcon={
            Loading === true ? (
              <CircularProgress size={25} sx={{ color: "yellow" }} />
            ) : (
              <Add />
            )
          }
        >
          Create Event
        </Button>
      </Box>
    </div>
  );
};

export default AddEvent;
