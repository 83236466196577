import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import MaskedImage from "../../../components/MaskedImage";
import StoryImage from "../../../assets/images/about_page.png";

import Srinija from "../../../assets/images/Srinija.png";
import Pooja from "../../../assets/images/Pooja.jpeg";
import Gracy from "../../../assets/images/Gracy.jpeg";
import Angela from "../../../assets/images/interns/Angela.jpg";
import Jayita from "../../../assets/images/interns/Jayita.jpg";
import Aditi from "../../../assets/images/interns/Aditi.jpg";
import Deeksha from "../../../assets/images/interns/Deeksha.jpg";
import Malia from "../../../assets/images/interns/Malia.jpg";
import cathyfarmer from "../../../assets/images/cathyfarmer.png";
import Advisor from "../../../assets/images/Advisor.png";
import Partners from "../HomePage/Partners";
import Footer from "../Footer";
import MemberCard from "./MemberCard";
import Fade from 'react-reveal/Fade'

import "./OurTeam.css";
import { Scrollbar } from "smooth-scrollbar-react";

const OurTeam = () => {
  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: '',
        },
      }}>
      <Box className="landingPageMainContainer">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MaskedImage
              currentStyle={{
                height: "40vh",
                backgroundImage: `url(${StoryImage})`,
              }}
            >
              <Fade left duration={1500} delay={400} >
                <Typography
                  variant="h2"
                  sx={{ fontFamily: "Lato", fontWeight: 700 }}
                >
                  Our Team
                </Typography>
              </Fade>
            </MaskedImage>
          </Grid>
          <Grid item xs={12}>
            <Fade bottom duration={1500} delay={400} >
              <Box
                className="flexBox fullWidth flexCenter"
                sx={{ paddingY: "5rem" }}
              >
                <Typography
                  variant="h6"
                  className="teamNote"
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: { xs: "1.1rem", md: "1.3rem" },
                    lineHeight: { xs: "1.4rem", md: "2rem" },
                    fontWeight: 500,
                  }}
                >
                  Mindly began with a shared passion for revolutionizing healthcare
                  through innovative technology. This team brings together a diverse
                  array of backgrounds in cognitive science, data science, public
                  health, and artificial intelligence, making us uniquely suited to
                  create Mindly, a groundbreaking platform designed to address the
                  challenges in neurological disorder diagnosis and treatment.
                </Typography>
              </Box>
            </Fade>
          </Grid>
          <Grid item xs={12}>
            <Box className="Team" gap={'8rem'}>
              <Fade left duration={1500} delay={400}>
                <MemberCard
                  image={Pooja}
                  name={"Pooja Shah"}
                  width={"200px"}
                  height={"200px"}
                  title="CEO"
                  linkedInURL={"https://www.linkedin.com/in/pooja-shah01/"}
                />
              </Fade>
              <Fade right duration={1500} delay={400}>
                <MemberCard
                  image={Srinija}
                  name={"Srinija Maganti"}
                  width={"200px"}
                  height={"200px"}
                  title="President"
                  linkedInURL={"https://www.linkedin.com/in/srinijamaganti/"}
                />
              </Fade>
            </Box>

            <Box className="Team" gap={'10rem'}>
              <Fade bottom duration={1500} delay={400} >
                <MemberCard
                  image={cathyfarmer}
                  width={"170px"}
                  height={"170px"}
                  name={"Cathy Farmer"}
                  title="Advisor"
                  linkedInURL={"https://www.linkedin.com/in/cathysf/"}
                />
              </Fade>
              <Fade bottom duration={1500} delay={400}>
                <MemberCard
                  image={Advisor}
                  width={"170px"}
                  height={"170px"}
                  name={"Bryna Siegel"}
                  title="Advisor"
                  linkedInURL={
                    "https://www.linkedin.com/in/bryna-siegel-01514b161/"
                  }
                />
              </Fade>
              <Fade bottom duration={1500} delay={400}>
                <MemberCard
                  image={Angela}
                  name={"Angela Du"}
                  width={"170px"}
                  height={"170px"}
                  title="Reseach Intern"
                  linkedInURL={"http://www.linkedin.com/in/angela-du-76b255281"}
                />
              </Fade>
              <Fade bottom duration={1500} delay={400}>
                <MemberCard
                  image={Jayita}
                  name={"Jayita Mukunda"}
                  width={"170px"}
                  height={"170px"}
                  title="Research Intern"
                  linkedInURL={"http://www.linkedin.com/in/jayitamukunda"}
                />
              </Fade>
              <Fade bottom duration={1500} delay={400}>
                <MemberCard
                  image={Aditi}
                  name={"Aditi Telang"}
                  width={"170px"}
                  height={"170px"}
                  title="Research Intern"
                  linkedInURL={"https://www.linkedin.com/in/adititelang/"}
                />
              </Fade>
              <Fade bottom duration={1500} delay={400}>
                <MemberCard
                  image={Deeksha}
                  name={"Deeksha Kasula"}
                  width={"170px"}
                  height={"170px"}
                  title="Intern"
                  linkedInURL={"https://www.linkedin.com/in/deeksha-kasula/"}
                />
              </Fade>
              <Fade bottom duration={1500} delay={400}>
                <MemberCard
                  image={Malia}
                  name={"Malia Kowal"}
                  width={"170px"}
                  height={"170px"}
                  title="Intern"
                  linkedInURL={"http://www.linkedin.com/in/malia-kowal-05b426216"}
                />
              </Fade>
              <Fade bottom duration={1500} delay={400}>
                <MemberCard
                  image={''}
                  name={"Musfiqur Rahaman"}
                  width={"170px"}
                  height={"170px"}
                  title="Intern"
                  linkedInURL={"https://www.linkedin.com/in/musfiqur-rahaman/"}
                />
              </Fade>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Partners size="small" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Footer />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Scrollbar>
  );
};

export default OurTeam;
