import { Divider, Typography } from "@mui/material";
import React from "react";
import Section from "./Section";

const EnhancedCare = () => {
  return (
    <div style={{ backgroundColor: "var(--clr-theme-white)" }}>
      <Section
        title={"Enhancing Neurological Care"}
        titleColor="var(--clr-theme-gray)"
        content={`With its user-friendly interface and ability to store patient data in
        one central location, Mindly streamlines the workflow of clinicians,
        saves time, and reduces the risk of errors. Its role in the healthcare
        market is significant, as it helps improve patient outcomes and
        contributes to the overall efficiency of care delivery.`}
        contentColor="var(--clr-theme-gray)"
        dividerColor="var(--clr-theme-purple)"
      ></Section>
    </div>
  );
};

export default EnhancedCare;
