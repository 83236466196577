import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import BrandLogo from "../../components/BrandLogo";

import "./LoginLayout.css";

const LoginLayout = ({ children, className }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={`loginLayout pageBgImage`}>
        <Box className="leftBox flexBox flexCenter">
          <BrandLogo newline={false} />

          <Typography className="mission-statement">
            Empowering exceptional care for neurological disorders in real time
            through data-driven symptom tracking and analysis.
          </Typography>
        </Box>
        <Box
          className={`rightBox flexBox flexCenter flexColumn ${
            className || ""
          }`}
        >
          {children}
        </Box>
      </div>
    </>
  );
};

export default LoginLayout;
