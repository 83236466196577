import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
  Chip,
} from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { DEFAULT_SIGNUP_SELECTION } from '../../../constants/AppConstants';
import AuthContext from '../../../contexts/AuthContext';
import NewLogo from '../../../components/BrandLogo';
import './Login.css';

const PAGE_IMAGE =
  'https://friendlystock.com/wp-content/uploads/2019/02/1-doctor-talking-to-patient-cartoon-clipart.jpg';

const REGID_PATTERN = /^(?=.*?[a-z])(?=.*?\d)(?=.*?)/i; // allow atleast one alphabet and one number

const SignUpInfo = ({
  signinHandler,
  signupHandler,
  redirectFromProvider,
  isSocialLoginShow = true,
  handleOpen,
}) => {
  const [type, setType] = useState(DEFAULT_SIGNUP_SELECTION);
  const [docInput, setDocInput] = useState('');
  const [customError, setCustomError] = useState({
    show: false,
    message: '',
  });

  const { registerWithGoogle, loginWithGoogleProvider } =
    useContext(AuthContext);

  const radioChangeHandler = (e) => {
    setType(e.target.value);
    resetFields();
  };

  const resetFields = () => {
    setCustomError({ show: false, message: '' });
  };

  const validateDocRegID = (inputText) => {
    //  TODO ! if any external validations, add here !!
    return inputText?.trim()?.length > 5 && REGID_PATTERN.test(inputText);
  };

  const inputChangeHandler = (e) => {
    if (validateDocRegID(e.target.value)) {
      setCustomError({ show: false, message: '' });
    } else {
      setCustomError({ show: true, message: 'Invalid registration ID !! ' });
    }
    setDocInput(e.target.value);
  };

  const gotoNext = async () => {
    try {
      if (type === 'DOCTOR' && !docInput.trim()) {
        setCustomError({ show: true, message: 'Please fill the registration ID.' });
        return;
      }
      var isValid = false;
      var params = {
        type: type,
      };

      if (type === 'DOCTOR') {
        isValid = validateDocRegID(docInput);
        params.regID = docInput;
      } else if (type === 'PATIENT') {
        isValid = true;
      }

      if (isValid) {
        if (redirectFromProvider.status) {
          console.log(redirectFromProvider.status);
          // TODO ! Directly login to firebase and goto home

          const response = await registerWithGoogle(
            redirectFromProvider.data,
            params
          );
          console.log('gmail response: ', response);
          if (response.status) {
            if (type === 'DOCTOR') {
              // navigate("/");
              signinHandler();
            } else {
              handleOpen && handleOpen();
              signupHandler({ ...response.data, provider: 'GOOGLE' });
            }
            // navigate("/");
          }
        } else {
          console.log(' at signup handler if ::: ');
          signupHandler({ ...params, provider: 'PASSWORD' });
        }
      }
      // } else {
      //   setCustomError({
      //     show: true,
      //     message: "Invalid input !!",
      //   });
      // }
    } catch (e) {
      console.log(e);
      alert('field required');
    }
  };

  return (
    <div
      className='fullWidth flexBox flexColumn'
      style={{
        textAlign: 'center',
        gap: 20,
      }}
    >
      <NewLogo newline={false} />
      <div
        className='flexBox flexColumn'
        style={{
          alignItems: 'start',
          gap: 15,
        }}
      >
        <div
          className='flexBox flexColumn fullWidth'
          style={{
            alignItems: 'start',
            textAlign: 'left',
          }}
        >
          <Typography variant='h4' sx={{ fontFamily: 'Lato', fontWeight: 400 }}>
            Welcome to Mindly.
          </Typography>
          <Chip
            variant='filled'
            component='p'
            sx={{ my: 2 }}
            label='Continue registration and proceed to dashboard.'
          ></Chip>

          <Typography
            gutterBottom
            variant='subtitle1'
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
            }}
          >
            You are a:
          </Typography>
        </div>

        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            value={type}
            onChange={radioChangeHandler}
            className='radio'
          >
            <FormControlLabel
              value='DOCTOR'
              control={<Radio />}
              label='Healthcare Professional'
            />

            <FormControlLabel
              value='PATIENT'
              control={<Radio />}
              label='Patient'
            />
            <FormControlLabel control={<Radio />} label='Researcher' disabled />
          </RadioGroup>

          {type === 'DOCTOR' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                mt: 3,
              }}
            >
              <Typography variant='caption'>Enter Registration ID </Typography>
              <TextField fullWidth required onChange={inputChangeHandler} />
            </Box>
          )}
          {customError.show && (
            <Typography className='errorMessage' variant='caption'>
              {customError.message}
            </Typography>
          )}

          {customError.show && type === 'DOCTOR' && (
            <List dense className='instructions'>
              <ListItem>
                <ListItemText primary=' - Atleast 6 characters.' />
              </ListItem>
              <ListItem>
                <ListItemText primary=' - Only letters and numbers are allowed.' />
              </ListItem>
              <ListItem>
                <ListItemText primary=' - Choose atleast one letter and one number.' />
              </ListItem>
            </List>
          )}
        </FormControl>
      </div>
      <Button
        onClick={gotoNext}
        variant='contained'
        color='success'
        sx={{ background: '#9291f0', textTransform: 'capitalize' }}
      >
        Next
      </Button>
      {isSocialLoginShow && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Divider />
          <Typography
            sx={{
              marginTop: 2,
              fontFamily: 'Karla',
              fontWeight: 700,
              fontSize: '1rem',
              color: 'var(--clr-theme-gray)',
            }}
          >
            Already have an account ?
            <Link
              onClick={signinHandler}
              style={{
                marginLeft: '5px',
                cursor: 'pointer',
                fontFamily: 'Karla',
              }}
            >
              Sign In
            </Link>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default SignUpInfo;
