import { MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const LineChartComponent = ({ data }) => {
  const [selectedKey, setSelectedKey] = useState("NOSE");

  const handleFilterChange = (key) => {
    setSelectedKey(key);
  };

  const extractKeypointValues = (keypoints, selectedKey) => {
    try {
      const keypointsObj =
        typeof keypoints === "string" ? JSON.parse(keypoints) : keypoints;
      const selectedKeypoint = keypointsObj[selectedKey];

      if (typeof selectedKeypoint === "string") {
        return JSON.parse(selectedKeypoint.replace(/'/g, '"'));
      } else if (typeof selectedKeypoint === "object") {
        return selectedKeypoint;
      } else {
        return { x: 0, y: 0, z: 0 };
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return { x: 0, y: 0, z: 0 };
    }
  };

  const filteredData = data.map((item) => {
    const selectedKeypoint = extractKeypointValues(item.keypoints, selectedKey);
    return {
      frame: item.frame,
      x: selectedKeypoint.x,
      y: selectedKeypoint.y,
      z: selectedKeypoint.z,
    };
  });

  return (
    <div className="fullWidth fullHeight " style={{ padding: "10px" }}>
      <div
        className="fullWidth flexBox"
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Lato",
            color: "var(--clr-theme-purple)",
            fontWeight: 700,
          }}
        >
          Process Video Data
        </Typography>
        <Select
          sx={{ minWidth: 250 }}
          value={selectedKey}
          onChange={(e) => handleFilterChange(e.target.value)}
          // className="dropdown"
          // style={{
          //   margin: "1rem 1rem 1rem 40rem",
          //   boxShadow: " 0.5px 0.5px 0.5px 1px lightpurple",
          //   borderRadius: "0.4rem",
          // }}
        >
          <MenuItem value="NOSE">Head Movement</MenuItem>
          <MenuItem value="LEFT_WRIST">Left Hand Movement</MenuItem>
          <MenuItem value="RIGHT_WRIST">Right Hand Movement</MenuItem>
        </Select>
      </div>
      <ResponsiveContainer width="95%" height={"82%"}>
        <LineChart
          data={filteredData}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <XAxis
            dataKey="frame"
            label={{ value: "Frame", position: "insideBottom", offset: -12 }}
          />
          <YAxis
            label={{ value: "Value", angle: -90, position: "insideLeft" }}
          />

          <Legend verticalAlign="top" height={36} />
          <Line
            type="monotone"
            dataKey="x"
            stroke="#8884d8"
            name="X"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="y"
            stroke="#82ca9d"
            name="Y"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="z"
            stroke="#ffc658"
            name="Z"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComponent;
