import React from "react";
import {
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Fab,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import "./FullScreenDialog.css";

const FullScreenDialog = ({
  children,
  title,
  buttonText,
  buttonColor,
  buttonIcon,
  buttonVariant,
  customClass = "",
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Fab
        className={`${customClass}`}
        color={buttonColor}
        onClick={handleClickOpen}
        variant={buttonVariant}
        size="small"
      >
        {buttonText}
        {buttonIcon}
      </Fab>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="toolbar">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="fullHeight fullWidth" style={{ padding: "10px" }}>
          {children}
        </div>
      </Dialog>
    </div>
  );
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default FullScreenDialog;
