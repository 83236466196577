export const DEFAULT_SIGNUP_SELECTION = "DOCTOR";

export const TREATMENT_TEMPLATE = {
  therapies: [],
  meds: [],
  rating: 0,
  symptom: "",
  diagnosis: "",
};

export const BEHAVIOUR_TEMPLATE = {
  behaviour: "",
  behaviour_rating: 0,
  antecedents: "",
  consequences: "",
};

export const GOALS_TEMPLATE = {
  behaviour: "",
  GoalsDescription:"",
  BaselineDescription:"",
};