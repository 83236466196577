import {
  Box,
  Dialog,
  DialogContent,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import MaskedImage from "../../../components/MaskedImage";
import HomePageImage from "../../../assets/images/home_page.png";
import Work from "./Work";
import Partners from "./Partners";
import Footer from "../Footer";
import LoginScreen from "../LoginScreen";
import EnhancedCare from "./EnhancedCare";

import "./HomePage.css";
import Stats from "./Stats";
import { Scrollbar } from "smooth-scrollbar-react";
import Fade from "react-reveal/Fade";

const HomePage = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const position = props.scrolling.current.getBoundingClientRect();
    const handleScroll = () => {
      props.setScrollPosition(props.scrolling.current.scrollTop)
    }

    props.scrolling.current.addEventListener('scroll', handleScroll)

    return () => { props.setScrollPosition(0) }
  }, [])

  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: '',
        },
      }}>
      <Box className="landingPageMainContainer">
        <Grid container spacing={0} key={"mainContainer"} ref={props.scrolling}>
          <Grid item xs={12} className="topContainer">
            <MaskedImage
              currentStyle={{
                height: "80vh",
                backgroundImage: `url(${HomePageImage})`,
              }}
            >
              <Box
                className="flexBox flexColumn"
                sx={{ mb: "6rem", gap: "2rem", ml: "0.8" }}
              >
                <Fade bottom duration={1500} delay={400}>
                  <Typography
                    className="mainHomeHeader"
                    variant="h3"
                    sx={{
                      maxWidth: { lg: "50%", md: "80%", sm: "90%", xs: "90%" },
                      fontSize: "calc(18px + 2vw)",
                      paddingX: "10px",
                      fontFamily: "Lato"

                    }}
                  >
                    Empowering exceptional care for neurological disorders in real
                    time through data-driven symptom tracking and analysis.
                  </Typography>
                </Fade>
                <Fade left duration={1500} delay={400}>
                  <Fab
                    variant="extended"
                    className="loginButton"
                    sx={{
                      width: { xs: "10rem", sm: "10rem", md: "15rem" },
                      fontSize: { md: "1.5rem", sm: "1.1rem", xs: "1rem" },
                      height: { xs: "2rem", sm: "2.5rem", md: "3.3rem" },

                    }}
                    onClick={handleClickOpen}
                  >
                    Log in
                  </Fab>
                </Fade>
              </Box>
            </MaskedImage>
            <Dialog open={open} onClose={handleClose} className="LoginModal">
              <DialogContent className="content-dialog">
                <LoginScreen />
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            <EnhancedCare />
          </Grid>
          <Grid item xs={12}>
            <Stats />
          </Grid>
          <Grid item xs={12}>
            <Work />
          </Grid>
          <Grid item xs={12}>
            <Partners />
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </Box>
    </Scrollbar>
  );
};

export default HomePage;
