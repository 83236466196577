import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { ExpandMore } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";

import "./Historical.css";

const data = [
  {
    name: "Feb",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "march",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "April",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "May",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "June",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "July",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Aug",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BehaviourGoals = ({ selectedDocData }) => {
  const [selectedBehavior, setSelectedBehavior] = useState("");

  const behavioralGoals =
    selectedDocData?.docData?.summary?.BehavioralGoalsAndBaselines || [];

  const crisisPlanSummary =
    Array.isArray(selectedDocData?.docData?.summary?.CrisisPlanSummary)
      ? selectedDocData.docData.summary.CrisisPlanSummary
      : [];

  const handleBehaviorChange = (event) => {
    setSelectedBehavior(event.target.value);
  };

  const selectedGoal = behavioralGoals.find(
    (behavior) => behavior?.BehaviorName === selectedBehavior
  );

  const normalizeString = (str) => str?.toLowerCase().replace(/[^a-zA-Z0-9\s]/g, '').trim();

  const filteredCrisisPlanSummary = crisisPlanSummary?.filter(
    (summary) =>
      normalizeString(selectedBehavior).includes(normalizeString(summary?.TargetBehavior)) ||
      normalizeString(summary?.TargetBehavior).includes(normalizeString(selectedBehavior))
  );

  return (
    <Box
      className="fullHeight fullWidth behaviourGoals"
      sx={{ p: 1, overflowY: "auto" }}
    >
      <Grid container spacing={2}>
        {/* Dropdown and Descriptions Section */}
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Lato",
              fontWeight: 700,
            }}
            gutterBottom
          >
            Behaviour Goals
          </Typography>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel color="secondary">Select Behaviour</InputLabel>
            <Select
              MenuProps={MenuProps}
              value={selectedBehavior}
              onChange={handleBehaviorChange}
              fullWidth
              variant="outlined"
              label="Select Behavior"
              sx={{ textOverflow: "ellipsis" }}
              color="secondary"
            // disabled={behavioralGoals.length === 0}
            >
              {/* <MenuItem value={"All"}>{"Select Behaviour"}</MenuItem> */}
              {behavioralGoals && behavioralGoals.length > 0 ? (
                behavioralGoals.map((behavior, index) => (
                  <MenuItem key={index} value={behavior.BehaviorName}>
                    {behavior?.BehaviorName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="">
                  <Typography variant="body2">
                    No behaviors available.
                  </Typography>
                </MenuItem>
              )}
            </Select>
          </FormControl>

          {selectedGoal ? (
            <Box className="fullWidth flexBox flexColumn" gap={1}>
              <Box
                className="fullWidth"
                sx={{
                  p: 2,
                  backgroundColor: "var(--clr-theme-lgray)",
                  borderRadius: 3,
                }}
              >
                <Typography fontFamily={"Lato"} variant="h6" fontWeight={700}>
                  Summary of Goals
                </Typography>
                <Typography
                  fontFamily={"Lato"}
                >{`${selectedGoal?.Baseline} ${selectedGoal?.Goal}`}</Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "var(--clr-theme-lgray)",
                  borderRadius: 3,
                  p: 1,
                }}
              >
                <Typography
                  fontFamily={"Lato"}
                  variant="h6"
                  fontWeight={700}
                  ml={1}
                >
                  Summary of Crisis Plans
                </Typography>
                {filteredCrisisPlanSummary?.length > 0 ? (
                  filteredCrisisPlanSummary?.map((summary, index) => (
                    <Box key={index} sx={{ p: 1, mt: 1 }}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <Typography fontWeight={600} fontFamily={"Lato"}>
                            {summary?.TargetBehavior}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography fontFamily={"Lato"}>
                            <strong>Active Steps for Defusing Crisis:</strong>{" "}
                            <p style={{ color: "gray", fontFamily: "Karla" }}>
                              {summary?.ActiveStepsforDefusingCrisis}
                            </p>
                          </Typography>
                          <Typography fontFamily={"Lato"}>
                            <strong>Antecedent Interventions:</strong>{" "}
                            <p style={{ color: "gray", fontFamily: "Karla" }}>
                              {summary?.AntecedentInterventions}
                            </p>
                          </Typography>
                          <Typography fontFamily={"Lato"}>
                            <strong>Consequence Interventions:</strong>{" "}
                            <p style={{ color: "gray", fontFamily: "Karla" }}>
                              {summary?.ConsequenceInterventions}
                            </p>
                          </Typography>
                          <Typography fontFamily={"Lato"}>
                            <strong>Definition:</strong>
                            <p style={{ color: "gray", fontFamily: "Karla" }}>
                              {summary?.Definition}
                            </p>
                          </Typography>
                          <Typography fontFamily={"Lato"}>
                            <strong>Function:</strong>
                            <p style={{ color: "gray", fontFamily: "Karla" }}>
                              {summary?.Function}
                            </p>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ))
                ) : (
                  <Typography fontFamily={"Lato"} ml={1}>
                    No crisis plan summary available.
                  </Typography>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              className="flexBox flexColumn flexCenter"
              sx={{
                height: "50%",
                backgroundColor: "var(--clr-theme-lgray)",
                mt: 1,
                borderRadius: 2
              }}
            >
              <Typography fontFamily={"Lato"} >
                No behavioral goals available at this time.
              </Typography>
            </Box>
          )}
        </Grid>

        {/* Graph Section */}
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant="h5"
            fontFamily={"Lato"}
            textAlign={"center"}
            gutterBottom
            fontWeight={700}
          >
            Goals Over Time
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: {
                xs: 200,
                sm: 400,
                md: 300,
              },
            }}
          >
            <ResponsiveContainer width="99%" height={"100%"}>
              <LineChart
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                {/* <YAxis dataKey={""}/> */}
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                {/* <Line type="monotone" dataKey="amt" stroke="#82ca9d" /> */}
              </LineChart>
            </ResponsiveContainer>
          </Box>
          <Box className="flexBox fullWidth expandButton">
            <CustomButton customClass="customButton">
              Expand/View All
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BehaviourGoals;
