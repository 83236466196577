import { Box, Stack } from "@mui/material";
import React from "react";

const MaskedImage = ({ children, currentStyle }) => {
  return (
    <>
      <Box
        className="backgroundImageContainer"
        style={{
          ...currentStyle,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          zIndex: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          className="imageMask"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the transparency here (0.5 = 50%)
          }}
        />
        <Stack
          gap={10}
          sx={{
            marginLeft: "10%",
            zIndex: 9999,
            color: "var(--clr-theme-white)",
            position: "absolute",
          }}
        >
          {children}
        </Stack>
      </Box>
    </>
  );
};

export default MaskedImage;
