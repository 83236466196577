import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

import firebaseConfig from "./firebaseConfig.json";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const storage = getStorage(app);
const functions = getFunctions(app);

export {
  auth as FirebaseAuth,
  app as FirebaseInstance,
  db as FirestoreDB,
  storage as FirebaseStorage,
  functions as CloudFunctions,
};
