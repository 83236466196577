import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import MainLayout from "../../hoc/MainLayout";
import Profile from "../../pages/Settings/Profile";
import Paymentmethod from "../../pages/Settings/Paymentmethod";
import Transactionhistory from "./Transactionhistory";
import { useState } from "react";

import "./Settings.css";
import ChangePassword from "./ChangePassword";
import AddPatient from "./AddPatient";
import DeleteAccount from "./Deleteaccount";
import AuthContext from "../../contexts/AuthContext";
import { useContext } from "react";
import { Grid } from "@mui/material";
import AddDoctor from "./AddDoctor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pr: 3, pl: 3, pb: 3, height: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const { typeOfUser } = useContext(AuthContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
            lg: "row",
          },
          padding: "1rem",
        }}
      >
        <Grid item xs={12} md={9} lg={9}>
          <Box sx={{ height: "100%" }}>
            <TabPanel value={value} index={0}>
              <Profile />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ChangePassword />
            </TabPanel>
            {typeOfUser === "DOCTOR" ? (
              <TabPanel value={value} index={2}>
                <AddPatient />
              </TabPanel>
            ) : (
              <TabPanel value={value} index={2}>
                <AddDoctor />
              </TabPanel>
            )}
            <TabPanel value={value} index={3}>
              <DeleteAccount />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Paymentmethod />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Transactionhistory />
            </TabPanel>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          lg={3}
          sx={{ pb: "1rem", pl: "1rem", pr: "1rem" }}
        >
          <Box className="setting">
            <Box>
              <Tabs
                onChange={handleChange}
                className="settings-tabs"
                orientation="vertical"
                value={value}
                indicatorColor="secondary"
                variant="fullWidth"
              >
                <Tab label="Profile" />
                <Tab label="Change Password" />

                {typeOfUser === "DOCTOR" ? (
                  <Tab label="Add Patient" />
                ) : (
                  <Tab label="Add Healthcare Professional" />
                )}
                <Tab label="Delete Account" />
                <Tab label="Payment Method" disabled />
                <Tab label="Transaction History" disabled />
              </Tabs>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <Box sx={{ display: "flex", height: "100%" }}>
        <TabPanel value={value} index={0}>
          <Profile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChangePassword />
        </TabPanel>
        {typeOfUser === "DOCTOR" ? (
          <TabPanel value={value} index={2}>
            <AddPatient />
          </TabPanel>
        ) : (
          <TabPanel value={value} index={2}>
            <Adddoctors />
          </TabPanel>
        )}

        <TabPanel value={value} index={3}>
          <Paymentmethod />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Transactionhistory />
        </TabPanel>

        <Box sx={{ display: "flex" }} className="setting">
          <Box>
            <Tabs
              onChange={handleChange}
              className="settings-tabs"
              orientation="vertical"
              value={value}
              indicatorColor="secondary"
              variant="fullWidth"
            >
              <Tab label="Profile" />
              <Tab label="Change Password" />

              {typeOfUser === "DOCTOR" ? (
                <Tab label="Add Patient" />
              ) : (
                <Tab label="Add Doctor" />
              )}

              <Tab label="Payment Method" />
              <Tab label="Transaction History" />
            </Tabs>
          </Box>
        </Box>
      </Box> */}
    </MainLayout>
  );
}
