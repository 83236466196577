import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Fade } from "react-reveal";
import LightSpeed from "react-reveal/LightSpeed";
import { ToastContainer, toast } from "react-toastify";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import { FirestoreDB } from "../../../utils/firebase/firebase";

function SignUpPilot() {
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [otherInputValue, setOtherInputValue] = useState("");

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const resetFields = () => {
    setName("");
    setEmail("");
    setPhoneNumber("");
    setDropdownValue("");
    setOtherInputValue("");
  };

  const handleSubmit = async () => {
    try {
      const mailDocId = uuidv4();

      if (email && phoneNumber) {
        const mailRef = doc(FirestoreDB, "emails", mailDocId);
        await setDoc(mailRef, {
          type: "pilotSignUp",
          name,
          toEmail: email,
          phoneNumber,
          opted: dropdownValue ? dropdownValue : otherInputValue,
          date: Timestamp.now(),
        });
        toast.success("Successfully sent mail", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      resetFields();
      setOpenModal(false);
    } catch (e) {
      toast.error(e.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }} align="center">
      <ToastContainer autoClose={true} />
      <Fade bottom duration={1500} delay={400}>
        <Typography
          variant="h3"
          sx={{
            fontFamily: "Lato",
            fontWeight: 300,
            fontSize: "3rem",
            textAlign: "center",
            color: "#fff",
          }}
        >
          Sign up for your pilot
        </Typography>
      </Fade>
      <Fade bottom duration={1500} delay={400}>
        <Divider
          sx={{
            // border: 1,
            borderColor: "var(--clr-theme-white)",
            width: "15rem",
            margin: "2rem",
          }}
        />
      </Fade>
      <Fade bottom duration={1500} delay={400}>
        <Typography
          variant="subtitle2"
          mb={2}
          sx={{
            fontFamily: "Lato",
            fontSize: { sm: "1rem", md: "1rem", lg: "1.2rem" },
            lineHeight: { sm: "1.2rem", md: "1.2rem", lg: "1.6rem" },
            color: "var(--clr-theme-gray)",
          }}
        >
          Mindly can help improve patient care.
        </Typography>
      </Fade>
      <LightSpeed bottom duration={1500} delay={400}>
        <Fab
          variant="extended"
          className="joinpilot"
          sx={{
            width: "fit-content",
            fontSize: { md: "1.2rem", sm: "1rem", xs: "1rem" },
            textTransform: "none",
            padding: "0px 30px",
          }}
          onClick={handleOpenModal}
        >
          Join the Pilot
        </Fab>
      </LightSpeed>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle color="var(--clr-theme-purple)">
          Join the Pilot
          <CloseIcon
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              cursor: "pointer",
              color: "black",
            }}
            onClick={handleCloseModal}
          />
        </DialogTitle>
        <DialogContent>
          <Box mt={2} sx={{ textAlign: "center" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Email address"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Phone number"
                  fullWidth
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  variant="outlined"
                  label="Select"
                  fullWidth
                  value={dropdownValue}
                  onChange={(e) => setDropdownValue(e.target.value)}
                  sx={{ textAlign: "left" }}
                >
                  <MenuItem value="healthcare_professional">
                    Healthcare professional
                  </MenuItem>
                  <MenuItem value="researcher">Researcher</MenuItem>
                  <MenuItem value="parent">Parent</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>
              </Grid>
              {dropdownValue === "other" && (
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Other"
                    fullWidth
                    value={otherInputValue}
                    onChange={(e) => setOtherInputValue(e.target.value)}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Fab
            size="medium"
            onClick={handleSubmit}
            variant="extended"
            sx={{
              fontFamily: "Lato",
              fontSize: "1rem",
              background: "var(--clr-theme-purple)",
              color: "var(--clr-theme-white)",
              textTransform: "none",
              ":hover": {
                background: "var(--clr-theme-purple-50)",
              },
            }}
          >
            Sign Up
          </Fab>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default SignUpPilot;
