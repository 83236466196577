import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Fade from 'react-reveal/Fade'

const Section = ({
  title,
  titleColor,
  content,
  contentColor,
  children,
  dividerColor,
  size,
}) => {
  return (
    <Box
      className="homeSection flexBox fullWidth flexCenter flexColumn"
      sx={{ paddingY: "3rem" }}
    >
      <Fade bottom duration={1500} delay={400}>
        <Typography
          variant="h3"
          sx={{
            fontFamily: "Lato",
            fontWeight: 300,
            fontSize: size === "small" ? "2rem" : "3rem",
            textAlign: "center",
            color: titleColor,
          }}
        >
          {title}
        </Typography>
      </Fade>
      <Fade bottom duration={1500} delay={400}>
        <Divider
          sx={{
            border: 1,
            borderColor: dividerColor,
            width: size === "small" ? "10rem" : "15rem",
            margin: "3rem",
          }}
        />
      </Fade>
      {content && (
        <Typography
          variant="h6"
          sx={{
            minWidth: "260px",
            width: "58%",
            fontFamily: "Karla",
            fontWeight: 400,
            fontSize: { sm: '1rem', md: '1.2rem', lg: '1.25rem' },
            lineHeight: { sm: '1.5rem', md: '1.8rem', lg: '2rem' },
            color: contentColor,
            textAlign: "center",
          }}
        >
          <Fade duration={1500} delay={400} bottom>
            {content}
          </Fade>
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default Section;
