import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { collection, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { FirestoreDB } from '../../../../utils/firebase/firebase';
import Backdrop from '../../../Progress/Backdrop';

const Editbehaviors = ({ data, onClose, onUpadateBehaviour, loading }) => {
  const [isCustomBehaviour, setIsCustomBehaviour] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedBehaviorCount, setSelectedBehaviorCount] = useState(
    data?.behaviour_rating
  );
  const [behaviorData, setBehaviorData] = useState(data);
  const [behavioursName, setBehavioursName] = useState([]);

  useEffect(() => {
    getBehaviors();
  }, []);

  console.log(behaviorData, 'behaviorData');
  const updateBehaviours = (dataList, type) => {
    if (type === 'Behaviour') {
      setBehaviorData((prev) => {
        return {
          ...prev,
          behaviour: dataList,
        };
      });
    } else if (type === 'BehaviourRating') {
      setBehaviorData((prev) => {
        return {
          ...prev,
          behaviour_rating: dataList,
        };
      });
    } else if (type === 'Antecedents') {
      setBehaviorData((prev) => {
        return {
          ...prev,
          antecedents: dataList,
        };
      });
    } else if (type === 'Consequences') {
      setBehaviorData((prev) => {
        return {
          ...prev,
          consequences: dataList,
        };
      });
    }
  };

  const getBehaviors = async () => {
    const behaviorRef = collection(FirestoreDB, 'behaviour');
    return onSnapshot(
      behaviorRef,
      (querySnapshot) => {
        const behaviorIds = querySnapshot.docs.map((doc) => doc.id);
        setBehavioursName(behaviorIds);
      },
      (error) => {
        console.log('Error listening to symptoms:', error);
      }
    );
  };

  const behaviourHandler = (e) => {
    console.log(e.target.value, 'abcd');
    if (e.target.value !== 'other') {
      updateBehaviours(e.target.value, 'Behaviour');
      setIsCustomBehaviour(false);
    } else {
      setIsCustomBehaviour(true);
    }
  };

  const frequencyHandler = (e) => {
    const enteredValue = e.target.value;
    const numericValue = parseInt(enteredValue);

    if (!isNaN(numericValue)) {
      const updatedValue = Math.max(0, Math.min(numericValue, 50));
      setSelectedBehaviorCount(updatedValue);
      updateBehaviours(updatedValue, 'BehaviourRating');
    } else {
      setSelectedBehaviorCount(0);
    }
  };

  const incrementCount = () => {
    const incrementCount = selectedBehaviorCount + 1;
    const updatedCount = Math.min(incrementCount, 50);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(updatedCount, 'BehaviourRating');
  };

  const decrementCount = () => {
    const newCount = selectedBehaviorCount - 1;
    const updatedCount = Math.max(newCount, 0);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(updatedCount, 'BehaviourRating');
  };

  return (
    <Box>
      <Card
        className="cardContent"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 5,
          padding: 3,
          backgroundColor: 'rgba(229, 229, 250, 1)',
        }}
      >
        <CardContent className="flexBox flexColumn fullWidth">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select behaviour
            </InputLabel>
            <Select
              label="Select behavior"
              value={isCustomBehaviour ? 'other' : behaviorData?.behaviour}
              onChange={behaviourHandler}
              disabled={loading}
            >
              {behavioursName?.length > 0 &&
                behavioursName?.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {isCustomBehaviour && (
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label={`Add behaviour `}
                onChange={(e) => {
                  updateBehaviours(e.target.value, 'Behaviour');
                }}
                disabled={loading}
              />
            )}
          </FormControl>
          <Box className="flexBox flexColumn" sx={{ gap: 2 }}>
            <Box
              className="flexBox flexCenter"
              sx={{ justifyContent: 'space-between', mt: 2 }}
            >
              <Typography variant="subtitle2" fontWeight={'bold'}>
                {/* Selected Behavior:{" "}
                  {isCustomBehaviour ? "Custom Behavior" : behaviour?.behaviour} */}
                Frequency count
              </Typography>
              <Box className="flexBox flexCenter" sx={{ gap: 2 }}>
                <Fab
                  onClick={decrementCount}
                  size="small"
                  sx={{
                    backgroundColor: 'var(--clr-theme-purple)!important',
                    fontSize: '2rem',
                    color: 'var(--clr-theme-white)',
                  }}
                  disabled={loading}
                >
                  -
                </Fab>
                {/* <Typography>{selectedBehaviorCount}</Typography> */}
                <TextField
                  type="tel"
                  inputProps={{
                    pattern: '[0-50]*', // Allows only numeric input
                    inputMode: 'numeric', // Specifies a numeric keyboard
                  }}
                  sx={{ width: '55px' }}
                  value={selectedBehaviorCount}
                  onChange={frequencyHandler}
                  disabled={loading}
                />

                <Fab
                  onClick={incrementCount}
                  size="small"
                  sx={{
                    backgroundColor: 'var(--clr-theme-purple) !important',
                    fontSize: '1.5rem',
                    color: 'var(--clr-theme-white)',
                  }}
                  disabled={loading}
                >
                  +
                </Fab>
              </Box>
            </Box>

            <TextField
              label="Antecedents"
              multiline
              rows={4}
              value={behaviorData?.antecedents}
              fullWidth
              onChange={(e) => {
                updateBehaviours(e.target.value, 'Antecedents');
              }}
              disabled={loading}
            />
            <TextField
              label="Consequences"
              multiline
              rows={4}
              fullWidth
              value={behaviorData?.consequences}
              onChange={(e) => {
                updateBehaviours(e.target.value, 'Consequences');
              }}
              disabled={loading}
            />
          </Box>
        </CardContent>
        <CardActions className="flexBox flexCenter fullWidth" sx={{ gap: 2 }}>
          {/* <Box className="flexBox flexCenter fullWidth" sx={{ gap: 2,border:"1px solid" }}> */}
          <Button
            fullWidth
            sx={{
              border: '1px solid var(--clr-theme-purple) !important',
              textTransform: 'capitalize',
              color: 'var(--clr-theme-purple)',
            }}
            variant="outlined"
            color="primary"
            onClick={onClose}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => onUpadateBehaviour(behaviorData)}
            disabled={loading}
            sx={{
              textTransform: 'capitalize',
              backgroundColor: 'var(--clr-theme-purple) !important',
              '&:disabled': {
                color: 'var(--clr-theme-white) !important',
                backgroundColor: 'rgb(211, 211, 211) !important',
              },
            }}
          >
            Update
          </Button>
          {/* </Box> */}
        </CardActions>
        {loading && (
          <CircularProgress
            size={'4rem'}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              color: 'var(--clr-theme-purple)',
            }}
          />
        )}
      </Card>
    </Box>
  );
};

export default Editbehaviors;
