import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Rating,
  Paper,
  Box,
  AppBar,
  Card,
  CardHeader,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardActions,
  Button,
  TextField,
  CircularProgress,
  Tooltip,
  Slider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AuthContext from "../../../contexts/AuthContext";
import Progress from "../../Progress";
import "./PatientReview.css";
import { Check, Clear, Edit, Padding } from "@mui/icons-material";
import { FirestoreDB } from "../../../utils/firebase/firebase";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  UpdatePatientNotesToVault,
  UpdatedDoctorFeedbackNotesToVault,
  editDoctorFeedbackBehaviours,
  editDoctorFeedbackTreatments,
} from "../../../api";
import { DataContext } from "../../../contexts/DataProvider";
import EditVideoFeedBack from "./EditVideoFeedBack";
import Editbehaviors from "./EditBehaviors";
// import Progress from "../../Progress";

const PatientReview = ({ videoDetails, feedback, patientNote, patientID }) => {
  const feedbackTreatments = feedback?.data?.treatments;
  const feedbackBehavior = feedback?.data?.behaviours;
  const { user, typeOfUser } = useContext(AuthContext);
  const [expanded, setExpanded] = React.useState(0);
  const [expandedPanels, setExpandedPanels] = useState({
    diagnosis: 0,
    behavior: null,
  });
  const [editOpen, setEditOpen] = useState(false);
  const [patientNotes, setPatientNotes] = useState(patientNote?.data);
  const [editPatientNotes, setEditPatientNotes] = useState(false);
  const [feedbackDoctorNote, setFeedbackDoctorNote] = useState(
    feedback?.data && feedback.data?.notes
  );
  const [isLoading, setIsLoading] = useState(false);

  const [editSymptomIndex, setEditSymptomIndex] = useState(null);
  const [editBehaviorIndex, setEditBehaviorIndex] = useState(null);
  const [editedTreatments, setEditedTreatments] = useState(feedbackTreatments);
  const [editedBehaviors, setEditedBehaviors] = useState(feedbackBehavior);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditOpenBehavior, setIsEditOpenBehavior] = useState(false);

  const [treatments, setTreatments] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [medAndTherapies, setMedTherapies] = useState({});
  const [isCustomSymptom, setIsCustomSymptom] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentPatientData, fetchOnePatientData } = useContext(DataContext);
  const [originalTreatments, setOriginalTreatments] = useState(
    feedback?.data?.treatments
  );
  const [originalBehaviors, setOriginalBehaviors] = useState(
    feedback?.data?.behaviours
  );

  const marks = [
    { value: 0, label: "Low" },
    { value: 1, label: "Mild" },
    { value: 2, label: "Moderate" },
    { value: 3, label: "Severe" },
  ];

  useEffect(() => {
    // Update the original behaviour state whenever feedback data changes
    setOriginalBehaviors(feedback?.data?.behaviours || []);
  }, [feedback?.data?.behaviours]);

  useEffect(() => {
    // Update the original treatments state whenever feedback data changes
    setOriginalTreatments(feedback?.data?.treatments || []);
  }, [feedback?.data?.treatments]);

  useEffect(() => {
    if (patientID !== currentPatientData?.id) {
      fetchOnePatientData(patientID);
    }
  }, [patientID]);

  const getSymptomps = async () => {
    const symptomsRef = collection(FirestoreDB, "symptoms");
    return onSnapshot(
      symptomsRef,
      (querySnapshot) => {
        const symptomIds = querySnapshot.docs.map((doc) => doc.id);
        setSymptoms(symptomIds);
      },
      (error) => {
        console.log("Error listening to symptoms:", error);
      }
    );
  };

  useEffect(() => {
    const medsAndTherapies = transformPatientData(currentPatientData?.data);
    setMedTherapies(medsAndTherapies);
    getSymptomps();
  }, [currentPatientData]);

  function transformPatientData(patientData) {
    const result = {
      meds: [],
      therapies: [],
      treatment_name: [],
    };

    patientData?.forEach((patient) => {
      if (patient.meds && patient.meds.length > 0) {
        patient.meds.forEach((med) => {
          result.meds.push({
            tradeName: med.tradeName || "",
            dosage: med.dosage || 0,
            usage: med.usage || "",
            drug_name: med.drug_name || "",
            range: med.range || "",
            treats: med.treats || "",
            sideEffects: med.sideEffects || "",
          });
        });
      }

      if (patient.therapies && patient.therapies.length > 0) {
        patient.therapies.forEach((therapy) => {
          result.therapies.push({
            costPerHours: therapy.costPerHours || "",
            therapy_name: therapy.therapy_name || "",
            sessionsPerWeek: therapy.sessionsPerWeek || "",
            hoursPerSession: therapy.hoursPerSession || "",
          });
        });
      }

      if (patient.treatment_name) {
        result.treatment_name.push(patient.treatment_name);
      }
    });

    return result;
  }

  const updateTreatments = (dataList, type, treatmentID) => {
    const myTreatments = [...feedbackTreatments];
    if (type === "Therapy") {
      myTreatments.find((x) => x.id === treatmentID).therapies = dataList;
    } else if (type === "Meds") {
      myTreatments.find((x) => x.id === treatmentID).meds = dataList;
    } else if (type === "Symptom") {
      myTreatments.find((x) => x.id === treatmentID).symptom = dataList;
    } else if (type === "Diagnosis") {
      myTreatments.find((x) => x.id === treatmentID).diagnosis = dataList;
    } else {
      myTreatments.find((x) => x.id === treatmentID).rating = dataList;
    }

    setEditedTreatments(myTreatments);
  };

  const deleteTreatment = (treatmentID) => {
    setTreatments([...treatments.filter((x) => x.id !== treatmentID)]);
  };

  const addNewSymptomToSymptomsCollection = async (otherSymptom) => {
    const symptomsCollectionRef = collection(FirestoreDB, "symptoms");
    try {
      const symptomDocRef = doc(symptomsCollectionRef, otherSymptom);
      const symptomDocSnapshot = await getDoc(symptomDocRef);
      if (!symptomDocSnapshot.exists()) {
        await setDoc(symptomDocRef, {});
      } else {
      }
    } catch (error) {
      console.error(
        "Error adding new symptom to the 'symptoms' collection:",
        error
      );
    }
  };

  const handleEditSymptom = (index) => {
    setEditSymptomIndex(index);
    setEditedTreatments([...feedback?.data?.treatments]);
    setIsEditOpen(true);
  };

  const handleEditBehavior = (index) => {
    setEditBehaviorIndex(index);
    setEditedBehaviors([...feedback?.data?.behaviours]);
    setIsEditOpenBehavior(true);
  };
  const handleCloseEditBehavior = () => {
    setEditBehaviorIndex(null);
    setIsEditOpenBehavior(false);
    // setEditedTreatments(feedbackTreatments);
  };

  const handleCloseEdit = () => {
    setEditSymptomIndex(null);
    setIsEditOpen(false);
    // setEditedTreatments(feedbackTreatments);
  };

  const onUpadateTreats = async (data) => {
    setLoading(true);
    const myTreatments = [...originalTreatments];
    const index = myTreatments.findIndex(
      (treatment) => treatment.id === data.id
    );
    myTreatments[index] = { ...data };
    const response = await editDoctorFeedbackTreatments(
      user?.uid,
      btoa(JSON.stringify(myTreatments)),
      videoDetails?.patientId,
      videoDetails?.videoId
    );
    setOriginalTreatments(myTreatments);
    setLoading(false);
    // setEditSymptomIndex(null);
    handleCloseEdit();
  };

  const onUpadateBehaviour = async (data) => {
    setLoading(true);
    const myBehaviours = [...originalBehaviors];
    const index = myBehaviours.findIndex(
      (behaviour) => behaviour.id === data.id
    );
    myBehaviours[index] = { ...data };
    console.log(btoa(JSON.stringify(myBehaviours)), "my behaviors");
    const response = await editDoctorFeedbackBehaviours(
      user?.uid,
      btoa(JSON.stringify(myBehaviours)),
      videoDetails?.patientId,
      videoDetails?.videoId
    );
    console.log(response, "response");
    setOriginalBehaviors(myBehaviours);
    setLoading(false);
    handleCloseEditBehavior();
  };

  const handleChange = (panel, type) => (event, isExpanded) => {
    // Update the expanded state based on the accordion type
    setExpandedPanels((prevExpanded) => ({
      ...prevExpanded,
      [type]: isExpanded ? panel : false,
    }));
  };

  const UpdateDoctorFeedbackNotes = async () => {
    setIsLoading(true);
    const response = await UpdatedDoctorFeedbackNotesToVault(
      btoa(feedbackDoctorNote),
      user?.uid,
      videoDetails?.patientId,
      videoDetails?.videoId
    );
    setEditOpen(false);
    setIsLoading(false);
  };

  const UpdatePatientNotes = async () => {
    setIsLoading(true);
    const response = await UpdatePatientNotesToVault(
      btoa(patientNotes),
      user?.uid,
      videoDetails?.videoId
    );
    setEditPatientNotes(false);
    setIsLoading(false);
  };

  const ClearChanges = () => {
    // setFeedbackDoctorNote("");
    setEditOpen(false);
  };
  const ClearPatientChanges = () => {
    setEditPatientNotes(false);
  };

  return (
    <Box
      className="fullWidth  flexBox flexColumn Patientreview"
      sx={{
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
        pl: 4,
        pr: 4,
        pb: 4,
      }}
    >
      {typeOfUser === "PATIENT" &&
        !feedback?.data?.Private &&
        originalTreatments?.map((symptomData, index) => {
          const isEditing = editSymptomIndex === index;

          return (
            <Box
              key={index}
              className="flexBox flexColumn fullHeight fullWidth"
              sx={{ gap: 2, justifyContent: "center" }}
            >
              <Accordion
                key={index}
                expanded={index === expandedPanels.diagnosis}
                onChange={handleChange(index, "diagnosis")}
                //  sx={{ backgroundColor: "var(--clr-theme-purple)" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "var(--clr-theme-purple)" }}
                  >
                    Diagnosis : {symptomData?.diagnosis || "No Diagnosis"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box
                      sx={{
                        backgroundColor: "#E5E5FA",
                        borderRadius: "20px",
                        padding: "10px",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Diagnosis
                      </Typography>
                      <Typography variant="h6">
                        {symptomData?.diagnosis || "No Diagnosis"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: "#E5E5FA",
                        borderRadius: "20px",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Meds
                      </Typography>

                      <Typography>
                        {symptomData?.meds?.map((med, i) => (
                          <Typography key={i} gutterBottom>
                            {med?.drug_name}
                          </Typography>
                        ))}
                      </Typography>
                    </Box>

                    {originalBehaviors?.map((behaviourData, index) => (
                      <Box
                        key={index}
                        sx={{
                          backgroundColor: "#E5E5FA",
                          borderRadius: "20px",
                          padding: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          Behaviour Name
                        </Typography>
                        <Typography>
                          {behaviourData?.behaviour || "No Behaviour"}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}

      {typeOfUser === "PATIENT" &&
        !feedback?.data?.Private &&
        originalBehaviors?.map((behaviorData, index) => {
          const isEditingBehavior = editBehaviorIndex === index;
          return (
            <Box
              key={index}
              className="flexBox flexColumn fullHeight fullWidth"
              sx={{ gap: 2, justifyContent: "center" }}
            >
              <Accordion
                key={index}
                expanded={expandedPanels.behavior === index}
                onChange={handleChange(index, "behavior")}
                sx={{ border: "1px solid #ccc" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: "var(--clr-theme-purple)" }}
                    />
                  }
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "var(--clr-theme-purple)" }}
                  >
                    Behaviour Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    className="flexBox flexColumn fullHeight fullWidth"
                    sx={{ gap: 1 }}
                  >
                    {isEditingBehavior ? (
                      <Editbehaviors
                        data={behaviorData}
                        onClose={handleCloseEditBehavior}
                        onUpadateBehaviour={onUpadateBehaviour}
                        loading={loading}
                      />
                    ) : (
                      <>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            Behaviour
                          </Typography>
                          <Typography>{behaviorData.behaviour}</Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            Serverity Rating
                          </Typography>
                          <Typography>as seen on page currently</Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            FREQUENCY COUNT
                          </Typography>
                          <Box>
                            <Typography>{`${
                              behaviorData?.behaviour_rating || 0
                            } (Count)`}</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            ANTECEDENT
                          </Typography>
                          <Typography gutterBottom>
                            {behaviorData?.antecedents}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            CONSEQUENCE
                          </Typography>
                          <Typography gutterBottom>
                            {behaviorData?.consequences}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}

      {
        originalTreatments?.map((symptomData, index) => {
          const isEditing = editSymptomIndex === index;

          return (
            <Box
              key={index}
              className="flexBox flexColumn fullHeight fullWidth"
              sx={{ gap: 2, justifyContent: "center" }}
            >
              <Accordion
                key={index}
                expanded={index === expandedPanels.diagnosis}
                onChange={handleChange(index, "diagnosis")}
                sx={{ border: "1px solid #ccc" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: "var(--clr-theme-purple)" }}
                    />
                  }
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "var(--clr-theme-purple)" }}
                  >
                    Diagnosis : {symptomData?.diagnosis || "No Diagnosis"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    className="flexBox flexColumn fullHeight fullWidth"
                    sx={{ gap: 1 }}
                  >
                    {isEditing ? (
                      <EditVideoFeedBack
                        data={symptomData}
                        medsAndTherapies={medAndTherapies}
                        isCustomSymptom={isCustomSymptom}
                        symptoms={symptoms}
                        setIsCustomSymptom={setIsCustomSymptom}
                        onCancel={handleCloseEdit}
                        onSave={onUpadateTreats}
                        loading={loading}
                      />
                    ) : (
                      <>
                        <Box
                          sx={{
                            backgroundColor: "#E5E5FA",
                            borderRadius: "20px",
                            padding: "10px",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Diagnosis
                          </Typography>
                          <Typography variant="body1" sx={{ color: "#333" }}>
                            {symptomData?.diagnosis}
                          </Typography>
                        </Box>
                        {/* <Box
                          sx={{
                            backgroundColor: "#E5E5FA",
                            borderRadius: "20px",
                            padding: "10px",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Behavoiur Type
                          </Typography>
                          <Typography variant="body1" sx={{ color: "#333" }}>
                            Autofilled by patient
                          </Typography>
                        </Box> */}
                        <Box
                          sx={{
                            backgroundColor: "#E5E5FA",
                            borderRadius: "20px",
                            padding: "10px",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Intervention
                          </Typography>
                          <Typography variant="body1" sx={{ color: "#333" }}>
                            Autofilled by patient
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: "#E5E5FA",
                            borderRadius: "20px",
                            padding: "10px",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Meds
                          </Typography>
                          <Typography variant="body1" sx={{ color: "#333" }}>
                            {symptomData?.meds?.map((med, i) => (
                              <Typography key={i} gutterBottom>
                                {med?.drug_name}
                              </Typography>
                            ))}
                          </Typography>
                        </Box>

                        {originalBehaviors?.map((behaviourData, index) => (
                      <Box
                        key={index}
                        sx={{
                          backgroundColor: "#E5E5FA",
                          borderRadius: "20px",
                          padding: "10px",
                          
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          Behaviour Name
                        </Typography>
                        <Typography>
                          {behaviourData?.behaviour || "No Behaviour"}
                        </Typography>
                      </Box>
                    ))}
                      </>
                    )}
                    {typeOfUser === "DOCTOR" && !isEditOpen && (
                      <Tooltip title="edit the feedback" placement="left" arrow>
                        <Button
                          sx={{
                            width: "40%",
                            alignSelf: "center",
                            border:
                              "1px solid var(--clr-theme-purple) !important",
                            // color: "var(--clr-theme-purple)",
                            background: "var(--clr-theme-purple)",
                          }}
                          onClick={() => handleEditSymptom(index)}
                        >
                          {/* <Edit sx={{ color: "var(--clr-theme-purple)" }} /> */}
                          <Typography sx={{ color: "white" }}>
                            Edit Information
                          </Typography>
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      {
        originalBehaviors?.map((behaviorData, index) => {
          const isEditingBehavior = editBehaviorIndex === index;
          return (
            <Box
              key={index}
              className="flexBox flexColumn fullHeight fullWidth"
              sx={{ gap: 2, justifyContent: "center" }}
            >
              <Accordion
                key={index}
                expanded={expandedPanels.behavior === index}
                onChange={handleChange(index, "behavior")}
                sx={{ border: "1px solid #ccc" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: "var(--clr-theme-purple)" }}
                    />
                  }
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "var(--clr-theme-purple)" }}
                  >
                    Behaviour Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    className="flexBox flexColumn fullHeight fullWidth"
                    sx={{ gap: 1 }}
                  >
                    {isEditingBehavior ? (
                      <Editbehaviors
                        data={behaviorData}
                        onClose={handleCloseEditBehavior}
                        onUpadateBehaviour={onUpadateBehaviour}
                        loading={loading}
                      />
                    ) : (
                      <>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            Behaviour
                          </Typography>
                          <Typography>{behaviorData.behaviour}</Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            Serverity Rating
                          </Typography>
                          <Typography>as seen on page currently</Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            FREQUENCY COUNT
                          </Typography>
                          <Box>
                            <Typography>{`${
                              behaviorData?.behaviour_rating || 0
                            } (Count)`}</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            ANTECEDENT
                          </Typography>
                          <Typography gutterBottom>
                            {behaviorData?.antecedents}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "8px" }}
                          >
                            CONSEQUENCE
                          </Typography>
                          <Typography gutterBottom>
                            {behaviorData?.consequences}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}

      <Box className="fullWidth fullHeight flexBox flexColumn" sx={{ gap: 2 }}>
        <Card
          sx={{
            minHeight: 250,
          }}
        >
          <CardHeader
            title={"Patient Notes:"}
            action={
              typeOfUser === "PATIENT" &&
              !editPatientNotes && (
                <Box className="flexBox flexCenter">
                  <Button
                    onClick={() => {
                      setEditPatientNotes(true);
                    }}
                  >
                    <Edit sx={{ color: "black" }} />
                  </Button>
                </Box>
              )
            }
          />

          <CardContent>
            {!editPatientNotes ? (
              <Typography gutterBottom>
                {patientNote?.data || "-------"}
              </Typography>
            ) : (
              <TextField
                label="Patient Notes"
                multiline
                rows={6}
                fullWidth
                value={patientNotes}
                onChange={(e) => setPatientNotes(e.target.value)}
                disabled={isLoading}
              />
            )}
          </CardContent>
          <CardActions>
            {editPatientNotes && (
              <Box
                className="flexBox flexCenter fullWidth  flexWrap"
                sx={{
                  gap: 3,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid var(--clr-theme-purple) !important",
                    width: "20%",
                    color: "var(--clr-theme-purple)",
                    fontFamily: "Lato",
                    textTransform: "capitalize",
                  }}
                  onClick={ClearPatientChanges}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--clr-theme-purple) !important",
                    width: "20%",
                    fontFamily: "Lato",
                    textTransform: "capitalize",
                  }}
                  onClick={UpdatePatientNotes}
                  disabled={isLoading}
                >
                  Save
                </Button>
                {isLoading && (
                  <CircularProgress sx={{ position: "absolute", top: "22%" }} />
                )}
              </Box>
            )}
          </CardActions>
        </Card>

        {typeOfUser === "PATIENT" && !feedback?.data?.Private && (
          <Card
            sx={{
              minHeight: 250,
            }}
          >
            <CardHeader title={"Feedback Notes:"} />
            <CardContent>
              <Typography gutterBottom>
                {feedback?.data?.notes ||
                  "Healthcare professional has not provided feedback for this video."}
              </Typography>
            </CardContent>
          </Card>
        )}
        {typeOfUser === "DOCTOR" && (
          <Card
            sx={{
              minHeight: 250,
            }}
          >
            <CardHeader
              title={"Feedback Notes:"}
              action={
                typeOfUser === "DOCTOR" &&
                !editOpen && (
                  <Box className="flexBox flexCenter">
                    <Button
                      onClick={() => {
                        setEditOpen(true);
                      }}
                    >
                      <Edit sx={{ color: "black" }} />
                    </Button>
                  </Box>
                )
              }
            />

            <CardContent>
              {!editOpen ? (
                feedback ? (
                  <Typography gutterBottom>{feedback?.data?.notes}</Typography>
                ) : (
                  <Typography gutterBottom>No feedback available</Typography>
                )
              ) : (
                <TextField
                  label="Feedback Notes"
                  multiline
                  rows={6}
                  fullWidth
                  value={feedbackDoctorNote}
                  onChange={(e) => setFeedbackDoctorNote(e.target.value)}
                  disabled={isLoading}
                />
              )}
            </CardContent>
            <CardActions>
              {editOpen && (
                <Box
                  className="flexBox flexCenter fullWidth  flexWrap"
                  sx={{
                    gap: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid var(--clr-theme-purple) !important",
                      width: "20%",
                      color: "var(--clr-theme-purple)",
                      fontFamily: "Lato",
                      textTransform: "capitalize",
                    }}
                    onClick={ClearChanges}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      backgroundColor: "var(--clr-theme-purple) !important",
                      width: "20%",
                      fontFamily: "Lato",
                      textTransform: "capitalize",
                    }}
                    onClick={UpdateDoctorFeedbackNotes}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                  {isLoading && (
                    <CircularProgress
                      color="success"
                      sx={{ position: "absolute", top: "22%" }}
                    />
                  )}
                </Box>
              )}
            </CardActions>
          </Card>
        )}
      </Box>
    </Box>
  );
};
export default PatientReview;