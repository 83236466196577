import React, { useContext, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import HomeCard from "../../../components/HomeCard";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import "./AddPatient.css";
import {
  Backdrop,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Email, Info } from "@mui/icons-material";
import { addPatientByDoctor, validateAuthCodeAndGetDoctor } from "../../../api";
import AuthContext from "../../../contexts/AuthContext";
import { FirestoreDB } from "../../../utils/firebase/firebase";
import Progress from "../../../components/Progress";
import { toast } from "react-toastify";

// import { DatePicker } from '@mui/lab';

const AddPatient = () => {
  const [email, setEmail] = useState("");
  const [patientDetails, setPatientDeatils] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState({
    show: false,
    message: "",
  });
  const [customMsg, setCustomMsg] = useState({
    show: false,
    message: "",
  });

  const [isPatientAdded, setIsPatientAdded] = useState(false);
  const { user } = useContext(AuthContext);

  const emailHandler = (e) => {
    setEmail(e.target.value);
    setCustomError({
      show: false,
      message: "",
    });
  };

  const resetValues = () => {
    setEmail("");
    setPatientDeatils(null);
    setCustomError({
      show: false,
      message: "",
    });
    setCustomMsg({
      show: false,
      message: "",
    });
    setIsPatientAdded(false);
  };

  // console.log("patientDeatils", patientDetails);
  console.log("ispatienttadded", isPatientAdded);

  const addPatientByEmail = async () => {
    if (!email) {
      setCustomError({
        show: true,
        message: "Email address cannot be empty.",
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    if (email && user?.uid) {
      try {
        console.log("calling");
        const patientInfo = await addPatientByDoctor(user?.uid, email);
        setPatientDeatils(patientInfo?.data);
        console.log("patient info-----------------", patientInfo);
        if (patientInfo) {
          setCustomMsg({ show: true, message: patientInfo?.message });
        }

        if (patientInfo?.status) {
          setIsPatientAdded(true);
        }

        if (!patientInfo?.status) {
          // setIsPatientAdded(true);
          setCustomError({ show: true, message: patientInfo?.data?.message });
        }
      } catch (error) {
        console.log("error while adding patient", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <HomeCard title="Add Patient" showAvatar={false} customClass="AddDoctor">
      <Container maxWidth="xl" sx={{ px: 4, mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper
              className="fullHeight"
              sx={{ p: 5, mb: 4, border: "1px solid lightgray" }}
            >
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ fontFamily: "Lato" }}
              >
                I would like guidance on adding a patient to the system and
                obtaining the necessary email id for the process.
              </Typography>
              <Box className="flexBox flexColumn" sx={{ gap: 2, mt: 3 }}>
                {/* Form Group (username) */}
                <TextField
                  label="Type email address"
                  fullWidth
                  value={email}
                  onChange={emailHandler}
                  color="secondary"
                  error={customError.show}
                  helperText={customError.show ? customError.message : ""}
                />

                <Box className="flexBox" gap={0.5}>
                  <Info color="success" sx={{ fontSize: "20px" }} />
                  <Typography
                    variant="caption"
                    sx={{ textAlign: "start", color: "var(--clr-theme-gray)" }}
                  >
                    To proceed further, please kindly provide the email address
                    of either the patient or their healthcare professional, as
                    it is necessary for the subsequent steps in the registration
                    process.
                  </Typography>
                </Box>

                {customMsg.show && (
                  <Typography className="successMessage" variant="caption">
                    {customMsg.message}
                  </Typography>
                )}

                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--clr-theme-purple) !important",
                    "&:disabled": {
                      color: "var(--clr-theme-white) !important",
                      backgroundColor: "rgb(211, 211, 211) !important", // You can adjust this styling as needed
                    },
                  }}
                  variant="contained"
                  color="primary"
                  type="button"
                  fullWidth
                  onClick={addPatientByEmail}
                  disabled={isPatientAdded}
                  // disabled={isVerificationDone}
                >
                  Add patient
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--clr-theme-purple) !important",
                    "&:disabled": {
                      color: "var(--clr-theme-white) !important",
                      backgroundColor: "rgb(211, 211, 211) !important", // You can adjust this styling as needed
                    },
                  }}
                  variant="contained"
                  color="primary"
                  type="button"
                  fullWidth
                  onClick={resetValues}
                  
                  // disabled={isVerificationDone}
                >
                  Reset
                </Button>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ border: "1px solid lightgray" }}>
              <CardHeader
                title="Patient Details"
                sx={{
                  textAlign: "center",
                  backgroundColor: "var(--clr-theme-purple)",
                  color: "#fff",
                }}
              />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Avatar
                    src={patientDetails?.photoURL}
                    alt="Admin"
                    sx={{
                      width: 150,
                      height: 150,
                      marginBottom: 2,
                    }}
                    variant="circular"
                  />
                  <div style={{ marginTop: 3 }}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontFamily: "Lato" }}
                    >
                      {patientDetails?.fullname || "NA"}
                    </Typography>
                  </div>
                </div>
                <Divider my={4} />
                <List>
                  <ListItem ke alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography sx={{ fontFamily: "Lato" }} variant="h6">
                          Email
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {patientDetails?.email || "NA"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem ke alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography sx={{ fontFamily: "Lato" }} variant="h6">
                          Contact number
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {`${patientDetails?.contactNo?.areaCode ?? "NA"} ${
                            patientDetails?.contactNo?.number ?? ""
                          }`}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Container>
    </HomeCard>
  );
};

export default AddPatient;
