import { React, useState, useEffect } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";



import BlogImage from "../../../assets/images/about_page.png";
import MaskedImage from "../../../components/MaskedImage";
import Footer from "../Footer";
import "./Blog.css";
import { Scrollbar } from "smooth-scrollbar-react";
import Fade from "react-reveal";
import PostCard from "../Blog/PostCard";
import BlogPhoto from "../../../assets/images/blog_stock_image.png";

import Partners from "../HomePage/Partners";

const Blog = () => {

    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const localUserInfo = localStorage.getItem("userInfo");
        if (localUserInfo) {
            setUserInfo(JSON.parse(localUserInfo));
        }
    }, []);


    useEffect(() => {
        if (userInfo) {
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
    }, [userInfo]);

    const handleUserInfoChange = (info) => {
        setUserInfo(info);
    };

    return (
        <Scrollbar
            plugins={{
                overscroll: {
                    effect: "",
                },
            }}
        >

            <Box className="landingPageMainContainer">
                <Grid item xs={12}>
                    <MaskedImage
                        currentStyle={{
                            height: "40vh",
                            backgroundImage: `url(${BlogImage})`,
                        }}
                    >
                        <Fade duration={1500} delay={400} left>
                            <Typography
                                variant="h2"
                                sx={{ fontFamily: 'Lato', fontWeight: 700 }}
                            >
                                Our Blog
                            </Typography>
                        </Fade>
                    </MaskedImage>

                </Grid>
                <Fade duration={1500} delay={400} bottom>
                    <Grid
                        container
                        spacing={0}
                        justifyContent={'center'}
                        textAlign={'center'}
                        padding={5}
                    >
                        <Grid item xs={10}>
                            <Fade duration={1500} delay={400} bottom>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        fontFamily: 'Lato',
                                        fontSize: { sm: '1rem', md: '1rem', lg: '1.2rem' },
                                        lineHeight: { sm: '1.5rem', md: '1.5rem', lg: '1.8rem' },
                                        color: 'var(--clr-theme-gray-dark)',
                                    }}
                                >
                                    Stay up-to-date on the latest advancements in neurological care by exploring our blog!
                                </Typography>
                            </Fade>
                        </Grid>
                        <Divider
                            sx={{
                                borderColor: 'var(--clr-theme-purple)',
                                width: '60%',
                                margin: '3rem',
                            }}
                        />

                        <Grid
                            container
                            spacing={4}
                            justifyContent="flex-start"
                            sx={{
                                maxWidth: '1200px',
                                margin: '0 auto',
                                marginTop: '20px',
                            }}
                        >

                            <PostCard
                                photo={BlogPhoto}
                                title="Revolutionizing Autism Treatment with Video-Based Data"
                                content={`
        In the rapidly evolving healthcare sector, technology isn’t a luxury but a critical driver of progress. Among the numerous innovations that define this era, the strategic use of video-based data stands out as a game-changing paradigm shift. This approach is particularly noteworthy in the realm of autism and neurological disorder treatments, offering tangible benefits and nuanced insights into patient care.

        In contrast to the conventional reliance on text-based records in healthcare, the integration of video data introduces a visual dimension to analytics. This is very important when it comes to neurological disorders such as autism, where each patient’s symptom manifestation is different and calls for a more comprehensive approach to treatment. Unlike static written descriptions, videos offer a range of insights into patients’ experiences in different environments, allowing healthcare professionals to observe, analyze, and understand behavioral patterns and responses in a more detailed and accurate manner. This enhanced observational capability paves the way for more personalized and effective interventions, ultimately improving the quality of care for individuals with neurological conditions.

        The incorporation of videos into neurological disorder treatment also facilitates the development and implementation of more robust evidence-based strategies. Unlike conventional methods that rely on subjective interpretations and trial-and-error, videos offer an objective representation of patients’ behavioral responses to treatments. This visual data serves as concrete evidence, allowing healthcare professionals to identify symptom patterns and trends that might be missed with traditional approaches. The ability to observe real-time interactions in different contexts provides a wealth of information, facilitating a more thorough analysis of treatment efficacy.

        Capturing complex behaviors and responses on video can become a powerful and invaluable tool for clinicians, providing a visual narrative that surpasses traditional written records to enable a more holistic understanding of neurological disorders.
    `}
                                author="Pooja Shah"
                                datePosted="September 20, 2024"
                                userInfo={userInfo}
                                userInfoUpdated={handleUserInfoChange}
                            />


                        </Grid>
                        <Partners />
                        <Footer />

                    </Grid>
                </Fade>







            </Box>


        </Scrollbar >

    );


};
export default Blog;
