import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';

import MindlyGifWeb from '../../../assets/images/MindlyGifWeb.gif';
import mobilegif2 from '../../../assets/images/mobilegif2.gif';
import FlipCard from '../../../components/FlipCard';
import Partners from '../HomePage/Partners';
import Footer from '../Footer';
import SignUpPilot from '../HomePage/SignupPilot';
import Section from '../HomePage/Section';
import MaskedImage from '../../../components/MaskedImage';
import AboutImage from '../../../assets/images/about_page.png';
import { Scrollbar } from 'smooth-scrollbar-react';
import { Fade } from 'react-reveal';

const OurStory = () => {
  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: '',
        },
      }}
    >
      <Box className="landingPageMainContainer">
        <Grid item xs={12}>
          <MaskedImage
            currentStyle={{
              height: '40vh',
              backgroundImage: `url(${AboutImage})`,
            }}
          >
            <Fade duration={1500} delay={400} left>
              <Typography
                variant="h2"
                sx={{ fontFamily: 'Lato', fontWeight: 700 }}
              >
                About
              </Typography>
            </Fade>
          </MaskedImage>
        </Grid>
        <Grid
          container
          spacing={0}
          justifyContent={'center'}
          textAlign={'center'}
          padding={5}
        >
          <Grid item xs={10}>
            <Fade duration={1500} delay={400} bottom>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Lato',
                  fontSize: { sm: '1rem', md: '1rem', lg: '1.2rem' },
                  lineHeight: { sm: '1.5rem', md: '1.5rem', lg: '1.8rem' },
                  color: 'var(--clr-theme-gray-dark)',
                }}
              >
                At Mindly, we are passionate about leveraging technology to
                improve patient care, especially for individuals with
                neurological disorders and related conditions. Our platform
                empowers clinicians and caregivers to gain valuable treatment
                insights by remotely monitoring patients outside of traditional
                clinical settings, improving treatment accuracy and patient
                outcomes.
              </Typography>
            </Fade>
          </Grid>
          <Divider
            sx={{
              borderColor: 'var(--clr-theme-purple)',
              width: '60%',
              margin: '3rem',
            }}
          />
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Fade bottom duration={1500} delay={400}>
                  <img
                    src={MindlyGifWeb}
                    alt="web"
                    width={'80%'}
                    height={'auto'}
                  />
                </Fade>
              </Grid>
              <Grid item xs={10} py={2}>
                <Fade left duration={1500} delay={400}>
                  <Typography
                    color={'var(--clr-theme-purple)'}
                    variant="h6"
                    sx={{
                      fontFamily: 'Lato',
                      fontWeight: 600,
                    }}
                  >
                    Web
                  </Typography>
                </Fade>
                <Fade left duration={1500} delay={400}>
                  <Typography
                    variant="subtitle2"
                    color={'GrayText'}
                    sx={{
                      fontFamily: 'Lato',
                      fontSize: { sm: '1rem', md: '1rem', lg: '1.2rem' },
                      lineHeight: { sm: '1.5rem', md: '1.5rem', lg: '1.8rem' },
                    }}
                  >
                    {`Discover the convenience of accessing our services right from your browser. 
                Our web platform provides an effective method for tracking patient outcomes and analyzing vital data.
                Monitor progress seamlessly through a user-friendly interface, accessing insights that guide personalized treatment decisions.`}
                  </Typography>
                </Fade>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={10}>
                <Fade right duration={1500} delay={400}>
                  <Typography
                    color={'var(--clr-theme-purple)'}
                    variant="h6"
                    sx={{
                      fontFamily: 'Lato',
                      fontWeight: 600,
                    }}
                  >
                    Mobile
                  </Typography>
                </Fade>
                <Fade bottom duration={1500} delay={400}>
                  <Typography
                    variant="subtitle2"
                    color={'GrayText'}
                    sx={{
                      fontFamily: 'Lato',
                      fontSize: { sm: '1rem', md: '1rem', lg: '1.2rem' },
                      lineHeight: { sm: '1.5rem', md: '1.5rem', lg: '1.8rem' },
                    }}
                  >
                    {`Take our services with you wherever you go. Enjoy a smooth and intuitive mobile experience that fits right in your pocket.
                 Capture real-time insights effortlessly, providing invaluable behavioural data that ensures optimal patient care.`}
                  </Typography>
                </Fade>
              </Grid>
              <Grid item xs={12} py={2}>
                <Fade right duration={1500} delay={400}>
                  <img src={mobilegif2} alt="mobile" height={'300px'} />
                </Fade>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={9}
          className="featuresPages"
          style={{ backgroundColor: 'var(--clr-theme-purple-50)' }}
        >
          <div className="flexBox fullWidth flexCenter flexColumn">
            <Section
              title={'How it Works'}
              titleColor="var(--clr-theme-white)"
              content={`Mindly has a clinician-facing website and a patient-facing iOS app that tracks the improvement of neurological disorders through video tracking methods in relation to different types of treatment methods.`}
              contentColor="var(--clr-theme-white)"
              dividerColor="var(--clr-theme-white)"
            ></Section>
            <Fade bottom duration={1500} delay={400}>
              <div className="fullWidth">
                <Box
                  className="box2 flexBox flexWrap fullWidth fullHeight"
                  sx={{ flex: 0.6, gap: '1em' }}
                >
                  <FlipCard
                    title={'Visually Track Behavior'}
                    content={`Utilize videos to convey details about complex behaviors that are difficult to explain. By providing clear, visual insights, parents can effectively advocate for their child’s needs to acilitate a more accurate analysis of behaviors.`}
                  />
                  <FlipCard
                    title={'Automated Symptom Detection'}
                    content={`Streamline behavioral analysis by leveraging Mindly's Artificial Intelligence algorithm, which will automatically pinpoint, analyze, and splice out specific behaviors for clinicians to view with unparalleled efficiency.`}
                  />
                  <FlipCard
                    title={'Comprehensive Analytics'}
                    content={`Provide clinicians with a better understanding of symptom progression in relation to various treatment methods, allowing them to identify patterns and trends that can help them make more effective decisions.`}
                  />
                </Box>
              </div>
            </Fade>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Partners size="small" />
          </Box>
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: 'var(--clr-theme-purple-50)' }}
        >
          <SignUpPilot />
        </Box>
        <Grid item xs={12}>
          <Box>
            <Footer />
          </Box>
        </Grid>
      </Box>
    </Scrollbar>
  );
};

export default OurStory;
