import React from "react";
import MainLayout from "../../hoc/MainLayout";
import { Card, CardContent, Typography } from "@mui/material";
const UserGuide = () => {
  return (
    <MainLayout>
      <Card sx={{ maxWidth: "90%", margin: "20px auto", padding: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
            User Guide 
          </Typography>

          {/* Add more sections based on the information you collect */}
          <Typography gutterBottom>
            <b>1. Introduction</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>Overview of Mindly care</b>.Mindly is a visual database
                platform for clinicians who treat neurological disorders to
                accurately track symptoms in relation to medication and therapy.
                Mindly establishes video tracking methods in conjunction with
                conventional treatment routes in order to aid in visually
                monitoring the progression of neurological disorders in
                patients. Our platform consists of a clinician-facing website
                and a patient-facing mobile app that enable clinicians and
                caregivers to consistently upload videos of patients in various
                settings in order to gain valuable behavioral insights for
                improved treatment outcomes. Mindly's AI algorithm will then
                detect specific physical symptoms exhibited in the video and
                compile all of the uploaded videos into a short, one-minute
                summary of all highlighted behaviors to track the progression of
                symptoms over time, saving a significant amount of time and
                money on the treatment process. Our platform aims to improve the
                data collection process for treatment efficacy as well as
                provide personalized analyses and visualizations of metrics and
                data collected from patients, in order to identify specific
                behavioral markers and symptomatic patterns in these disorders.
                Our end goal is to help clinicians make more informed treatment
                choices for neurological disorders, as well as improve patient
                outcomes, through hard-coded data on the platform.
              </li>
              <li type="a">
                <b>Purpose of the User Guide</b>Welcome to Mindly! This guide is
                your go-to resource to efficiently navigate our platform. We aim
                to empower clinicians and patients by making accurate symptom
                tracking and effective treatment accessible. Our goal is to
                demystify Mindly, providing you with the knowledge to optimize
                your experience. We're here to enhance your understanding and
                utilization of our platform, contributing to improved
                neurological disorder management.
              </li>
            </ol>
          </Typography>
          {/* Add more sections based on how you use the collected information */}
          <Typography gutterBottom>
            <b> 2. Getting Started</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b> Accessing the Website</b>. To access our website, simply
                visit mindly.care using your preferred web browser.<br></br>
                <b>Home Page:</b>
                Our home page is your starting point. Here, you'll find an
                overview of Mindly's offerings and its impact on neurological
                disorder treatment.<br></br>
                <b>About Page:</b>
                Dive deeper into Mindly's mission, features, and how it's
                revolutionizing symptom tracking and treatment efficacy.
                <br></br>
                <b>Team:</b>
                Learn about the brilliant minds behind Mindly, driving
                innovation and dedicating their expertise to improve
                neurological disorder care.<br></br>
                <b>Contact:</b>
                Have inquiries about Mindy? Head to our contact page to get in
                touch with our team for more information.<br></br>
              </li>
              <li type="a">
                <b>Creating an Account</b>
                -Click on the “Log In” button located on the home page.{" "}
                <br></br>
                -Click on “sign up” at the bottom of the login page<br></br>
                -Select the “Doctor” option <br></br>
                -Enter registration ID<br></br>
                -Upload profile picture <br></br>
                -Enter the following information: Full Name, Email, Password,
                Confirm Password<br></br>
                -Read the Terms and Conditions, Privacy Policy, and SAAS
                Agreement, and then select “i agree” at the bottom of the page,
                You will need to agree 3 times to each page. <br></br>
                -Click “Sign Up”
              </li>

              <li type="a">
                <b>Logging In</b>
                -Enter username (EMAIL) <br></br>
                -Enter Password <br></br>
                -Click “Log In” <br></br>
                -If password is forgotten, click on “forgot password”<br></br>
                -Submit your email, and an email will be sent to that email
                address with instructions to reset your password.<br></br>
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b> 3. User Dashboard</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b> Overview</b>.Welcome to the <b> Mindly Dashboard</b>, your
                central control hub for efficient management of neurological
                disorders. Quickly catch up on progress by viewing the most
                recent video uploads, gaining real-time insights into your
                patients' journeys. Analyze personalized patient analytics to
                track the emergence of symptoms and the efficacy of treatment.
                Stay on top of your schedule with the integrated calendar,
                effortlessly managing and organizing appointments, ensuring
                you're always in sync with patient care. The Mindly Dashboard is
                designed for seamless, informed, and well-organized clinical
                practice.
              </li>
              <li type="a">
                <b>Navigating the Dashboard + Full Pages</b>
                <br></br>
                <b>Video Uploads</b>
                <br></br>
                -On the dashboard, find the section labeled "Videos": Here, you
                can quickly view the most recent videos uploaded by you or your
                patients<br></br>
                -Expand the view of patient videos by clicking the carrot icon
                at the end of each patient’s name. Collapse the view by clicking
                the carrot icon again.<br></br>
                -Filter recently uploaded videos by patient name and date{" "}
                <br></br>
                -Click “View All” at the bottom left side of the “Videos”
                section to view all uploaded videos categorized in order of date
                uploaded.<br></br>
                -To access and manage a specific video, click on the “Details”
                button on the right hand side of the video icon. You will be
                directed to the “Video Information” page.<br></br>
                -On the top left-hand side of the page, you'll find a carousel
                or menu displaying options for "Raw Video," "Processed Video,"
                and "Spliced Video." Click on any of these options to access and
                view the respective videos.<br></br>
                -Directly below the video carousel, you'll see a section
                displaying video-specific analytics, offering insights into the
                patient's condition during the recorded session.<br></br>
                -On the right-hand side of the page, you'll find a panel where
                you can assign specific details related to the video<br></br>
                <b>Activity Log:</b> <br></br>Click on the “Activity Log” button
                to view and track timestamps of edits made by other
                clinicians/patients on the platform.<br></br>
                <b>Treatment Information:</b>
                <br></br>
                <b>Condition/Diagnosis Tracking:</b> Assign the condition you
                are tracking for the patient during this video session.<br></br>
                <b>Medication & Therapies: </b>Select any medications or
                therapies the patient is currently undergoing.<br></br>
                <b>Rating Scale:</b> Rate the video based on a specified metric.
                Use this feature to provide a quantitative assessment of the
                patient's behavior, symptoms, or progress observed in the video.
                <b>Behavior Information (Frequency):</b> Select a behavior from
                the dropdown or add new behavior by clicking “Other” on the
                dropdown. Frequency Count: Increase or decrease the “frequency
                count” by clicking on the “+” or “-”, respectively.<br></br>
                <b>Antecedent/Consequences: </b>Write in notes regarding the ABC
                model. <br></br>
                <b>Notes: </b>Write any additional notes pertaining to the video
                in the “Feedback Notes” section. Any notes the parent added when
                uploading the video can also be viewed under “Patient Notes”.
                <br></br>
                -You can click on “Add New Treatment”, “Add New Behavior”, or
                “Update Treatment Information”<br></br>
                -Click on “Submit” to enter all of the video information into
                Mindly’s database<br></br>
                <b>Statistics</b>
                <br></br>
                -Navigate to the section on the dashboard labeled “Statistics”.
                Here, you can quickly view patient analytics to track symptom
                development and treatment effectiveness.<br></br>
                Filter analytics by patient name.<br></br>
                Click “View All” at the bottom left side of the “Statistics”
                page to explore more detailed and personalized patient
                analytics.<br></br>
                -The main area of the “Statistics” page displays the selected
                patient's analytics based on your chosen graph type and time
                frame.<br></br>
                -Analyze the graph in detail to understand the patient's
                progress, behaviors, or symptoms based on the selected
                parameters.<br></br>
                -Utilize the filtering options to refine the displayed data
                further.<br></br>
                -Filter data by patient name, graph type (ratings, frequency,
                symptom), or time to focus on specific aspects of the patient's
                progress.<br></br>
                -On the right-hand side of the page, you'll find a panel
                displaying general patient statistics. <br></br>
                <b>Appointments</b>
                <br></br>
                -Head to the "Calendar" section, found on the right hand side of
                the dashboard. Here, you can connect to Google Calendar and
                schedule new appointments, view existing ones, and efficiently
                manage your schedule.<br></br>
                -Filter events by day, week, and month.<br></br>
                You can view your calendar on a larger scale by navigating to
                the “Appointments” tab on the left hand side of the dashboard.
                <br></br>
                <b>Other Features</b>
                <br></br>
                <b>On the top navigation bar, you can:</b>
                <br></br>
                -Edit your profile information by clicking on the pencil icon{" "}
                <br></br>
                -View new patient notifications and platform updates by clicking
                on the bell icon<br></br>
                -Access the “Settings” page by clicking on your profile icon on
                the top right hand side of the dashboard. You can also shortcut
                to the “Dashboard”, as well as log out of the platform.<br></br>
                <b>On the left hand side of the dashboard, you can:</b>
                <br></br>
                -access individual patient profiles by clicking on “Patient
                List” button<br></br>
                -access your calendar on a larger scale by clicking
                “Appointments” button<br></br>
                -access analytics on a larger scale by clicking on “Statistics”
                button<br></br>
                -Edit your own profile information by clicking on “Settings”
                <br></br>
                -Access privacy policy, terms and conditions, and contact
                information. <br></br>
                <b>
                  On the bottom right hand corner, click the “Upload” button to
                  upload any patient videos onto the platform
                </b>
                <br></br>
                -Enter the date, patient, and additional notes before clicking
                the “save” button to submit the video into Mindly’s database
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>4. Profile Management.</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b> Patient Profile Information (PATIENT LIST)</b>
                <br></br>
                -Navigate to “Patient List” on the left hand side of the
                dashboard.<br></br>
                -Click on the carrot icon to expand patient list to access
                individual patient information<br></br>
                -Select a patient from the list whose information you want to
                access or edit.<br></br>
                -Upon selecting a patient, you'll be directed to their dedicated
                “Patient Report” page.<br></br>
                -Here, you can view and edit a patient’s personal details by
                clicking the “edit profile” button on the bottom of the profile
                picture section.<br></br>
                -You can also view and edit a patient’s treatment information by
                clicking the “Modify Treatment Methods” button on the bottom of
                the “Treatment Information” section. Here, new diagnoses and
                treatments can be added to a patient’s profile.<br></br>
                -A patient’s video archive can be accessed in the “Videos”
                section, where all videos uploaded by a specific patient are
                stored. Click on “view all” at the bottom of this section to
                view all videos specific to an individual patient, organized by
                date uploaded.<br></br>
                -When scrolling down on the “Patient Report” page, individual
                statistics specific to a single patient can be accessed at the
                bottom of the page. Analytics can be filtered by “symptom type”
                and “date”.<br></br>
              </li>

              <li type="a">
                <b> Personal Profile Information (SETTINGS)</b>
                <br></br>
                -Navigate to “Settings” on the left hand side of the dashboard.
                <br></br>
                -On the “Settings” page, click on "Edit" and modify any
                necessary personal profile information, such as address, email,
                contact number, gender, and doctor type.<br></br>
                -On the right hand side of the page, profile password can be
                changed, and new patients can be added. <br></br>
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>5. Video Upload Guidelines</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>Recording Guidelines: </b>
                <br></br>
                <b> Distance and Position:</b> Maintain a consistent distance
                and position when recording the video. This helps in accurate
                landmark detection and tracking.<br></br>
                <b>Video Format:</b> While Mindly can convert various video
                formats, using the MP4 format is recommended for compatibility.
              </li>
              <li type="a">
                <b>Hand Movements:</b>
                Mindly can effectively track hand movements with the following
                considerations:<br></br>
                <b>Number of Landmarks:</b> Capture at least 20 key landmarks
                for precise analysis. A full-body shot is not necessary, focus
                on the hands.<br></br>
                <b>Subject Focus:</b> Ensure that only the subject's hands are
                visible in the video frame. Mindly can account for the presence
                of other hands in the video.<br></br>
                <b>Maximum Hands:</b> You can set a maximum of two hands to be
                tracked simultaneously.<br></br>
                <b>Resolution:</b> Videos with resolutions of 192 x 192 or 224 x
                224 are ideal for precise tracking.<br></br>
              </li>
              <li type="a">
                <b>Face Movements:</b>
                <br></br>
                If facial expressions are relevant to the analysis, please
                consider the following for effective tracking:<br></br>
                <b>Clarity:</b> Ensure the subject's face is clearly visible in
                the video.<br></br>
                <b>Expressions:</b> Record the full range of facial expressions
                to provide comprehensive data.<br></br>
                <b>Resolution:</b> Maintain a resolution of 192 x 192 or higher
                for precise facial analysis.
              </li>
              <li type="a">
                <b>Body Movements:</b>
                <br></br>
                For full-body movements, consider the following when recording:
                <br></br>
                <b>Video Quality:</b> Lower quality videos (faster frames) can
                be used for body movements. Aim for a range of 7 - 20 KB per
                frame, as seen in example training datasets.<br></br>
                <b>Full Body:</b> If recording full-body movements, ensure the
                entire body is in the frame. Mindly is optimized for a 256 x 256
                frame.<br></br>
                <b>Subject's Distance:</b> The subject should be positioned 2 -
                4 meters from the camera for effective analysis.<br></br>
              </li>
            </ol>
          </Typography>
        </CardContent>
      </Card>
    </MainLayout>
  );
};
export default UserGuide;
