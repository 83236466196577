import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { styled } from "@mui/material/styles";
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UIContext } from '../../contexts/UIProvider';

import './CollapsibleList.css';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: "bold",
    padding: "10px",
  },
}));

const CollapsibleList = ({ mainIcon, primaryText, data }) => {
  const { selectedPatientId, selectPatient } = useContext(UIContext);
  const [open, setOpen] = React.useState(selectedPatientId !== -1 || false);

  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const goToPatient = (item) => {
    selectPatient(item.patientId);

    navigate(`/patient/${item.patientId}`, {
      state: {
        patientData: { ...item?.patientInfo, id: item.patientId },
      },
    });
  };

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };


  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ color: 'var(--clr-theme-purple)' }}>
          {mainIcon}
        </ListItemIcon>
        <ListItemText primary={primaryText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding className='collapsedList'>
          {data.map((item, i) => (
            <ListItemButton
              key={i}
              sx={{ pl: 4 }}
              className={
                selectedPatientId === item.patientId ? 'selectedPatient' : ''
              }
              onClick={() => goToPatient(item)}
            >
              <ListItemIcon>
                {item.icon ||
                  (item?.patientInfo?.photoURL && (
                    <Avatar src={item?.patientInfo?.photoURL} />
                  )) || <Avatar />}
              </ListItemIcon>
               {/* Tooltip to show full name on hover */}
               <BootstrapTooltip
                title={
                  item?.patientInfo?.parentName
                    ? `${item.patientInfo.parentName} (${item.patientInfo.fullname})`
                    : item?.patientInfo?.fullname
                }
                arrow
              >
                <ListItemText
                  primary={
                    item?.patientInfo?.parentName
                      ? `${truncateName(item.patientInfo.parentName)} (${truncateName(
                          item.patientInfo.fullname
                        )})`
                      : truncateName(item.patientInfo.fullname)
                  }
                  sx={{ cursor: 'pointer' }} // Add a pointer cursor to indicate it's hoverable
                />
              </BootstrapTooltip>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default CollapsibleList;
