import { Medication, Psychology } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  Tabs,
  Tab,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { collection, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import UpdateTreatments from "../../components/UpdateTreatments";
import AuthContext from "../../contexts/AuthContext";
import { DataContext } from "../../contexts/DataProvider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function PatientMeds({ patient }) {
  const {
    typeOfUser,
    setOpenRightPanel,
    setPanelContent,
    // setIsNotificationPanel,
  } = useContext(AuthContext);
  const { currentPatientData, fetchOnePatientData } = useContext(DataContext);

  const [editOpen, setEditOpen] = useState(false);

  const EditMedsAndTherapy = () => {
    setOpenRightPanel(true);
    setPanelContent(<UpdateTreatments patientID={patient?.id} />);
  };

  useEffect(() => {
    if (patient?.id !== currentPatientData?.id) {
      fetchOnePatientData(patient.id);
    }
  }, [patient, currentPatientData]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card
      className="fullWidth fullHeight flexBox flexColumn "
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: 'none',
        position: 'relative',
        height: '100%',
      }}
    >

<Box sx={{ width: '100%', position: 'relative', height: '100%' }}>
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1, backgroundColor: 'white',display: 'flex',
      justifyContent: 'center' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Meds and Therapies Tabs"
        >
          <Tab label="Meds" {...a11yProps(0)} />
          <Tab label="Therapies" {...a11yProps(1)} />
        </Tabs>
        </Box>
       <Box sx={{ position: 'absolute', top: '48px', bottom: '56px', left: 0, right: 0, overflowY: 'auto' }}>
        <TabPanel value={value} index={0}>
          <List disablePadding sx={{ width: "100%" }}>
            {/* <Typography
              variant='h5'
              gutterBottom
              sx={{
                textAlign: 'center',
                fontFamily: 'Lato',
                fontWeight: 600,
                color: 'var(--clr-theme-purple)',
              }}
            >
              Meds
            </Typography> */}
            <ListItem
              className="flexBox flexColumn"
              sx={{
                gap: 2,
                alignItems: "flex-start",
              }}
            >
              {currentPatientData?.data.length > 0
                ? currentPatientData.data.map((item, i) =>
                    item?.meds?.map((med, j) => (
                      <Box
                        key={j}
                        className="flexBox"
                        sx={{ justifyContent: "center" }}
                      >
                        <ListItemIcon>
                          <Medication
                            sx={{ color: "var(--clr-theme-gray-dark)" }}
                          />
                        </ListItemIcon>
                        <Typography
                          gutterBottom
                          sx={{
                            textAlign: "center",
                            fontFamily: "Karla",
                            color: "var(--clr-theme-gray)",
                          }}
                        >
                          {med?.drug_name}
                        </Typography>
                      </Box>
                    ))
                  )
                : "No Medicine"}
            </ListItem>
          </List>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <List disablePadding sx={{ width: "100%" }}>
            {/* <Typography
              variant='h5'
              gutterBottom
              sx={{
                textAlign: 'center',
                fontFamily: 'Lato',
                fontWeight: 600,
                color: 'var(--clr-theme-purple)',
              }}
            >
              Therapies
            </Typography> */}
            <ListItem
              className="flexBox flexColumn"
              sx={{ gap: 2, alignItems: "flex-start" }}
            >
              {currentPatientData?.data.length > 0
                ? currentPatientData.data.map((item, i) =>
                    item?.therapies?.map((therapy, j) => (
                      <Box key={j} className="flexBox flexCenter">
                        <ListItemIcon>
                          <Psychology
                            sx={{ color: "var(--clr-theme-gray-dark)" }}
                          />
                        </ListItemIcon>
                        <Typography
                          gutterBottom
                          sx={{
                            textAlign: "center",
                            fontFamily: "Karla",
                            color: "var(--clr-theme-gray)",
                          }}
                        >
                          {therapy?.therapy_name || "-"}
                        </Typography>
                      </Box>
                    ))
                  )
                : "No Therapy"}
            </ListItem>
          </List>
        </TabPanel>
      </Box>

      <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, zIndex: 1, backgroundColor: 'white',alignItems:'center',display: 'flex',
      justifyContent: 'center' }}>
        <CardActions>
          <Button
            variant="contained"
            onClick={EditMedsAndTherapy}
            sx={{ backgroundColor: "var(--clr-theme-purple) !important" }}
          >
            Update treatment methods
          </Button>
        </CardActions>
      </Box>
      </Box>
    </Card>
  );
}

export default PatientMeds;
