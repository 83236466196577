/**
 * ContactForm Component
 *
 * This component renders a form for users to send contact messages.
 *
 * @module ContactForm
 */

import React from 'react';
import { Box, TextField } from '@mui/material';
import Progress from '../../components/Progress';

/**
 * ContactForm component to render the contact form
 * @param {Object} props - Component props
 * @param {Object} props.formData - Form data state
 * @param {Function} props.handleInputChange - Function to handle input changes
 * @param {Function} props.handleSubmit - Function to handle form submission
 * @param {boolean} props.isLoading - Loading state
 * @returns {React.Component} ContactForm component
 */
const ContactForm = ({
  formData,
  handleInputChange,
  handleSubmit,
  isLoading,
}) => (
  <Box className="contact-form">
    <form onSubmit={handleSubmit} id="contact-form">
      <h2>Send Message</h2>
      {Object.entries(formData).map(([key, value]) => (
        <Box key={key} className="input-box">
          <TextField
            label={
              key === 'phnNo'
                ? 'Phone number'
                : key.charAt(0).toUpperCase() + key.slice(1)
            }
            name={key}
            value={value}
            onChange={handleInputChange}
            variant="standard"
            fullWidth
            multiline={key === 'message'}
            rows={key === 'message' ? 4 : 1}
          />
        </Box>
      ))}
      <Box className="input-box">
        <input type="submit" value="Send" name="sendButton" />
      </Box>
    </form>
    {isLoading && <Progress />}
  </Box>
);

export default ContactForm;
