import { memo, useContext, useEffect, useState } from "react";
import { Add, Upgrade } from "@mui/icons-material";
import { Box, Paper } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import {
  collection,
  doc,
  setDoc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { FirestoreDB } from "../../utils/firebase/firebase";
import AuthContext from "../../contexts/AuthContext";
import Treatments from "./Treatmentm";
import { ToastContainer, toast } from "react-toastify";

import CustomButton from "../../components/CustomButton";
import { fetchPatientTreatments } from "../../api";
import { DataContext } from "../../contexts/DataProvider";
import Progress from "../../components/Progress";

const UpdateTreatments = ({ patientID }) => {
  const { setOpenRightPanel, user, typeOfUser } = useContext(AuthContext);
  const { setCurrentPatientData, currentPatientData } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const [treatments, setTreatments] = useState([]);
  const [treatmentsFromDB, setTreatmentsFromDB] = useState([]);

  const TreatmentTemplate = {
    id: uuidv4(),
    therapies: [],
    meds: [],
    treatment_name: "",
    userName: "",
    userType: "",
    isDoctorApproved: false,
  };

  const AddNewTreatment = () => {
    const newTreatment = {
      id: uuidv4(),
      therapies: [],
      meds: [],
      treatment_name: "",
      userName: user?.fullname,
      userType: typeOfUser,
      isDoctorApproved: typeOfUser === "DOCTOR" ? true : false,
    };
    setTreatments((prevTreatments) => [...prevTreatments, newTreatment]);
  };

  const getTreatments = async () => {
    setIsLoading(true);
    const result = await fetchPatientTreatments(patientID);
    setCurrentPatientData((prev) => {
      return {
        ...prev,
        data: result,
      };
    });
    setTreatments(result);
    setTreatmentsFromDB(result);
    setIsLoading(false);
  };

  const updateTreatments = (dataList, type, treatmentID) => {
    const myTreatments = [...treatments];
    if (type === "Therapy") {
      myTreatments.find((x) => x.id === treatmentID).therapies =
        dataList.filter((item) => {
          return item.therapy_name.trim().length > 0;
        });
    } else if (type === "Meds") {
      myTreatments.find((x) => x.id === treatmentID).meds = dataList.filter(
        (item) => {
          return item.drug_name.length > 0;
        }
      );
    } else if ((type = "treatment")) {
      myTreatments.find((x) => x.id === treatmentID).treatment_name = dataList;
    }

    setTreatments(myTreatments);
  };
  const deleteTreatment = (treatmentID) => {
    setTreatments([...treatments.filter((x) => x.id !== treatmentID)]);
  };

  const changeDoctorApproveStatus = (treatmentID) => {
    const index = treatments.findIndex(
      (treatment) => treatment.id === treatmentID
    );

    if (index !== -1) {
      const updatedTreatments = [...treatments];
      updatedTreatments[index] = {
        ...updatedTreatments[index],
        isDoctorApproved: true,
      };

      setTreatments(updatedTreatments);
    } else {
      console.log(`Treatment with ID ${treatmentID} not found.`);
    }
  };

  const validatingTreatments = () => {
    const tempTreatments = [];
    for (let i = 0; i < treatments.length; i++) {
      if (
        treatments[i]?.meds.length === 0 &&
        treatments[i]?.therapies.length === 0
      ) {
        toast.error("Every treatment has atleast one med or therapy.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
      const meds = [];
      const therapies = [];

      for (let j = 0; j < treatments[i]?.meds?.length; j++) {
        meds.push({
          drug_name: treatments[i]?.meds[j]?.drug_name.trim(),
          dosage: treatments[i]?.meds[j]?.dosage,
          sideEffects: treatments[i]?.meds[j]?.sideEffects.trim(),
          tradeName: treatments[i]?.meds[j]?.tradeName?.trim(),
          usage: treatments[i]?.meds[j]?.usage?.trim(),
        });
      }
      for (let k = 0; k < treatments[i]?.therapies?.length; k++) {
        therapies.push({
          therapy_name: treatments[i]?.therapies[k]?.therapy_name.trim(),
          hoursPerSession: treatments[i]?.therapies[k]?.hoursPerSession?.trim(),
          costPerHours: treatments[i]?.therapies[k]?.costPerHours?.trim(),
          sessionsPerWeek: treatments[i]?.therapies[k]?.sessionsPerWeek.trim(),
        });
      }
      tempTreatments.push({
        id: treatments[i]?.id,
        treatment_name: treatments[i]?.treatment_name?.trim(),
        meds: meds,
        therapies: therapies,
        userName: treatments[i]?.userName,
        userType: treatments[i]?.userType,
        isDoctorApproved: treatments[i]?.isDoctorApproved,
      });
    }

    setTreatments(tempTreatments);
    return true;
  };

  const addNewSymptomToSymptomsCollection = async (otherSymptom) => {
    console.log("inside updation");
    const symptomsCollectionRef = collection(FirestoreDB, "symptoms");
    try {
      if (otherSymptom) {
        const symptomDocRef = doc(symptomsCollectionRef, otherSymptom);
        const symptomDocSnapshot = await getDoc(symptomDocRef);
        if (!symptomDocSnapshot.exists()) {
          await setDoc(symptomDocRef, {});
        } else {
        }
      }
    } catch (error) {
      console.error(
        "Error adding new symptom to the 'symptoms' collection:",
        error
      );
    }
  };

  const UpdatetoFirebase = async () => {
    setIsLoading(true);
    if (validatingTreatments()) {
      try {
        for (let i = 0; i < treatments.length; i++) {
          const treatmentRef = doc(
            FirestoreDB,
            "patientsList",
            patientID,
            "treatments",
            treatments[i].id
          );

          const docSnapshot = await getDoc(treatmentRef);
          if (docSnapshot.exists()) {
            console.log(
              `Document with treatmentId ${treatments[i].id} already exists, skipping.`
            );
            continue;
          }

          await setDoc(treatmentRef, {
            meds: btoa(JSON.stringify(treatments[i].meds || [])),
            therapies: btoa(JSON.stringify(treatments[i].therapies || [])),
            treatment_name: btoa(
              treatments[i].treatment_name || "Symptom #" + (i + 1)
            ),
            userName: btoa(treatments[i].userName),
            userType: treatments[i].userType,
            isDoctorApproved: treatments[i].isDoctorApproved,
          });

          treatments[i].meds &&
            treatments[i].meds?.forEach((each) => {
              return (
                each.symptom && addNewSymptomToSymptomsCollection(each?.symptom)
              );
            });
        }
        setCurrentPatientData((prev) => {
          return {
            ...prev,
            data: treatments,
          };
        });
        toast.success("Treatments updated successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        setOpenRightPanel(false);
      } catch (error) {
        console.log(error, "error");
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setTreatments([]);
    if (patientID) {
      getTreatments();
    }
  }, [patientID]);

  if (isLoading) return <Progress />;

  return (
    <Paper
      className="fullHeight"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      {treatments.length > 0 && (
        <Box className="fullWidth" sx={{ flex: 0.88, overflowY: "auto" }}>
          {treatments?.map((treatment, i) => {
            return (
              <Treatments
                key={i}
                index={i}
                treatment={treatment}
                updateTreatments={updateTreatments}
                deleteTreatment={deleteTreatment}
                patientID={patientID}
                setIsLoading={setIsLoading}
                treatmentsFromDB={treatmentsFromDB}
                getTreatments={getTreatments}
                changeDoctorApproveStatus={changeDoctorApproveStatus}
                typeOfUser={typeOfUser}
              />
            );
          })}
        </Box>
      )}
      <ToastContainer autoClose={false} />
      {!treatments.length > 0 && (
        <Box
          sx={{
            flex: 0.88,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Treatments Available
        </Box>
      )}
      <Box
        className="fullWidth"
        sx={{
          flex: 0.12,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          gap: 1,
        }}
      >
        <CustomButton
          variant="extended"
          color="primary"
          ChangeHandler={AddNewTreatment}
        >
          <Add />
          Add New Treatment
        </CustomButton>
        <CustomButton
          variant="extended"
          color="secondary"
          ChangeHandler={UpdatetoFirebase}
          disabled={treatments.length === 0}
        >
          Save Changes
          <Upgrade />
        </CustomButton>
      </Box>
    </Paper>
  );
};

export default memo(UpdateTreatments);
