import React from 'react';
import { LinkedIn } from '@mui/icons-material';
import './SocialLinks.css';

const LinkedInIcon = ({ link,color }) => {
  return (
    <div>
      <a
        href={link || 'https://www.linkedin.com/company/mindlycare/'}
        target='_blank'
        rel='noopener noreferrer'
        className='socialIcon'
      >
        <LinkedIn sx={{ color: color }}/>
      </a>
    </div>
  );
};

export default LinkedInIcon;
