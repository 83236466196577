import React, { useContext, useState } from "react";
import { Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { deleteUserAccountInfo } from "../../../api";
import { DataContext } from "../../../contexts/DataProvider";
import AuthContext from "../../../contexts/AuthContext";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const DeleteAccount = () => {
  const { logout, user, typeOfUser } = useContext(AuthContext);
  const { resetStates } = useContext(DataContext);
  const [openDialog, setOpenDialog] = useState(true);

  const deleteAccountHandler = async () => {
    // setIsLoading(true);
    logout();
    const response = await deleteUserAccountInfo(user?.uid, typeOfUser);
    if (response) {
      console.log("Account deletion confirmed");
      resetStates();
    }
    setOpenDialog(false); // Close the dialog after deletion, regardless of the result
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
       <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" fontFamily={"Lato"}>
          Are you sure you want to delete this account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" fontFamily={"Lato"}>
            This account will be deleted permanently. You can't undo this action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
            sx={{
              color: "var(--clr-theme-purple)",
              border: "1px solid var(--clr-theme-purple)",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={deleteAccountHandler}
            color="error"
            sx={{ backgroundColor: "var(--clr-theme-purple)" }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteAccount;
