import { Box, CircularProgress, Typography } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import AuthContext from "../../../contexts/AuthContext";
import { FirestoreDB } from "../../../utils/firebase/firebase";

const ProcessVideo = ({
  videoDetails,
  patientID,
  isPlaying,
  setIsPlaying,
  processVideoStatusMessage,
  processVideos,
  setIsProcessedVideoPlaying,
  setIsRawVideoPlaying,
  setIsFullProcessedVideoPlaying,
}) => {
  const { user, typeOfUser } = useContext(AuthContext);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {processVideos ? (
        <ReactPlayer
          className="react-player"
          width={"100%"}
          url={processVideos?.fileDetails?.processedVideoUrl}
          type={processVideos?.fileDetails?.fileType}
          controls={true}
          playing={isPlaying}
          // onPlay={() => setIsPlaying(true)}
          // onPause={() => setIsPlaying(false)}
          onPlay={() => {
            setIsRawVideoPlaying(false); // Pause full raw video when playing raw video
            setIsProcessedVideoPlaying(true); // play processed video when playing raw video
            setIsFullProcessedVideoPlaying(false); // Pause full processed video when playing raw video
          }}
          onPause={() => setIsProcessedVideoPlaying(false)}
        />
      ) : (
        <Box
          className="fullHeight flexBox flexColumn flexCenter"
          sx={{
            gap: 4,
          }}
        >
          <Typography variant="h6" fontFamily={"Lato"}>
            {processVideoStatusMessage}
          </Typography>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ProcessVideo;
