import { CalendarMonthOutlined, HomeOutlined, PeopleOutlined, SettingsOutlined,QueryStatsOutlined, MenuBookOutlined, Message} from "@mui/icons-material";
import { Divider } from "@mui/material";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { MdFiberNew } from "react-icons/md";

export const LeftMenu = [
  { key: "Home", name: "My Dashboard", icon: <HomeOutlined />, path: "/" },
  {
    key: "Patient List",
    name: "Patient List",
    icon: <PeopleOutlined sx={{ color: "var(--clr-theme-white)" }} />,
    collapsible: true,
  },
  {
    key: "Appointments",
    name: "Appointments",
    icon: <CalendarMonthOutlined />,
    path: "/appointments",
  },
  {
    key: "Statistics",
    name: "Statistics",
    icon: <QueryStatsOutlined />,
    path: "/statistics",
  },
  {
    key: "HistoricalData",
    name: "Historical Data",
    icon: <MenuBookOutlined />,
    path: "/historical",
  },
  {
    key: "Messaging",
    name: "Messaging",
    icon: <Message />,
    additionalIcon: <MdFiberNew />,
    path: "/message",
  },
  { key: "Settings", name: "Settings", icon: <SettingsOutlined />, path: "/settings" },
];

export const LeftMenu_BottomSection = [
  {
    key: "Privacy",
    name: "Privacy Statement",
    icon: <PrivacyTipIcon fontSize="small" />,
    path: "/Privacy",
  },
  {
    key: "Termcondition",
    name: "Terms and Conditions",
    icon: <BrandingWatermarkIcon fontSize="small" />,
    path: "/Termcondition",
  },
  {
    key: "Contact",
    name: "Contact Information",
    icon: <ContactPhoneIcon fontSize="small" />,
    path: "/Contact",
  },
  // {
  //   key: "Userguide",
  //   name: "User guide",
  //   icon: <MenuBookIcon fontSize="small" />,
  //   path: "/Userguide",
  // },
];

export const DRAWER_WIDTH = 297;
