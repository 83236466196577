import React from "react";

import AuthProvider from "./contexts/AuthProvider";
import UIProvider from "./contexts/UIProvider";
import AppRoutes from "./routes";

function App() {
  return (
    <AuthProvider>
      <UIProvider>
        <AppRoutes />
      </UIProvider>
    </AuthProvider>
  );
}

export default App;
