/**
 * ContactInformation Component
 *
 * This component renders a contact information page with a form for users to send messages.
 * It includes sections for displaying contact details and a form for user input.
 *
 * @module ContactInformation
 */

import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';

import MainLayout from '../../hoc/MainLayout';
import { FirestoreDB } from '../../utils/firebase/firebase';
import ContactInfo from './ContactInfo.js';
import ContactForm from './ContactForm.js';
import { INITIAL_FORM_STATE, validateForm } from './ContactUtils.js';

import './ContactInformation.css';

/**
 * Main ContactInformation component
 * @returns {React.Component} ContactInformation component
 */
const ContactInformation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);

  /**
   * Handles input changes in the form
   * @param {Object} e - Event object
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  /**
   * Handles form submission
   * @param {Object} e - Event object
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm(formData)) return;

    setIsLoading(true);
    try {
      const mailDocId = uuidv4();
      const mailRef = doc(FirestoreDB, 'emails', mailDocId);
      await setDoc(mailRef, {
        type: 'contactUs',
        ...formData,
        date: Timestamp.now(),
      });
      toast.success('Successfully sent mail');
      setFormData(INITIAL_FORM_STATE);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainLayout>
      <Box className="fullWidth fullHeight flexCenter flexBox">
        <section className="fullWidth">
          <Box className="section-header">
            <Box className="container">
              <Typography>
                Want to get in touch? We'd love to hear from you. Here's how you
                can reach us.
              </Typography>
            </Box>
          </Box>

          <Box className="container">
            <Box className="row">
              <ContactInfo />
              <ContactForm
                formData={formData}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
              />
            </Box>
          </Box>
        </section>
      </Box>
    </MainLayout>
  );
};

export default ContactInformation;
