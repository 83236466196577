import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../../hoc/MainLayout";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DocumentsUpload from "./DocumentsUpload";
import "./Historical.css";
import UploadAttachment from "../../pages/Historical/UploadAttachment";
import { useNavigate } from "react-router-dom";
import AssessmentAnalytics from "./AssessmentAnalytics";
import BehaviourGoals from "./BehaviourGoals";
import AuthContext from "../../contexts/AuthContext";
import { DataContext } from "../../contexts/DataProvider";
import { fetchAllAttachmentsByUser } from "../../api";
import { Search } from "@mui/icons-material";

const Historical = () => {
  const navigate = useNavigate();
  const [showVideoUpload, setShowVideoUpload] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, typeOfUser } = useContext(AuthContext);
  const [toggleStates, setToggleStates] = useState({});
  const [selectedPatient, setSelectedPatient] = useState("All");
  const [assessmentIndices, setAssessmentIndices] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedDocData, setSelectedDocData] = useState(null);
  const { patientData } = useContext(DataContext);

  useEffect(() => {
    if (user && typeOfUser === "PATIENT") {
      // Setting the default selected patient if the user is a patient
      setSelectedPatient(user?.uid);
    }
  }, [user, typeOfUser]);

  useEffect(() => {
    if (user && typeOfUser === "PATIENT") {
      // Setting the default selected patient if the user is a patient
      setSelectedPatient(user?.uid);
    }
  }, [user, typeOfUser]);

  useEffect(() => {
    if (user && typeOfUser === "PATIENT") {
      // Setting the default selected patient if the user is a patient
      setSelectedPatient(user?.uid);
    }
  }, [user, typeOfUser]);

  useEffect(() => {
    if (user && typeOfUser === "PATIENT") {
      // Setting the default selected patient if the user is a patient
      setSelectedPatient(user?.uid);
    }
  }, [user, typeOfUser]);

  useEffect(() => {
    if (typeOfUser === "DOCTOR" && selectedPatient !== "All") {
      const patientDocs = documents.find((doc) => {
        console.log("doc::", doc);
        return doc?.patientId === selectedPatient;
      });
      console.log("patientDocs", patientDocs);
      if (patientDocs) {
        const attachmentData = patientDocs?.attachmentData || [];
        // setAssessmentIndices(attachmentData?.map((_, index) => index));
        setAssessmentIndices(
          attachmentData.map(
            (assessment) => assessment?.docData?.info?.fileName
          )
        );
      }
    } else if (typeOfUser === "PATIENT") {
      // setAssessmentIndices(documents?.map((_, index) => index));
      setAssessmentIndices(
        documents
          ?.map((doc) => {
            return doc?.docData?.info?.fileName;
          })
          .flat() // Flatten the array in case there are nested arrays
      );
    }
  }, [selectedPatient, documents]);

  useEffect(() => {
    if (
      typeOfUser === "DOCTOR" &&
      selectedIndex !== "" &&
      selectedPatient !== "All"
    ) {
      const patientDocs = documents?.find(
        (doc) => doc?.patientId === selectedPatient
      );
      if (patientDocs) {
        const docData = patientDocs?.attachmentData[selectedIndex];
        setSelectedDocData(docData || null);
      }
    } else if (typeOfUser === "PATIENT") {
      setSelectedDocData(documents[selectedIndex]);
    }
  }, [selectedIndex, selectedPatient, documents]);

  const handleSelectPatient = (event) => {
    setSelectedPatient(event.target.value);
    setAssessmentIndices([]);
    setSelectedIndex("");
    setSelectedDocData(null);
  };

  const handleSelectIndex = (event) => {
    setSelectedIndex(event.target.value);
  };

  const PatientSelect = (
    <FormControl fullWidth>
      <InputLabel>Select Patient</InputLabel>
      <Select
        value={selectedPatient}
        label="Select Patient"
        onChange={handleSelectPatient}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{ backgroundColor: "#fff" }}
      >
        <MenuItem value={"All"}>{"All Patients"}</MenuItem>
        {patientData?.map((patient) => (
          <MenuItem key={patient.patientId} value={patient.patientId}>
            {patient?.patientInfo?.fullname
              ? patient?.patientInfo?.fullname
                  .split(/\s+/)
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")
              : ""}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const AssessmentIndexSelect = (
    <FormControl fullWidth>
      <InputLabel color="secondary">Select Assessment</InputLabel>
      <Select
        value={selectedIndex}
        label="Select Assessment"
        onChange={handleSelectIndex}
        color="secondary"
      >
        {assessmentIndices && assessmentIndices.length > 0 ? (
          assessmentIndices.map((fileName, index) => (
            <MenuItem sx={{ p: 1.5 }} divider key={index} value={index}>
              {index + 1}. {fileName}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="">
            <Typography variant="body2">
              Assessments are not available.
            </Typography>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );

  const handleUploadClick = () => {
    setShowVideoUpload(true);
  };

  const handleCloseVideoUpload = () => {
    setShowVideoUpload(false);
  };

  const handleSmartSearch = () => {
    navigate("/smartsearch");
  };

  // const fetchDocuments = async () => {
  //   if (!user) return;
  //   setLoading(true);
  //   try {
  //     const fetchedDocuments = await fetchAllAttachmentsByUser(
  //       user?.uid,
  //       typeOfUser
  //     );

  //     setDocuments(fetchedDocuments?.data);
  //     const initialToggleStates = fetchedDocuments?.data.reduce((acc, doc) => {
  //       doc.attachmentData.forEach((attachment) => {
  //         if (attachment?.docData?.isPrivate) {
  //           acc[attachment.docId] = attachment?.docData?.isPrivate;
  //         }
  //       });
  //       return acc;
  //     }, {});
  //     setToggleStates(initialToggleStates);
  //   } catch (error) {
  //     console.log("Error fetching documents:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchDocuments = async () => {
    if (!user) return;
    setLoading(true);
    let fetchedDocuments;
    try {
      if (typeOfUser === "DOCTOR") {
        fetchedDocuments = await fetchAllAttachmentsByUser(
          user?.uid,
          typeOfUser
        );

        const filteredDocuments = fetchedDocuments?.data?.map((patient) => {
          const filteredAttachments = patient?.attachmentData?.filter(
            (attachment) => {
              // Show document if `isPrivateAll` is false or if the current user uploaded it
              if (attachment.docData.isPrivateAll) {
                return attachment.docData.uploadedId === user.uid;
              }
              return true; // Show the document if `isPrivateAll` is false
            }
          );

          return {
            ...patient,
            attachmentData: filteredAttachments,
          };
        });

        setDocuments(filteredDocuments);
      } else if (typeOfUser === "PATIENT") {
        fetchedDocuments = await fetchAllAttachmentsByUser(
          user?.uid,
          typeOfUser
        );

        setDocuments(fetchedDocuments?.data);
      }
      const initialToggleStates = fetchedDocuments?.data.reduce((acc, doc) => {
        doc.attachmentData.forEach((attachment) => {
          if (attachment?.docData?.isPrivate) {
            acc[attachment.docId] = attachment?.docData?.isPrivate;
          }
        });
        return acc;
      }, {});
      setToggleStates(initialToggleStates);
    } catch (error) {
      console.log("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    user && fetchDocuments();
  }, [user]);

  return (
    // <MainLayout>
    //   <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         justifyContent: "space-between",
    //       }}
    //     >
    //       <Box sx={{ padding: "20px", textAlign: "center" }}>
    //         <Typography
    //           variant="h5"
    //           sx={{ fontFamily: "Lato", fontWeight: 700 }}
    //         >
    //           Uploaded Documents
    //         </Typography>
    //       </Box>
    //       <Box>
    //         <Button
    //           variant="contained"
    //           onClick={handleUploadClick}
    //           style={{
    //             marginRight: 40,
    //             backgroundColor: "var(--clr-theme-purple)",
    //           }}
    //         >
    //           Upload Attachments
    //         </Button>
    //         <UploadAttachment
    //           open={showVideoUpload}
    //           handleClose={handleCloseVideoUpload}
    //         />
    //       </Box>
    //     </Box>
    //     <Box sx={{ padding: "20px" }}>
    //       <DocumentsUpload />
    //     </Box>
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         bottom: 0,
    //         left: "20rem",
    //         right: 0,
    //         textAlign: "center",
    //         padding: "30px",
    //       }}
    //     >
    //       <Button
    //         size="large"
    //         variant="contained"
    //         sx={{ backgroundColor: "var(--clr-theme-purple) !important" }}
    //         onClick={handleSmartSearch}
    //       >
    //         SMART SEARCH
    //       </Button>
    //     </Box>
    //   </Box>
    // </MainLayout>

    <MainLayout>
      <Box
        className=" fullHeight"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // column for mobile, row for larger screens
          // border: "2px solid",
          flexWrap: { xs: "nowrap", sm: "wrap" },
          gap: 1,
          width: "100%",
        }}
      >
        <Card
          sx={{
            // border: "2px solid green",
            width: { xs: "100%", sm: "68%" }, // 100% for mobile, 60% for larger screens
            height: { xs: "100%", sm: "50%" },
            border: "1px solid lightGray",
            minHeight: "50%",
          }}
        >
          <AssessmentAnalytics
            documents={documents}
            selectedPatient={selectedPatient}
            selectedIndex={selectedIndex}
            selectedDocData={selectedDocData}
            PatientSelect={PatientSelect} // Passing PatientSelect as a prop
            AssessmentIndexSelect={AssessmentIndexSelect} // Passing AssessmentIndexSelect as a prop
            typeOfUser={typeOfUser}
          />
        </Card>
        <Card
          sx={{
            // border: "2px solid red",
            width: { xs: "100%", sm: "30%" }, // 100% for mobile, 40% for larger screens
            height: { xs: "100%", sm: "50%" },
            p: 1,
            overflow: "auto",
            border: "1px solid var(--clr-theme-lgray)",
            minHeight: "50%",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            sx={{
              fontFamily: "Lato",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Documents Uploads
          </Typography>
          <DocumentsUpload selectedDocData={selectedDocData} />
        </Card>
        <Card
          sx={{
            // border: "2px solid blue",
            width: { xs: "100%", sm: "68%" }, // 100% for mobile, 60% for larger screens
            height: { xs: "100%", sm: "50%" },
            border: "1px solid var(--clr-theme-lgray)",
            minHeight: "50%",
          }}
        >
          <BehaviourGoals selectedDocData={selectedDocData} />
          {/* <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: "1rem",
              right: 0,
              textAlign: "center",
              padding: "14px",
            }}
          >
            <Button
              size="large"
              variant="contained"
              sx={{
                backgroundColor: "var(--clr-theme-purple) !important",
                textTransform: "capitalize",
              }}
              onClick={handleSmartSearch}
            >
              <Search sx={{ mr: 1 }} /> smart search
            </Button>
          </Box> */}
        </Card>

        <Card
          sx={{
            // border: "2px solid purple",
            width: { xs: "100%", sm: "30%" }, // 100% for mobile, 40% for larger screens
            height: { xs: "100%", sm: "50%" },
            border: "1px solid var(--clr-theme-lgray)",
            minHeight: "50%",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            sx={{
              fontFamily: "Lato",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            25 Metrics/Other
            <br /> METRICS
          </Typography>
        </Card>
      </Box>
    </MainLayout>
  );
};

export default Historical;
