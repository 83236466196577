import React, { useEffect } from "react";
import { AppBar, Box, Button, Card, CardContent, Grid, List, ListItem, ListItemAvatar, ListItemText, Toolbar, Typography } from "@mui/material";
import NewLogo from "../../../assets/images/NewLogo.png"
import LogoWhite from "../../../assets/images/LogoWhite.png"
import Footer from "../Footer";
import "./Privacy.css";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";
import FacebookIcon from "../SocialLinks/Facebook";
import TwitterIcon from "../SocialLinks/Twitter";
import LinkedInIcon from "../SocialLinks/LinkedIn";
import HomePage from "../HomePage";
import OurStory from "../OurStory";
import OurTeam from "../OurTeam";
import Contact from "../Contact";
import { useNavigate } from "react-router-dom";

const PrivacyLandingpage = () => {

  const [colorChange, setColorChange] = React.useState(false);
  const [activeButton, setActiveButton] = React.useState("Home");
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const mobileMenuId = "primary-search-account-menu-mobile";

  const getCurrentYear = () => new Date().getFullYear();

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleFootermenuclick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handlePrivacy = () => {
    navigate('/privacy-policy');
  }

  const handleTermsConditions = () => {
    navigate('/terms-and-conditions');
  }

  const handleAbout = () => {
    navigate('/main');
  }

  useEffect(() => {
    const adjustWidth = () => {
      const rootElement = document.getElementById("root"); 
      if (rootElement) {
        rootElement.style.overflowX = "hidden"; 
      }
    };

    adjustWidth();
    window.addEventListener("resize", adjustWidth); 

    return () => {
      window.removeEventListener("resize", adjustWidth); 
    };
  }, []);

  return (
    <Box className="privacy">
        <AppBar position="sticky" sx={{ bgcolor: "var(--clr-theme-purple)" }}>
          <Toolbar>
            <ListItemAvatar
              sx={{
                display: { xs: "flex", md: "flex" },
                mx: 1,
                cursor: "pointer",
              }}
              onClick={handleAbout}

            >
              {colorChange ? (<img src={NewLogo} height="50px" />) : (<img src={LogoWhite} height="50px" />)}
            </ListItemAvatar>
            <Typography
              className="brand"
              variant="h6"
              noWrap
              sx={{
                // display: { xs: "none", md: "flex" },
                letterSpacing: ".1rem",
                color: colorChange ? "var(--clr-theme-purple)" : "inherit",
                fontSize: "24px !important",
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              Mindly
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            {/* <Box sx={{ display: { xs: "none", md: "flex", gap: 25 } }}>
              <Button
                variant="text"
                color={colorChange ? "secondary" : "inherit"}
                sx={{  color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
                onClick={() => {
                  setCurrentPage(<HomePage />);
                  handleButtonClick("Home");
                  handleMenuClose();
                }}
                className={activeButton === "Home" ? "active" : "notActive"}
              >
                Home
              </Button>
              <Button
                variant="text"
                color={colorChange ? "secondary" : "inherit"}
                sx={{ color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
                onClick={
                  () => {
                  setCurrentPage(<OurStory />);
                  handleButtonClick("about");
                  handleMenuClose();
                }
              }
                className={activeButton === "about" ? "active" : "notActive"}
              >
                About
              </Button>
              <Button
              variant="text"
              color={colorChange ? "secondary" : "inherit"}
              sx={{ color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
              onClick={() => {
                setCurrentPage(<OurTeam />);
                handleButtonClick("team")
                handleMenuClose();
              }}
              className={activeButton === "team" ? "active" : "notActive"}
              >
                Team
              </Button>
              <Button
              variant="text"
              color={colorChange ? "secondary" : "inherit"}
              sx={{ color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
              onClick={() => {
                setCurrentPage(<Contact />);
                handleButtonClick("Contact");
                handleMenuClose();
              }}
              className={activeButton === "Contact" ? "active" : "notActive"}
              >
                Contact
              </Button>
            </Box> */}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                sx={{ color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {/* {renderMobileMenu} */}
      <Box
        sx={{
          maxWidth: "90%",
          margin: "20px auto",
          padding: 2,
          overflow: "auto",
        }}
      >
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Mindly Care Inc. Privacy Policy
          </Typography>
          <p>Effective: November 9, 2023</p>
          <Typography variant="h6">Introduction</Typography>
          <Typography paragraph>
            This Privacy Policy (“Policy”) describes how Mindly Care Inc.
            (“Mindly,” “we,” “our,” “us”) collects, uses, and discloses certain
            personal information obtained through our mobile platform (“App”)
            and through our website (“Site”), which is located at [mindly.care],
            as well as any information that we process as part of offering you
            our services (collectively with the App and Site, the “Services”).
            By using the Services, you are agreeing to the terms of this Policy.
            <br />
            Using Mindly as a Health Care Professional, you can track and
            monitor the progress of prescribed health care treatments and the
            status of health conditions. As a Health Account Owner, you can
            create and update your health care information and track your
            treatment. We are committed to being transparent about our privacy
            practices and will continue to update this Policy as necessary.
            <br />
            Please note that, in some situations, we may process protected
            health information (PHI) as a business associate regulated under the
            Health Insurance Portability and Accountability Act (HIPAA). In
            those cases, this Policy may not apply.
          </Typography>
          <Typography variant="h6" gutterBottom>
            What Information We Collect and Maintain About You
          </Typography>
          <Typography paragraph>
            We collect personal and other information from you directly when you
            provide it to us through the Services. We also automatically collect
            certain information about you and your computer, smartphone, or
            other device when you use, access, or interact with the Services. We
            may also collect information from third parties in relation to
            providing you the Services.
            <br />
            <br />
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Health Care Professionals:
            </span>{" "}
            If you create an account with Mindly as a Health Care Professional,
            we may process the following personal information about you: [your
            name, email and account password and information about your employer
            or organization.]
            <br />
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Health Account Owners:
            </span>
            If you create an account with Mindly as a Health Account Owner, we
            may process the following personal information about you and your
            affiliated minor: [your name, email and account password, your
            affiliated minor’s name and any of your affiliated minor’s heath
            information that you choose to provide us including audio, video or
            other multi-media.]
            <br />
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Other personal information collected through the Services:
            </span>
            You can visit the Site or download the App without submitting any
            information that we can use to identify you personally. However, to
            use certain features, such as the “Contact Us” feature or to sign up
            for our newsletter, you will be required to provide personal
            information. Such information could include, for example, your name,
            phone number and email address.
            <br />
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Web log data:
            </span>
            When you use the Services, we automatically receive and record
            certain information from your computer (or other device) and your
            browser. This may include such data as your IP address and domain
            name, the pages you visit on the Site, information about your
            activity on a page, the date and time of your visit, the files that
            you download, the URLs from the websites you visit before and after
            navigating to the Site, your software and hardware attributes
            (including device IDs), your general geographic location (e.g., your
            city, state, or metropolitan region), and certain cookie information
            (see below). To obtain such information, we may use web logs or
            applications that recognize your computer (or other device) and
            gather information about its online activity.
            <br />
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Linked services:
            </span>
            If you link your email account or your calendar with us (such as
            your Google Calendar), we may, with your permission, collect
            information from those linked services as part of offering our
            Services.
            <br />
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Cookies:
            </span>
            We use cookies on the Services. Cookies are small files that are
            stored on your computer (or other device) by your web browser. A
            cookie allows the Site to recognize whether you have visited before
            and may store user preferences and other information. For example,
            cookies can be used to collect or store information about your use
            of the Site during your current session and over time (including the
            pages you view and the files you download), your computer’s or
            device’s operating system and browser type, your Internet service
            provider, your domain name and IP address, your general geographic
            location, the website that you visited before visiting the Site, and
            the link you used to leave the Site. If you are concerned about
            having cookies on your computer, you can set your browser to refuse
            all cookies or to indicate when a cookie is being set, allowing you
            to decide whether to accept it. You can also delete cookies from
            your computer or device. However, if you choose to block or delete
            cookies, certain features of the Site may not operate correctly.{" "}
            <br />
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Web beacons:
            </span>
            The Site or the emails that you receive from Mindly may use an
            application known as a “web beacon” (also known as a “clear gif” or
            “web bug”). A web beacon is an electronic file that usually consists
            of a single-pixel image. It can be embedded in a web page or in an
            email to transmit information, which could include personal
            information. For example, it can allow an email sender to determine
            whether a user has opened a particular email.
            <br />
            <span
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                marginBottom: "0.5em",
              }}
            >
              Third-party online tracking and behavioral advertising:
            </span>
            We may partner with certain third parties to collect, analyze, and
            use the personal and other information described in this section.
            For example, we may allow third parties to set cookies or use web
            beacons on the Site or in email communications from us. This
            information may be used for a variety of purposes, including online
            interest-based advertising, as discussed below (see the section
            entitled “With Whom and Why We Share Your Information”).
          </Typography>
          {/* Add more sections based on the information you collect */}
          <Typography variant="h6" gutterBottom>
            How We Use and Process Your Information
          </Typography>
          <Typography paragraph>
            We use the information that we collect for a variety of purposes.
            Our legal bases for processing your personal information are: <br />
            1. our legitimate interest in running and maintaining our business{" "}
            <br />
            2. performance and fulfillment of our contracts
            <br />
            3. your consent <br />
            4. compliance with our legal obligations. <br />
            In many instances, more than one of these legal bases apply to the
            processing of your personal information.
            <br />
            The purposes for which we use your information include to: <br />
            <br />
            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li>
                Provide you with the Services, including administering your
                account.
              </li>
              <li>
                {" "}
                Respond to your questions or requests concerning the Services.
              </li>
              <li>Fulfill the terms of any agreement you have with us</li>
              <li>
                Fulfill your requests for the Services or otherwise complete a
                transaction that you initiate.{" "}
              </li>
              <li>
                {" "}
                Send you information about the Services and other topics that
                are likely to be of interest to you, including newsletters,
                updates, or other communications, including promotional emails.
              </li>
              <li>Improve our algorithms.</li>
              <li>
                {" "}
                Deliver confirmations, account information, notifications, and
                similar operational communications.
              </li>
              <li>
                Improve your user experience and the quality of our products and
                Services.
              </li>
              <li>Comply with legal and/or regulatory requirements.</li>
              <li>Aggregate and deidentify information.</li>
              <li>Serve advertisements.</li>
              <li>
                Analyze how visitors use the Services and various Services
                features, including to count and recognize visitors to the
                Services.
              </li>
              <li>Create new products and services.</li>
              <li>Manage our business.</li>
            </ul>
            We may link information gathered through the Services with
            information that we collect in other contexts. But in that event, we
            will handle the combined information in a manner consistent with
            this Policy.
          </Typography>
          {/* Add more sections based on how you use the collected information */}
          <Typography variant="h6" gutterBottom>
            With Whom and Why We Share Your Information
          </Typography>
          <Typography paragraph>
            We share your information with other parties for a variety of
            purposes, as described below.
            <br />
            <br />
            <span
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                marginBottom: "0.5em",
              }}
            >
              Health Care Professionals:
            </span>
            If you are a Health Account Owner, we may share your information and
            information about your affiliated minor with any Health Care
            Professionals that you have authorized to receive this information
            through the Services. Health Care Professionals may further disclose
            some of your information in order to further provide you the
            Services.
            <br />
            <span
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                marginBottom: "0.5em",
              }}
            >
              Third-party service providers:
            </span>
            Mindly uses third-party service providers that perform services on
            our behalf, including web-hosting companies, mailing vendors, and
            analytics providers. These service providers may collect and/or use
            your information, including information that identifies you
            personally, to assist us in achieving the purposes discussed above.
            <br />
            We may share your information with other third parties when
            necessary to fulfill your requests for services; to complete a
            transaction that you initiate; to meet the terms of any agreement
            that you have with us or our partners or to manage our business.
            <br />
            <span
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                marginBottom: "0.5em",
              }}
            >
              Analytics:
            </span>
            We partner with certain third parties to obtain the automatically
            collected information discussed above and to engage in analysis,
            auditing, research, and reporting. These third parties may use web
            logs or web beacons, and they may set and access cookies on your
            computer or other device. In particular, the Site uses Google
            Analytics to help collect and analyze certain information for the
            purposes discussed above. You may opt out of the use of cookies by
            Google Analytics here.
            <br />
            <span
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                marginBottom: "0.5em",
              }}
            >
              Interest-based advertising:
            </span>
            The Services also enable third-party tracking mechanisms to collect
            information about you and your computing devices for use in online
            interest-based advertising. For example, third parties may use the
            fact that you visited our Site to target online ads to you. In
            addition, our third-party advertising networks might use information
            about your use of our Site to help target advertisements based on
            your online activity in general. For information about
            interest-based advertising practices, including privacy and
            confidentiality, visit the Network Advertising Initiative website or
            the Digital Advertising Alliance website.
            <br />
            The use of online tracking mechanisms by third parties is subject to
            those third parties’ privacy policies, and not this Policy. If you
            prefer to prevent third parties from setting and accessing cookies
            on your computer or other device, you may set your browser to block
            cookies. Additionally, you may remove yourself from the targeted
            advertising of companies within the Network Advertising Initiative
            by opting out here, or of companies participating in the Digital
            Advertising Alliance by opting out here. Our Site currently does not
            respond to “Do Not Track” signals.
            <span
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                marginBottom: "0.5em",
              }}
            >
              Legal purposes:
            </span>
            We may use or share your information with third parties when we
            believe, in our sole discretion, that doing so is necessary:
            <br />
            <br />
            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li>
                To comply with applicable law or a court order, subpoena, or
                other legal process.
              </li>
              <li>
                To investigate, prevent, or take action regarding illegal
                activities, suspected fraud, violations of our terms and
                conditions, or situations involving threats to our property or
                the property or physical safety of any person or third party.
              </li>
              <li>
                To establish, protect, or exercise our legal rights or defend
                against legal claims; or{" "}
              </li>
              <li>
                To facilitate the financing, securitization, insuring, sale,
                assignment, bankruptcy, or other disposal of all or part of our
                business or assets.{" "}
              </li>
            </ul>
            <span
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                marginBottom: "0.5em",
              }}
            >
              Aggregated and de-identified information:
            </span>
            From time to time, Mindly may share aggregated or de-identified
            information about users, such as by publishing a report on trends in
            the usage of the Services. Such aggregated information will not
            identify you personally.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Your Privacy Rights
          </Typography>
          <Typography paragraph>
            If you want to learn more about the personal information that Mindly
            has about you, or you would like to update, change, or delete that
            information, please contact us by email at support@mindly.care.{" "}
            <br />
            You may opt out of receiving marketing emails from us by following
            the instructions in those emails or by emailing us at
            support@mindly.care.
            <br />
            If you are a resident of a jurisdiction with an applicable data
            privacy law, you may have certain rights available to you in
            relation to your personal information. These rights may include:
            <br />
            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li>
                The right to be informed about our data collection practices
              </li>
              <li>The right to access and correct your data.</li>
              <li>The right to erase or delete your data.</li>
              <li>The right to data portability</li>
              <li>
                The right to opt out of the sale or sharing of your information.
              </li>
              <li>The right to opt out of targeted advertising.</li>
              <li>
                The right to opt-out of marketing emails and text messages.
              </li>
              <li>
                The right to limit our use of any automated decision-making
                processes; and
              </li>
              <li>The right to withdraw consent (to the extent applicable).</li>
            </ul>
            To exercise any of the rights listed above, please contact us via
            email at support@mindly.care. We will respond to your request as
            soon as reasonably possible and within the timeframe required under
            applicable law. Prior to complying with your request, we will first
            verify your identity by comparing the information you provide with
            the information we have on file for you. <br />
            <br />
            Additionally, you may authorize an agent to make a request on your
            behalf. To designate an agent, please provide a written and signed
            document by both you and the agent that authorizes the agent to act
            on your behalf. You may also use a power of attorney.We will still
            require you to provide information to allow us to reasonably verify
            that you are the person about whom we collected personal
            information.
            <br />
            <br />
            Depending on your jurisdiction, you may have also have the right to
            appeal our decision regarding your data privacy rights, as well as
            the right to lodge a complaint to your supervisory authority.
          </Typography>
          <Typography variant="h6" gutterBottom>
            External Links
          </Typography>
          <Typography paragraph>
            The Site may contain links to third-party websites. If you use these
            links, you will leave the Site. We have not reviewed these
            third-party sites and do not control and are not responsible for any
            of these sites, their contents, or their privacy policies. Thus, we
            do not endorse or make any representations about them, or any
            information, software, or other products or materials found there,
            or any results that may be obtained from using them. If you decide
            to access any of the third-party sites listed on our website, you do
            so at your own risk.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Data Security
          </Typography>
          <Typography paragraph>
            We employ physical, technical, and administrative procedures to
            safeguard the personal information we collect online. However, no
            service is 100% secure, and we cannot ensure or warrant the security
            of any information you transmit to the Site or to us, and you
            transmit such information at your own risk.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Data Retention
          </Typography>
          <Typography paragraph>
            We retain personal information about you necessary to fulfill the
            purpose for which that information was collected or as required or
            permitted by law. We do not retain personal information longer than
            is necessary for us to achieve the purposes for which we collected
            it. When we destroy your personal information, we do so in a way
            that prevents that information from being restored or reconstructed.
          </Typography>
          <Typography variant="h6" gutterBottom>
            International Users
          </Typography>
          <Typography paragraph>
            The information that we collect through or in connection with the
            Services is transferred to and processed in the United States for
            the purposes described above. Mindly may subcontract the processing
            of your data to, or otherwise share your data with, affiliates or
            third parties in the United States or countries other than your
            country of residence. The data protection laws in these countries
            may be different from, and less stringent than, those in your
            country of residence. By using the Services or by providing any
            personal or other information to us, you expressly consent to such
            transfer and processing.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Children
          </Typography>
          <Typography paragraph>
            Content on the Site is directed at individuals over the age of 18
            and is not directed at children under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under the age of 13 without parental consent.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Changes to this Policy
          </Typography>
          <Typography paragraph>
            We may make changes to the Services in the future and as a
            consequence will need to revise this Policy to reflect those
            changes. We will post all such changes on the Site, so you should
            review this page periodically. If we make a material change to the
            Policy, you will be provided with appropriate notice.
          </Typography>
          <Typography variant="h6" gutterBottom>
            How to Contact Us
          </Typography>
          <Typography paragraph>
            Should you have any questions or concerns about this Policy, you can
            contact us at support@mindly.care.
          </Typography>
        </CardContent>
      </Box>
      {/* {footerRequired && <Footer />} */}
      <Box>
        <Box className="footer fullWidth">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            padding={"1.3rem 3.8rem"}
          >
            <List
              className="footerCss"
              subheader={
                <Typography variant="h6" className="footerGrid">
                  Mindly
                </Typography>
              }
            >
              <ListItem
                disablePadding
                sx={{ cursor: "pointer" }}
                onClick={
                  () => {
                    // setCurrentPage(<OurStory />);
                    handleFootermenuclick("about");
                  }
                }
              >
                <ListItemText primary="About" />
              </ListItem>
              <ListItem
                disablePadding
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // setCurrentPage(<OurTeam />);
                  handleFootermenuclick("team");
                }}
              >
                <ListItemText primary="Team" />
              </ListItem>
              <ListItem
                disablePadding
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // setCurrentPage(<Contact />);
                  handleFootermenuclick("Contact");
                }}
              >
                <ListItemText primary="Contact Us" />
              </ListItem>
            </List>

            <List
              subheader={
                <Typography variant="h6" className="footerGrid">
                  Connect
                </Typography>
              }
            >
              <ListItem disablePadding>
                <ListItemText primary="1138 Desmond Ct" />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary="Fremont CA, 94539" />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary="support@mindly.care" />
              </ListItem>
            </List>
            <List
              subheader={
                <Typography variant="h6" className="footerGrid">
                  Follow us
                </Typography>
              }
            >
              <Box className="flexBox flexRow" sx={{ gap: 2, fontSize: "1.7rem" }}>
                <ListItem disablePadding>
                  <FacebookIcon />
                </ListItem>
                <ListItem disablePadding>
                  <TwitterIcon size={28} />
                </ListItem>
                <ListItem disablePadding>
                  <LinkedInIcon />
                </ListItem>
              </Box>
            </List>
          </Grid>
          <Box
            className="flexBox"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              padding: "2rem 3.8rem",
              fontFamily: "Karla",
              lineHeight: "1.5rem",
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            <span className="text">© {getCurrentYear()} All rights reserved</span>
            <span className="text" style={{ cursor: "pointer" }}>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Lato",
                  fontSize: "1.1rem",
                }}
                onClick={handlePrivacy}
              >
                Privacy Policy
              </Button>
              /
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Lato",
                  fontSize: "1.1rem",
                }}
                // onClick={() => {
                //   setCurrentPage(
                //     <TermconditionLandingpage footerRequired={true} />
                //   );
                // }}
                onClick={handleTermsConditions}
              >
                Term and Conditions
              </Button>
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default PrivacyLandingpage;
