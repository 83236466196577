import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import DoctorEditInformation from "../../../components/DoctorEditInformation";
import PatientEditInformation from "../../../components/PatientEditInformation";
import HomeCard from "../../../components/HomeCard";
import Edit from "../../../assets/images/edit.png";
import { FirestoreDB } from "../../../utils/firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";

import "./Profile.css";

function Profile() {
  const { typeOfUser, user } = useContext(AuthContext);

  
  // const [userDetails, setUserDetails] = useState(user);
  let setCollection = "";

  // useEffect(() => {
  //   typeOfUser &&  getUserDetails();
  // }, [typeOfUser]);

  // const getUserDetails = () => {
  //   if (typeOfUser === "DOCTOR") {
  //     setCollection = "doctorsList";
  //   } else if (typeOfUser === "PATIENT") {
  //     setCollection = "patientsList";
  //   }
  //   onSnapshot(doc(FirestoreDB, setCollection, user?.uid), (querySnapshot) => {
  //     if (querySnapshot.exists()) {
  //       setUserDetails({
  //         user: querySnapshot.data(),
  //         uid: user?.uid,
  //       });
  //     }
  //   });
  // };


  return (
    <HomeCard
      title="Edit your profile"
      picture={Edit}
      showAvatar
      customClass="profileCard"
    >
      {typeOfUser === "DOCTOR" && <DoctorEditInformation />}
      {typeOfUser === "PATIENT" && <PatientEditInformation />}
    </HomeCard>
  );
}

export default Profile;
