import React, { useContext, useEffect, useState } from "react";
import { FirestoreDB } from "../../utils/firebase/firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { UIContext } from "../../contexts/UIProvider";

const UnreadMessageCounts = ({
  unreadCounts,
  setUnreadCounts,
  user,
  typeOfUser,
  patientData,
}) => {
  const { setIsNewMessage } = useContext(UIContext);

  useEffect(() => {
    const fetchUnreadCounts = async () => {
      try {
        if (typeOfUser === "PATIENT") {
          const counts = [];
          const snapshot = await getDocs(
            query(
              collection(FirestoreDB, "notifications", user?.uid, "messages"),
              where("readStatus", "==", false),
              where("notificationSource", "==", "doctorMessage")
            )
          );

          const countsObj = {};

          snapshot.forEach((doc) => {
            const userId = doc.data().userId;

            const matchedPatient = user?.doctorDetails.find(
              (doctor) => doctor.doctorId === userId
            );
            if (matchedPatient) {
              const doctorId = matchedPatient.doctorId;
              if (countsObj[doctorId]) {
                countsObj[doctorId]++;
              } else {
                countsObj[doctorId] = 1;
              }
            }
          });

          for (const [doctorId, count] of Object.entries(countsObj)) {
            counts.push({ doctorId, count });
          }
          const hasNewMessages = counts.some((item) => item.count > 0);
          setIsNewMessage(hasNewMessages);
          setUnreadCounts(counts);
        } else if (typeOfUser === "DOCTOR") {
          const counts = [];
          const snapshot = await getDocs(
            query(
              collection(FirestoreDB, "notifications", user?.uid, "messages"),
              where("readStatus", "==", false),
              where("notificationSource", "==", "patientMessage")
            )
          );

          const countsObj = {};

          snapshot.forEach((doc) => {
            const userId = doc.data().userId;

            const matchedPatient = patientData.find(
              (patient) => patient.patientId === userId
            );
            if (matchedPatient) {
              const patientId = matchedPatient.patientId;
              if (countsObj[patientId]) {
                countsObj[patientId]++;
              } else {
                countsObj[patientId] = 1;
              }
            }
          });

          for (const [patientId, count] of Object.entries(countsObj)) {
            counts.push({ patientId, count });
          }

          const hasNewMessages = counts.some((item) => item.count > 0);
          setIsNewMessage(hasNewMessages);
          setUnreadCounts(counts);
        }
      } catch (error) {
        console.error("Error fetching unread message counts:", error);
      }
    };
    fetchUnreadCounts();
  }, [user, typeOfUser]);

  return null;
};

export default UnreadMessageCounts;
