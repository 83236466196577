import { CardContent, Typography, Card, Box, Button, Grid, TextField } from '@mui/material';
import { React, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import NewLogo from '../../../components/BrandLogo';



const PostCard = ({ photo, title, content, author, datePosted, userInfo, userInfoUpdated }) => {

    const [open, setOpen] = useState(false);

    const [userInfoOpen, setUserInfoOpen] = useState(null);
 
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userEmail, setUserEmail] = useState("");



    const handleReadMore = () => {
        if (!userInfo) {
            setUserInfoOpen(true);
        }
        else {
            setOpen(!open);
        }
    };

    const handleUserInfo = () => {
        const user = { userFirstName, userLastName, userEmail };
        userInfoUpdated(user);
        setUserInfoOpen(false);
        setOpen(true);
        userInfoUpdated(user);
    };

    return (
        <>

            <Grid item xs={12} sm={6} md={4}>
                <Card className="post" sx={{ border: '14px solid #d9d9d9', height: '100%', maxWidth: '400px', width: '100%', }}>
                    <CardContent className='flexBox flexCenter flexColumn' >
                        <Box
                            component="img"
                            src={photo}
                            alt={title}
                            sx={{
                                width: "100%",
                                height: "170px",
                                objectFit: "cover",
                            }}
                        />
                        <Typography
                            variant='h6'
                            sx={{
                                color: 'black', fontFamily: 'Lato', fontWeight: 400,
                                maxWidth: '100%',
                            }}

                        >
                            {title}
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{ color: 'black', fontFamily: 'Karla', fontWeight: 200 }}
                        >
                            {content.length > 320 ? `${content.substring(0, 320)}...` : content}
                        </Typography>
                        <Button size="small" onClick={handleReadMore} sx={{ color: '#9291F0', fontFamily: 'Karla' }}>
                            Read More
                        </Button>

                    </CardContent>
                </Card>
            </Grid>


            <Dialog open={userInfoOpen && userInfo == null} onClose={() => setUserInfoOpen(false)} fullWidth maxWidth="sm" scroll="paper"
                PaperProps={{
                    sx: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        margin: 0,

                    },
                }}

            >
                <Box sx={{ marginTop: "25px", display: 'flex', justifyContent: 'center' }}>
                    <NewLogo newline={false} />
                </Box>
                <DialogTitle>
                    <Typography
                        variant="h4"
                        align="center"
                        style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold' }}
                    >
                        {'WELCOME TO MINDLY.'}
                    </Typography>
                </DialogTitle>

                <Typography
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                        color: "var(--clr-theme-gray)",
                        fontFamily: "Karla",
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    {`Please enter your information below to receive future news and updates.`}
                </Typography>
                <Box sx={{ padding: '15px' }}>
                    <Box >
                        <Typography variant="subtitle1"
                            sx={{
                                color: "var(--clr-theme-gray)",
                                fontFamily: "Karla",
                                fontWeight: 700,

                            }}>
                            First Name
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            value={userFirstName}
                            sx={{ fontFamily: 'Karla', fontWeight: 250 }}
                            onChange={(e) => setUserFirstName(e.target.value)}
                        />
                    </Box>
                    <Box >
                        <Typography variant="subtitle1"
                            sx={{
                                color: "var(--clr-theme-gray)",
                                fontFamily: "Karla",
                                fontWeight: 700,

                            }}>
                            Last Name
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            value={userLastName}
                            sx={{ fontFamily: 'Karla', fontWeight: 250 }}
                            onChange={(e) => setUserLastName(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <Typography variant="subtitle1"
                            sx={{
                                color: "var(--clr-theme-gray)",
                                fontFamily: "Karla",
                                fontWeight: 700,

                            }}>
                            Email
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            type="email"
                            value={userEmail}
                            sx={{ fontFamily: 'Karla', fontWeight: 250 }}
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleUserInfo}
                            sx={{
                                backgroundColor: "#9291f0",
                                textTransform: "capitalize",
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "1.2rem",
                                width: "100%",
                            }}

                        >
                            Submit
                        </Button>
                    </div>
                </Box>

            </Dialog>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xl" scroll="paper"
                PaperProps={{
                    sx: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        margin: 0,
                    },
                }}

            >
                <DialogTitle>
                    <Typography
                        variant="h4"
                        align="center"
                        style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold' }}
                    >
                        {title}
                    </Typography>
                </DialogTitle>
                <Box sx={{ padding: '0 24px 24px' }}>

                    <Typography
                        variant='subtitle2'
                        gutterBottom
                        sx={{ color: 'black', fontFamily: 'Karla', fontWeight: 250 }}
                    >
                        {`Date Posted: ${datePosted}`}
                    </Typography>
                    <Box
                        component="img"
                        src={photo}
                        alt={title}
                        sx={{
                            width: "100%",
                            height: "350px",
                            objectFit: "cover",
                        }}
                    />


                    <Typography
                        variant='body2'

                        sx={{ color: 'black', fontFamily: 'Karla', fontWeight: 250, fontSize: "18px", marginTop: '18px', marginBottom: '12px' }}

                    >
                        {content}
                    </Typography>
                </Box>
                <Button
                    size="small"
                    onClick={handleReadMore}
                    sx={{
                        color: '#9291F0',
                        fontFamily: 'Lato',
                        padding: '0',
                        minWidth: 'auto',
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',

                    }}
                >
                    Close
                </Button>
                <Button
                    size="small"
                    onClick={handleReadMore}
                    sx={{
                        color: 'red',
                        fontFamily: 'Lato',
                        padding: '0',
                        minWidth: 'auto',
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        fontSize: '15px'

                    }}
                >
                    X
                </Button>

            </Dialog>
        </>
    );

};
export default PostCard;