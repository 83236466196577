import { MedicalInformation } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { FirestoreDB } from "../../utils/firebase/firebase";

import "./PatientOverview.css";
import { DataContext } from "../../contexts/DataProvider";

const PatientDiagnosis = ({ patient }) => {
  const [currentPatient, setCurrentPatient] = useState(() => ({
    ...patient,
    diagnosis: Array.isArray(patient.diagnosis)
      ? patient.diagnosis.join(", ")
      : patient.diagnosis || "",
  }));
  const [editOpen, setEditOpen] = useState(false);
  const { currentPatientData, fetchOnePatientData } = useContext(DataContext);

  const UpdateDoctorChanges = async () => {
    const patientRef = doc(FirestoreDB, "patientsList", patient.id);
    await updateDoc(patientRef, {
      diagnosis: currentPatient.diagnosis.split(",").map((x) => x.trim()),
    });
    setEditOpen(false);
  };

  const ClearChanges = () => {
    setCurrentPatient({
      ...patient,
      diagnosis: Array.isArray(patient.diagnosis)
        ? patient.diagnosis.join(", ")
        : patient.diagnosis || "",
    });
    setEditOpen(false);
  };

  useEffect(() => {
    if (patient?.id !== currentPatientData?.id) {
      fetchOnePatientData(patient.id);
    }
    setCurrentPatient({
      ...patient,
      diagnosis: Array.isArray(patient.diagnosis)
        ? patient.diagnosis.join(", ")
        : patient.diagnosis || "",
    });
  }, [patient]);

  return (
    <Card
      className="fullWidth fullHeight flexBox flexColumn patientDiagnosis"
      sx={{ justifyContent: "space-between", boxShadow: "none" }}
    >
      <CardContent>
        {currentPatientData?.data.length > 0 ? (
          <List>
            {/* {currentPatient.diagnosis.split(",").map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <MedicalInformation
                    sx={{ color: "var(--clr-theme-gray-dark)" }}
                  />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))} */}
            {currentPatientData.data.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <MedicalInformation
                    sx={{ color: "var(--clr-theme-gray-dark)" }}
                  />
                </ListItemIcon>
                <ListItemText primary={item?.treatment_name} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box className="fullHeight flexBox flexCenter">
            <Typography
              gutterBottom
              sx={{
                fontFamily: "Lato",
                fontSize: "1.1rem",
              }}
            >
              No Diagnosis
            </Typography>
          </Box>

          // <TextField
          //   fullWidth
          //   value={currentPatient.diagnosis}
          //   label="Diagnosis"
          //   onChange={(e) => {
          //     setCurrentPatient({
          //       ...currentPatient,
          //       diagnosis: e.target.value,
          //     });
          //   }}
          //   helperText="Please Use (,) to Differentiate Diagnosis"
          // />
        )}
      </CardContent>
      {/* <Box>
        {!editOpen ? (
          <CardActions className="flexBox flexCenter">
            <Button
              color="primary"
              variant="contained"
              sx={{ backgroundColor: "var(--clr-theme-purple) !important" }}
              onClick={() => {
                setEditOpen(true);
              }}
            >
              Edit Diagnosis
            </Button>
          </CardActions>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "48%",
                textTransform: "capitalize",
                fontFamily: "Lato",
              }}
              onClick={ClearChanges}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                backgroundColor: "var(--clr-theme-purple) !important",
                width: "48%",
                fontFamily: "Lato",
                textTransform: "capitalize",
              }}
              onClick={UpdateDoctorChanges}
            >
              Save
            </Button>
          </Box>
        )}
      </Box> */}
    </Card>
  );
};

export default PatientDiagnosis;
