import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import React from "react";
import { FirestoreDB } from "../../utils/firebase/firebase";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import AuthContext from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext, useState, useRef } from "react";
import { UIContext } from "../../contexts/UIProvider";
import VideoUpload from "../VideoUpload";
import { styled } from "@mui/material/styles";
import { DataContext } from "../../contexts/DataProvider";
import PlayVideo from "../PlayVideo";
import { fetchVideoDetails } from "../../api";

const Notification = ({
  notifications = [],
  setNotifications,
  fetchNotifications,
}) => {
  const {
    user,
    setOpenRightPanel,
    typeOfUser,
    openVideoUpload,
    setOpenVideoUpload,
  } = useContext(AuthContext);
  const { selectPatient, bottomDrawer, setBottomDrawer } =
    useContext(UIContext);
  // const { bottomDrawer, setBottomDrawer } = useContext(UIContext);
  const { patientData: patients } = useContext(DataContext);
  const [notificationsList, setNotificationsList] = useState(notifications);
  const [videoList, setVideoList] = useState([]);
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [user]);

  const convertTimeToDays = (firebaseTimestamp) => {
    const timestamp = new Date(
      firebaseTimestamp?._seconds * 1000 +
      firebaseTimestamp?._nanoseconds / 1000000 ||
      firebaseTimestamp?.seconds * 1000 +
      firebaseTimestamp?.nanoseconds / 1000000
    );
    const currentDate = new Date();

    const timeDifference = currentDate - timestamp;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysAgo;
  };

  const goToPatient = async (patientId) => {
    const patientData = patients.filter((each) => {
      return each.patientId === patientId;
    });

    console.log(patientData, "dtata");
    if (patientData) {
      setOpenRightPanel(false);
      selectPatient(patientId);
      navigate(`/patient/${patientId}`, {
        state: {
          patientData: {
            ...patientData[0]?.patientInfo,
            id: patientData[0]?.patientId,
          },
        },
      });
    }
  };

   useEffect(() => {
    let tempNotifications = []
    if(typeOfUser === "DOCTOR"){
      if (notificationsList && notificationsList.length > 0) {
        tempNotifications = [...notificationsList]
          .filter(
            (notification) =>
              (notification.notificationSource === "newPatientReg" ||
                notification.notificationSource === "patientVideoUploaded") && notification?.readStatus === false
          ).sort((a, b) => {
            const dateA = new Date(
              a.createdDate?._seconds * 1000 + a.createdDate?._nanoseconds / 1000000 ||
              a.createdDate?.seconds * 1000 + a.createdDate?.nanoseconds / 1000000
            );
            const dateB = new Date(
              b.createdDate?._seconds * 1000 + b.createdDate?._nanoseconds / 1000000 ||
              b.createdDate?.seconds * 1000 + b.createdDate?.nanoseconds / 1000000
            );
            return dateB - dateA;
          });
      }
    } else {
      if (notificationsList && notificationsList.length > 0) {
        tempNotifications = [...notificationsList]
          .filter(
            (notification) =>
              (notification.notificationSource === "doctorVideoUploaded") && notification?.readStatus === false
          ).sort((a, b) => {
            const dateA = new Date(
              a.createdDate?._seconds * 1000 + a.createdDate?._nanoseconds / 1000000 ||
              a.createdDate?.seconds * 1000 + a.createdDate?.nanoseconds / 1000000
            );
            const dateB = new Date(
              b.createdDate?._seconds * 1000 + b.createdDate?._nanoseconds / 1000000 ||
              b.createdDate?.seconds * 1000 + b.createdDate?.nanoseconds / 1000000
            );
            return dateB - dateA;
          });
      }
    }
   
    setNotificationsList(tempNotifications);
  }, [notifications,typeOfUser]);

  const markNotificationAsRead = async (notificationId) => {
    console.log("notificationId", notificationId);
    const notificationIndex = notifications.findIndex(
      (notification) => notification.id === notificationId
    );

    if (notificationIndex !== -1) {
      console.log("notifications index", notificationIndex !== -1);
      try {
        const notificationRef = doc(
          collection(FirestoreDB, "notifications", user?.uid, "messages"),
          notificationId
        );

        // Update the readStatus field in Firebase
        await updateDoc(notificationRef, {
          readStatus: true,
        });
        await fetchNotifications();
        // // Update the local state with the modified notification
        // const updatedNotifications = [...notifications];
        // updatedNotifications[notificationIndex] = {
        //   ...updatedNotifications[notificationIndex],
        //   readStatus: true,
        // };
        // setNotifications(updatedNotifications);
      } catch (error) {
        console.error("Error updating readStatus in Firebase:", error);
      }
    }
  };

  const getVideoDetails = async (uid, videoId) => {
    // setIsLoading(true);
    const result = await fetchVideoDetails(uid, videoId);
    return result;
  };

  const handleVideoOpen = async (notificationDetails) => {
    const uid =
      notificationDetails?.notificationSource === "patientVideoUploaded"
        ? notificationDetails?.userId
        : user?.uid;
    const videoId = notificationDetails?.videoID;
    console.log(uid, videoId, "uid, videoId");
    const videoDetails = await getVideoDetails(uid, videoId);
    const doctor = user?.doctorDetails?.find(
      (x) => x.email === notificationDetails?.sentBy
    );
    const patient = patients?.find((x) => x.patientId === uid);

    setBottomDrawer({
      ...bottomDrawer,
      show: true,
      title: videoDetails?.rawVideoData?.fileName,
      patientName: patient?.patientInfo?.fullname || doctor?.fullname,
      patientPhoto: patient?.patientInfo?.photoURL || doctor?.photoURL,
      content: (
        <PlayVideo
          videoList={videoList?.map((video) => {
            if (video.user) {
              return video;
            } else {
              return {
                ...video,
                user: user,
              };
            }
          })}
          // .flatMap((x) => x.videos)}
          handleVideoOpen={handleVideoOpen}
          typeOfUser={typeOfUser}
          videoDetails={videoDetails}
          patientID={uid}
        />
      ),
    });
  };

  console.log("user------------------", user);

  const navigateToChatScreen = async (notificationDetails) => {
    const doctor = user?.doctorDetails?.find(
      (x) => x.email === notificationDetails?.sentBy
    );
    console.log("doctor----------------", doctor);
    const patient = patients?.find(
      (x) => x.patientId === notificationDetails?.userId
    );
    if (notificationDetails.notificationSource === "patientMessage") {
      navigate("/message", {
        state: { patientId: patient?.patientId },
      });
    } else if (notificationDetails.notificationSource === "doctorMessage") {
      navigate("/message", {
        state: { doctorId: doctor?.doctorId },
      });
    }
  };

  const openDescriptionModel = () => {
    setOpenRightPanel(false);
    setOpenVideoUpload(true);
  };

  const colors = {
    "newPatientReg": "#e7fffe",
    "patientVideoUploaded": "#F0F8FF",
    "doctorMessage": "#e6e6fabf",
    "patientMessage": "#e6e6fabf",
    "default": "#f5f5f5"
  }

  return (
    <Box className="fullWidth" p={1}>
      <Typography
        variant="h4"
        sx={{ fontFamily: "Lato", color: "var(--clr-theme-purple)" }}
      >
        Notifications
      </Typography>
      {notificationsList.length ? (
        notificationsList.map((each, index) => {
          if (!each.readStatus && each.title) {
            let backgroundColor = colors[each?.notificationSource] || "#f5f5f5";

            return (
              <Grid
                key={index}
                container
                spacing={2}
                className="candidate-list"
              >
                <Grid item xs={12}>
                  {each?.title !== "Patient has sent you a message" &&
                    each?.title !==
                    "Healthcare Professional has sent you a message" && (
                      <div
                        className="candidate-list-box card mt-4"
                        readStatus={each.readStatus}
                        style={{
                          backgroundColor,
                        }}
                      >
                        <div className="p-4 card-body">
                          <Grid container alignItems="center" spacing={2}>
                            {!each.readStatus && (
                              <div
                                style={{
                                  width: 10,
                                  height: 10,
                                  backgroundColor: "blue",
                                  borderRadius: 8,
                                  marginRight: 8,
                                }}
                              />
                            )}
                            <Grid item>
                              <div className="candidate-list-images">
                                <Avatar
                                  sx={{ height: 60, width: 60 }}
                                  src={
                                    each?.userPhotoURL ||
                                    "https://t4.ftcdn.net/jpg/03/59/58/91/360_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg"
                                  }
                                  alt=""
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <div className="candidate-list-content mt-3 mt-lg-0">
                                <Typography
                                  variant="h5"
                                  className="fs-19 mb-0"
                                  sx={{
                                    fontFamily: "Lato",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {each?.userName}
                                </Typography>

                                <ul className="list-inline mb-0 text-muted">
                                  <li className="list-inline-item">
                                    {each.title}
                                  </li>
                                  <li className="list-inline-item">
                                    {each.notificationSource ===
                                      "newPatientReg" && (
                                        <>
                                          <span> view profile of </span>
                                          <span
                                            style={{
                                              color: "blue",
                                              textDecoration: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              goToPatient(each.userId);
                                              markNotificationAsRead(each.id);
                                            }}
                                          >
                                            {each.userName
                                              ?.split(/\s+/)
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1).toLowerCase()
                                              )
                                              .join(" ")}
                                          </span>
                                        </>
                                      )}
                                  </li>

                                  <li className="list-inline-item">
                                    {(each?.notificationSource ===
                                      "patientVideoUploaded" ||
                                      each?.notificationSource ===
                                      "doctorVideoUploaded") && (
                                        <>
                                          <span
                                            style={{
                                              color: "blue",
                                              textDecoration: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              handleVideoOpen(each);
                                              setOpenRightPanel(false);
                                              markNotificationAsRead(each.id);
                                            }}
                                          >
                                            view video
                                          </span>
                                        </>
                                      )}
                                  </li>
                                  <li>
                                    {(each?.notificationSource ===
                                      "patientMessage" ||
                                      each?.notificationSource ===
                                      "doctorMessage") && (
                                        <>
                                          <span
                                            style={{
                                              fontWeight: "800",
                                            }}
                                          >
                                            {" "}
                                            {""}
                                            {each?.description}
                                          </span>
                                          <br />
                                          <span>
                                            {" "}
                                            From {each.userName} &nbsp;
                                          </span>
                                          <span
                                            style={{
                                              color: "blue",
                                              textDecoration: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              navigateToChatScreen(each);
                                              openDescriptionModel();
                                              markNotificationAsRead(each.id);
                                            }}
                                          >
                                            View
                                          </span>
                                        </>
                                      )}
                                  </li>
                                  <li>
                                    {each.notificationSource ===
                                      "uploadVideo" && (
                                        <>
                                          <span>
                                            {" "}
                                            {""}
                                            {each?.description}
                                          </span>
                                          <br />
                                          <span>
                                            {" "}
                                            From {each.userName} &nbsp;
                                          </span>
                                          <span
                                            style={{
                                              color: "blue",
                                              textDecoration: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              goToPatient(each.userId);
                                              markNotificationAsRead(each.id);
                                            }}
                                          >
                                            Please review
                                          </span>
                                        </>
                                      )}
                                  </li>
                                </ul>
                              </div>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <div>
                                <Chip
                                  label={`${convertTimeToDays(
                                    each.createdDate
                                  )} Days ago`}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                </Grid>
              </Grid>
            );
          } else {
            return null;
          }
        })
      ) : (
        <Box className="fullHeight flexBox flexColumn flexCenter">
          <Typography variant="h6" sx={{ fontFamily: "Lato" }}>
            No new notifications found
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Notification;
