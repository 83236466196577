import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Fab,
} from "@mui/material";

import UpdateTreatments from "../../UpdateTreatments";
import Treatment from "./Treatment";
import Behaviour from "./Behaviour";
import Goals from "./Goals";
import { FirestoreDB } from "../../../utils/firebase/firebase";
import { UIContext } from "../../../contexts/UIProvider";
import AuthContext from "../../../contexts/AuthContext";

import CustomButton from "../../CustomButton";

import "./VideoFeedback.css";

import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  BEHAVIOUR_TEMPLATE,
  TREATMENT_TEMPLATE,
  GOALS_TEMPLATE,
} from "../../../constants/AppConstants";
import { Add } from "@mui/icons-material";
import {
  doc,
  collection,
  setDoc,
  onSnapshot,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import { DataContext } from "../../../contexts/DataProvider";
import Progress from "../../Progress";
import SymptomRating from "./Rating";

const VideoFeedback = ({ patientID, videoDetails }) => {
  const { setOpenRightPanel, setPanelContent, user, typeOfUser } =
    useContext(AuthContext);
  const { currentPatientData, fetchOnePatientData, isLoading } =
    useContext(DataContext);
  const [treatments, setTreatments] = useState([]);
  const [behaviours, setBehaviours] = useState([]);
  const [behavioursName, setBehavioursName] = useState([]);
  const [goals, setGoals] = useState([]);
  const [notes, setNotes] = useState("");
  const [Private, setPrivate] = useState(true);
  const [symptoms, setSymptoms] = useState([]);
  const [medAndTherapies, setMedTherapies] = useState({
    treatment_name: [],
    meds: [],
    therapies: [],
  });
  const { bottomDrawer, setBottomDrawer } = useContext(UIContext);
  const [selectedBehaviorCount, setSelectedBehaviorCount] = useState(0);

  useEffect(() => {
    if (patientID !== currentPatientData?.id) {
      fetchOnePatientData(patientID);
    }
  }, [patientID]);

  const UpdateTreatmentHandler = () => {
    setPanelContent(<UpdateTreatments patientID={patientID} />);
    setOpenRightPanel(true);
  };

  const getSymptomps = async () => {
    const symptomsRef = collection(FirestoreDB, "symptoms");
    return onSnapshot(
      symptomsRef,
      (querySnapshot) => {
        const symptomIds = querySnapshot.docs.map((doc) => doc.id);
        setSymptoms(symptomIds);
      },
      (error) => {
        console.log("Error listening to symptoms:", error);
      }
    );
  };

  const getBehaviors = async () => {
    const behaviorRef = collection(FirestoreDB, "behaviour");
    return onSnapshot(
      behaviorRef,
      (querySnapshot) => {
        const behaviorIds = querySnapshot.docs.map((doc) => doc.id);
        setBehavioursName(behaviorIds);
      },
      (error) => {
        console.log("Error listening to symptoms:", error);
      }
    );
  };

  useEffect(() => {
    const medsAndTherapies = transformPatientData(currentPatientData?.data);
    setMedTherapies(medsAndTherapies);
  }, [currentPatientData]);

  function transformPatientData(patientData) {
    const result = {
      meds: [],
      therapies: [],
      treatment_name: [],
    };

    patientData?.forEach((patient) => {
      if (patient.meds && patient.meds.length > 0) {
        patient.meds.forEach((med) => {
          result.meds.push({
            tradeName: med.tradeName || "",
            dosage: med.dosage || 0,
            usage: med.usage || "",
            drug_name: med.drug_name || "",
            range: med.range || "",
            treats: med.treats || "",
            sideEffects: med.sideEffects || "",
          });
        });
      }

      if (patient.therapies && patient.therapies.length > 0) {
        patient.therapies.forEach((therapy) => {
          result.therapies.push({
            costPerHours: therapy.costPerHours || "",
            therapy_name: therapy.therapy_name || "",
            sessionsPerWeek: therapy.sessionsPerWeek || "",
            hoursPerSession: therapy.hoursPerSession || "",
          });
        });
      }

      if (patient.treatment_name) {
        result.treatment_name.push(patient.treatment_name);
      }
    });

    return result;
  }

  const updateTreatments = (dataList, type, treatmentID) => {
    const myTreatments = [...treatments];
    if (type === "Therapy") {
      myTreatments.find((x) => x.id === treatmentID).therapies = dataList;
    } else if (type === "Meds") {
      myTreatments.find((x) => x.id === treatmentID).meds = dataList;
    } else if (type === "Symptom") {
      myTreatments.find((x) => x.id === treatmentID).symptom = dataList;
    } else if (type === "Diagnosis") {
      myTreatments.find((x) => x.id === treatmentID).diagnosis = dataList;
    } else if (type === "Behaviour") {
      myTreatments.find((x) => x.id === treatmentID).behaviour = dataList;
    } else {
      myTreatments.find((x) => x.id === treatmentID).rating = dataList;
    }

    setTreatments(myTreatments);
  };

  const updateBehaviours = (dataList, type, behaviourID) => {
    const myBehaviours = [...behaviours];
    console.log(dataList, "dataList");
    if (type === "Behaviour") {
      myBehaviours.find((x) => x.id === behaviourID).behaviour = dataList;
    } else if (type === "BehaviourRating") {
      myBehaviours.find((x) => x.id === behaviourID).behaviour_rating =
        dataList;
    } else if (type === "Antecedents") {
      myBehaviours.find((x) => x.id === behaviourID).antecedents = dataList;
    } else if (type === "Consequences") {
      myBehaviours.find((x) => x.id === behaviourID).consequences = dataList;
    }

    setBehaviours(myBehaviours);
  };

  const updateGoals = (dataList, type, behaviourID) => {
    const myGoals = [...goals];
    console.log(dataList, "dataList");
    if (type === "behaviour") {
      myGoals.find((x) => x.id === behaviourID).behaviour = dataList;
    } else if (type === "GoalsDescription") {
      myGoals.find((x) => x.id === behaviourID).GoalsDescription = dataList;
    } else if (type === "BaselineDescription") {
      myGoals.find((x) => x.id === behaviourID).BaselineDescription = dataList;
    }
    setGoals(myGoals);
  };

  console.log(behaviours, "updated behaviours");
  console.log(goals, "upadted goals");

  const handleAddTreatment = () => {
    setTreatments([...treatments, { ...TREATMENT_TEMPLATE, id: uuidv4() }]);
  };

  const handleAddBehaviour = () => {
    setBehaviours([...behaviours, { ...BEHAVIOUR_TEMPLATE, id: uuidv4() }]);
  };

  const deleteTreatment = (treatmentID) => {
    setTreatments([...treatments.filter((x) => x.id !== treatmentID)]);
  };

  const deleteBehaviour = (behaviourID) => {
    setBehaviours([...behaviours.filter((x) => x.id !== behaviourID)]);
  };

  useEffect(() => {
    setTreatments([{ ...TREATMENT_TEMPLATE, id: uuidv4() }]);
    setBehaviours([{ ...BEHAVIOUR_TEMPLATE, id: uuidv4() }]);
    setGoals([{ ...GOALS_TEMPLATE, id: uuidv4() }]);
    getSymptomps();
    getBehaviors();
  }, [patientID]);

  const addNewSymptomToSymptomsCollection = async (otherSymptom) => {
    const symptomsCollectionRef = collection(FirestoreDB, "symptoms");
    try {
      const symptomDocRef = doc(symptomsCollectionRef, otherSymptom);
      const symptomDocSnapshot = await getDoc(symptomDocRef);
      if (!symptomDocSnapshot.exists()) {
        await setDoc(symptomDocRef, {});
      } else {
      }
    } catch (error) {
      console.error(
        "Error adding new symptom to the 'symptoms' collection:",
        error
      );
    }
  };
  const addNewBehaviourToBehaviourCollection = async (otherBehaviour) => {
    const behavioursCollectionRef = collection(FirestoreDB, "behaviour");
    try {
      const behaviourDocRef = doc(behavioursCollectionRef, otherBehaviour);
      const behaviourDocSnapshot = await getDoc(behaviourDocRef);

      if (!behaviourDocSnapshot.exists()) {
        await setDoc(behaviourDocRef, {});
      } else {
      }
    } catch (error) {
      console.error(
        "Error adding new symptom to the 'symptoms' collection:",
        error
      );
    }
  };

  const UpdateTreatmentstoVideo = async () => {
    if (validatingFeedback()) {
      try {
        for (let i = 0; i < treatments.length; i++) {
          await addNewSymptomToSymptomsCollection(treatments[i].symptom); //if other symptom add to collection
          await addNewBehaviourToBehaviourCollection(behaviours[i].behaviour); //if other behaviour add to collection

          const result = await setDoc(
            doc(
              FirestoreDB,
              "videoLogs",
              patientID,
              "docNotes",
              videoDetails?.videoId
            ),
            {
              treatments: btoa(JSON.stringify(treatments)),
              behaviours: btoa(JSON.stringify(behaviours)),
              time: Timestamp.now(),
              notes: btoa(notes),
              Private: Private,
              userName: user?.fullname,
              userType: typeOfUser,
            }
          );
          setBottomDrawer({ ...bottomDrawer, show: false });
        }

        toast.success("Feedback updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (error) {
        console.log(error, "error while updating feedback", toast.POSITION);
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  const validatingFeedback = () => {
    for (let i = 0; i < treatments.length; i++) {
      if (
        treatments[i]?.meds.length === 0 &&
        treatments[i]?.therapies.length === 0
      ) {
        toast.error("Every treatment has atleast one med or therapy & rating", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
    }
    return true;
  };

  const frequencyHandler = (e) => {
    const enteredValue = e.target.value;
    const numericValue = parseInt(enteredValue);

    // Check if the entered value is a valid number
    if (!isNaN(numericValue)) {
      const updatedValue = Math.max(0, Math.min(numericValue, 50));
      setSelectedBehaviorCount(updatedValue);
      updateBehaviours(updatedValue, "BehaviourRating", behaviours.id);
    } else {
      // Handle the case where the entered value is not a valid number
      // For example, clear the input or show an error message
      setSelectedBehaviorCount(0);
    }
  };

  const incrementCount = () => {
    const incrementCount = selectedBehaviorCount + 1;
    const updatedCount = Math.min(incrementCount, 50);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(updatedCount, "BehaviourRating", behaviours.id);
  };

  const decrementCount = () => {
    const newCount = selectedBehaviorCount - 1;
    const updatedCount = Math.max(newCount, 0);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(updatedCount, "BehaviourRating", behaviours.id);
  };

  const RatingHandler = (value, type, treatmentID) => {
    updateTreatments(value, type, treatmentID);
  };

  //Behaviors
  const behaviorsdetails = videoDetails?.patientTreatmentInfo?.behaviors || [];
  const behaviorNames = behaviorsdetails
    .map((behavior) => behavior.behaviorName)
    .join(", ");
  const antecedentFormatted = behaviorsdetails
    .map((behavior) => behavior.antecedent)
    .join(", ");
  const consequenceFormatted = behaviorsdetails
    .map((behavior) => behavior.consequence)
    .join(", ");

  //Tratments
  const { meds = [], diagnosis = [], behaviors = [] } = videoDetails?.patientTreatmentInfo || {};

  const formattedMeds = Array.isArray(meds) ? meds.join(", ") : meds;
  const formattedDiagnosis = Array.isArray(diagnosis) ? diagnosis.join(", ") : diagnosis;  

  const behaviorNamesTreat = behaviors
    .map((behavior) => behavior.behaviorName)
    .join(", ");

  return (
    <Box sx={{ p: 1 }} className="videoFeedbackPaper">
      <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
        
        <Card
          className="treatmentBox"
          sx={{ border: "1px solid lightgray", p: 1 }}
        >
          <Typography variant="h6">Treatment Information</Typography>

          <Box
            sx={{
              backgroundColor: "#E5E5FA",
              borderRadius: "20px",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "8px" }}>
              Diagnosis
            </Typography>
            <Typography variant="body1" sx={{ color: "#333" }}>
              {formattedDiagnosis}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#E5E5FA",
              borderRadius: "20px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "8px" }}>
              Meds
            </Typography>
            <Typography variant="body1" sx={{ color: "#333" }}>
              {formattedMeds}
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: "#E5E5FA",
              borderRadius: "20px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "8px" }}>
              Intervention
            </Typography>
            <Typography variant="body1" sx={{ color: "#333" }}>
              {consequenceFormatted}
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: "#E5E5FA",
              borderRadius: "20px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "8px" }}>
              Behaviors
            </Typography>
            <Typography variant="body1" sx={{ color: "#333" }}>
              {" "}
              {behaviorNamesTreat}
            </Typography>
          </Box>
        </Card>

        <Card
          className="treatmentBox"
          sx={{ border: "1px solid lightgray", p: 1 }}
        >
          <Box
            className="flexBox flexColumn fullHeight fullWidth"
            sx={{ gap: 2, justifyContent: "center" }}
          >
            <CardHeader subheader={"Behavior Information"} />

            {behaviorsdetails.length > 0 ? (
              <>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  <Typography sx={{ fontWeight: "bold", marginBottom: "8px" }}>
                    Behavior
                  </Typography>
                  {behaviorNames}
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "8px",
                      marginTop: "10px",
                    }}
                  >
                    Severity Rating
                  </Typography>
                  <SymptomRating
                    treatmentID={treatments.id}
                    RatingHandler={RatingHandler}
                  />
                  <Box
                    className="flexBox flexCenter"
                    sx={{ justifyContent: "flex-start", mt: 2 }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {/* Selected Behavior:{" "}
    {isCustomBehaviour ? "Custom Behavior" : behaviour?.behaviour} */}
                      Frequency count
                    </Typography>
                    <Box className="flexBox flexCenter" sx={{ gap: 4, ml: 10 }}>
                      <Fab
                        onClick={decrementCount}
                        size="small"
                        sx={{
                          backgroundColor: "var(--clr-theme-purple)!important",
                          fontSize: "2rem",
                          color: "var(--clr-theme-white)",
                        }}
                      >
                        -
                      </Fab>
                      {/* <Typography>{selectedBehaviorCount}</Typography> */}
                      <TextField
                        type="tel"
                        inputProps={{
                          pattern: "[0-50]*", // Allows only numeric input
                          inputMode: "numeric", // Specifies a numeric keyboard
                        }}
                        sx={{ width: "55px" }}
                        value={selectedBehaviorCount}
                        onChange={frequencyHandler}
                      />

                      <Fab
                        onClick={incrementCount}
                        size="small"
                        sx={{
                          backgroundColor: "var(--clr-theme-purple) !important",
                          fontSize: "1.5rem",
                          color: "var(--clr-theme-white)",
                        }}
                      >
                        +
                      </Fab>
                    </Box>
                  </Box>
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>ANTECEDENT</Typography>
                {antecedentFormatted}
                <Typography sx={{ fontWeight: "bold" }}>
                  {" "}
                  CONSEQUENCE
                </Typography>
                {consequenceFormatted}
              </>
            ) : (
              <Typography variant="body1" sx={{ color: "#777" }}>
                No behaviors available.
              </Typography>
            )}
          </Box>
        </Card>

        {typeOfUser === "DOCTOR" && (
          <>
            {treatments?.map((treatment, i) => {
              return (
                <Treatment
                  key={i}
                  index={i}
                  treatment={treatment}
                  updateTreatments={updateTreatments}
                  symptoms={symptoms}
                  deleteTreatment={deleteTreatment}
                  medAndTherapies={medAndTherapies}
                  isLoading={isLoading}
                  behaviours={behaviours}
                  videoDetails={videoDetails}
                />
              );
            })}
            {console.log(behaviours, "behaviours")}

            {behaviours?.map((behaviour, i) => {
              return (
                <Behaviour
                  key={i}
                  index={i}
                  behaviour={behaviour}
                  updateBehaviours={updateBehaviours}
                  symptoms={symptoms}
                  deleteBehaviour={deleteBehaviour}
                  medAndTherapies={medAndTherapies}
                  isLoading={isLoading}
                  behavioursName={behavioursName}
                  videoDetails={videoDetails}
                />
              );
            })}

            {/* {goals?.map((behaviour, i) => {
          return (
            <Goals
              key={i}
              index={i}
              behaviour={behaviour}
              updateGoals={updateGoals}
              // symptoms={symptoms}
              // deleteBehaviour={deleteBehaviour}
              // medAndTherapies={medAndTherapies}
              // isLoading={isLoading}
              behavioursName={behavioursName}
            />
          );
        })} */}
            <TextField
              label="Video feedback notes"
              multiline
              rows={12}
              value={notes}
              placeholder="Notes for patient"
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Private}
                  onChange={(e) => {
                    setPrivate(e.target.checked);
                  }}
                />
              }
              label="Private"
            />
            <Box
              className="flexBox flexWrap"
              sx={{
                justifyContent: "space-between",
                minWidth: "250px",
                gap: 1,
              }}
            >
              <Box sx={{ width: "45%" }}>
                <CustomButton
                  ChangeHandler={handleAddTreatment}
                  variant="extended"
                  color="info"
                  size={"large"}
                >
                  <Add />
                  Add new treatment
                </CustomButton>
              </Box>
              <Box sx={{ width: "45%" }}>
                <CustomButton
                  color="secondary"
                  variant="extended"
                  ChangeHandler={handleAddBehaviour}
                >
                  <Add /> Add new behaviour
                </CustomButton>
              </Box>
            </Box>

            <ToastContainer autoClose={true} />
            <CustomButton
              ChangeHandler={UpdateTreatmentHandler}
              variant="extended"
              color="success"
            >
              Update treatment process
            </CustomButton>
            <CustomButton
              ChangeHandler={UpdateTreatmentstoVideo}
              variant="extended"
              color="info"
              size={"large"}
            >
              Submit
            </CustomButton>
          </>
        )}
      </div>
    </Box>
  );
};
export default VideoFeedback;