import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const index = () => {
  return (
    <Box>
     <Typography>Transation History </Typography> 
      </Box>
  );
};

export default index;





      


          