import React, { useContext, useEffect, useRef, useState } from "react";
import MainLayout from "../../hoc/MainLayout";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  InputBase,
  IconButton,
  Paper,
  Avatar,
  Divider,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  ListItem,
  ListItemAvatar,
  CircularProgress,
  CardHeader,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import {
  Search,
  Paperclip,
  EmojiEmotions,
  Send,
  Close,
  West,
  Groups,
  Chat,
  Groups2,
} from "@mui/icons-material";
import "./Messaging.css"; // Import the external CSS file
import AuthContext from "../../contexts/AuthContext";
import { DataContext } from "../../contexts/DataProvider";
import { UIContext } from "../../contexts/UIProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchDoctorAndPatientMessages } from "../../api";
import { toast } from "react-toastify";
import { FirestoreDB } from "../../utils/firebase/firebase";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import UnreadMessageCounts from "./UnreadCount";
import { orderBy } from "lodash";
// import styled from "styled-components";
import Reminders from "../../components/Reminders";
import CustomButton from "../../components/CustomButton";
import { stableSort } from "../../utils/helper";
import Conversations from "./Conversation";
import Progress from "../../components/Progress";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: "bold",
    padding: "10px",
  },
}));

const Message = () => {
  const [messages, setMessages] = useState([]);
  const { user, typeOfUser } = useContext(AuthContext);
  const { patientData, fetchPatientsForDoctor } = useContext(DataContext);
  const { selectedPatientId, selectPatient, setIsNewMessage } =
    useContext(UIContext);
  const [currentPatient, setCurrentPatient] = useState(null);

  const [selectedChat, setSelectedChat] = useState(null);

  const [currentDoctor, setCurrentDoctor] = useState(null);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [unreadCounts, setUnreadCounts] = useState([]);
  const [unreadCountsForGroup, setUnreadCountsForGroup] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [localMessage, setLocalMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const scroll = useRef(null);

  const locationPatientId = location?.state?.patientId;
  const locationDoctorId = location?.state?.doctorId;

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchGroups = () => {
    if (!user?.uid) {
      // If user?.uid is undefined, show loading message and return a no-op function
      setLoading(true);
      return () => {};
    }

    const groupCollection = collection(FirestoreDB, "groupChats");
    const q = query(
      groupCollection,
      where("members", "array-contains", user?.uid)
    );

    const unsubscribe = onSnapshot(q, (groupSnapshot) => {
      const groupList = groupSnapshot.docs.map((doc) => ({
        groupId: doc.id,
        ...doc.data(),
      }));
      setGroups(groupList);
      setLoading(false);
    });

    return unsubscribe; // Return the unsubscribe function
  };

  useEffect(() => {
    const unsubscribe = fetchGroups();
    return () => unsubscribe(); // Clean up the listener when the component unmounts
  }, [user?.uid]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setErrorMessage("");
    resetStates();
  };

  const handlePatientCheckboxChange = (member) => {
    // member = { id, name, photoURL, typeOfUser: "DOCTOR" || "PATIENT" };
    console.log(" memeber : ", member);
    const isChecked = selectedPatients.find((item) => item?.id === member?.id);
    if (isChecked) {
      setSelectedPatients(
        selectedPatients.filter((item) => item?.id !== member?.id)
      );
    } else {
      setSelectedPatients([...selectedPatients, member]);
    }
    setErrorMessage("");
  };

  const createGroup = async (creator = "", members = []) => {
    try {
      const groupRef = collection(FirestoreDB, "groupChats");
      const res = await addDoc(groupRef, {
        createdBy: creator?.id,
        members: [...members.map((member) => member.id), creator?.id],
        groupName: groupName,
        createdAt: new Date(),
      });
      const batch = writeBatch(FirestoreDB);
      [...members, creator].forEach((member) => {
        const memberRef = doc(
          FirestoreDB,
          "groupChats",
          res.id,
          "members",
          member.id
        );
        batch.set(memberRef, member);
      });
      await batch.commit();
      toast.success("Group created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log("Group created successfully!");
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const handleCreateGroup = () => {
    if (!groupName) {
      setErrorMessage("Group name is required");
      return;
    }
    if (selectedPatients.length === 0) {
      setErrorMessage("At least one patient must be selected");
      return;
    }
    createGroup(
      {
        id: user.uid,
        name: user?.fullname,
        parentName: user?.parentName || "",
        photoURL: user?.photoURL,
        typeOfUser,
      },
      selectedPatients
    );
    handleDialogClose();
    resetStates();
  };

  const resetStates = () => {
    setSelectedPatients([]);
    setGroupName("");
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
    if (errorMessage && e.target.value) {
      setErrorMessage(""); // Clear the error message when a valid group name is entered
    }
  };

  useEffect(() => {
    user?.registrationID &&
      patientData.length === 0 &&
      fetchPatientsForDoctor(user?.uid);
  }, [user]);

  const changeReadStatusToDB = async (uid) => {
    const messageRef = collection(
      FirestoreDB,
      "notifications",
      user?.uid,
      "messages"
    );

    const querySnapshot = await getDocs(
      query(
        messageRef,
        orderBy("createdDate", "desc"),
        where("userId", "==", uid),
        limit(20)
      )
    );

    const batch = writeBatch(FirestoreDB);
    querySnapshot.forEach((doc) => {
      batch.update(doc.ref, { readStatus: true });
    });
    await batch.commit();
  };

  const showPatientConversation = async (patient) => {
    setCurrentPatient(patient);
    setSelectedChat(patient);
    await changeReadStatusToDB(patient?.patientId);
    setUnreadCounts((prevCounts) => [
      ...prevCounts.filter((count) => count.patientId !== patient?.patientId),
      { patientId: patient.patientId, count: 0 },
    ]);
  };

  const showDoctorConversation = async (doctor) => {
    setCurrentDoctor(doctor);
    setSelectedChat(doctor);
    await changeReadStatusToDB(doctor?.doctorId);
    setUnreadCounts((prevCounts) => [
      ...prevCounts.filter((count) => count.doctorId !== doctor?.doctorId),
      { doctorId: doctor.doctorId, count: 0 },
    ]);
  };

  const showGroupConversation = async (group) => {
    setSelectedChat(group);
    await changeReadStatusForGroup(group.groupId);
    console.log("changeReadStatusForGroup", group.groupId);
    setUnreadCountsForGroup((prevCounts) =>
      prevCounts.map((countObj) =>
        countObj.groupId === group.groupId
          ? { ...countObj, count: 0 }
          : countObj
      )
    );
    setIsNewMessage((prevCounts) => {
      // Ensure prevCounts is defined and an array
      if (!prevCounts || !Array.isArray(prevCounts)) {
        return false; // No new messages if prevCounts is undefined or not an array
      }
      // Update counts for the specific group to 0
      const updatedCounts = prevCounts.map((countObj) =>
        countObj.groupId === group.groupId
          ? { ...countObj, count: 0 }
          : countObj
      );
      // Check if any groups still have unread messages
      return updatedCounts.some((countObj) => countObj.count > 0);
    });
  };

  const goToPatient = (id) => {
    selectPatient(id);

    navigate(`/patient/${id}`, {
      state: {
        patientData: {
          ...patientData?.find((patient) => patient?.patientId === id)
            ?.patientInfo,
          id,
        },
      },
    });
  };

  console.log("groups ::", groups);

  // const fetchGroupUnreadCounts = async () => {
  //   try {
  //     if (groups) {
  //       const counts = [];
  //       for (const group of groups) {
  //         const readStatusQuery = { id: user?.uid, status: false };
  //         const snapshot = await getDocs(
  //           query(
  //             collection(
  //               FirestoreDB,
  //               "groupChats",
  //               group?.groupId,
  //               "groupMessages"
  //             ),
  //             where("readStatus", "array-contains", readStatusQuery),
  //             where("notificationSource", "==", "groupMessage")
  //           )
  //         );

  //         const count = snapshot.size;

  //         if (count > 0) {
  //           counts.push({ groupId: group?.groupId, count });
  //         }
  //       }

  //       const hasNewMessages = counts.some((item) => item.count > 0);
  //       setIsNewMessage(hasNewMessages);
  //       setUnreadCountsForGroup(counts);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching unread message counts:", error);
  //   }
  // };
  // console.log("setUnreadCountsForGroup ::", unreadCountsForGroup);

  // useEffect(() => {
  //   fetchGroupUnreadCounts();
  // }, [groups]);

  useEffect(() => {
    const unsubscribes = [];

    const fetchGroupUnreadCounts = () => {
      try {
        if (groups) {
          const counts = [];
          for (const group of groups) {
            const readStatusQuery = { id: user?.uid, status: false };

            const unsubscribe = onSnapshot(
              query(
                collection(
                  FirestoreDB,
                  "groupChats",
                  group?.groupId,
                  "groupMessages"
                ),
                where("readStatus", "array-contains", readStatusQuery),
                where("notificationSource", "==", "groupMessage")
              ),
              (snapshot) => {
                const count = snapshot.size;

                const groupIndex = counts.findIndex(
                  (item) => item.groupId === group?.groupId
                );
                if (groupIndex >= 0) {
                  // Update existing group's count
                  counts[groupIndex].count = count;
                } else {
                  // Add new group's count
                  counts.push({ groupId: group?.groupId, count });
                }

                const hasNewMessages = counts.some((item) => item.count > 0);
                setIsNewMessage(hasNewMessages);
                setUnreadCountsForGroup(counts);
              }
            );

            unsubscribes.push(unsubscribe);
          }
        }
      } catch (error) {
        console.error("Error fetching unread message counts:", error);
      }
    };

    fetchGroupUnreadCounts();

    // Cleanup subscriptions on component unmount
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [groups]);

  console.log("UnreadCountsForGroup ::", unreadCountsForGroup);

  const changeReadStatusForGroup = async (groupId) => {
    try {
      const snapshot = await getDocs(
        query(
          collection(FirestoreDB, "groupChats", groupId, "groupMessages"),
          where("readStatus", "array-contains", {
            id: user?.uid,
            status: false,
          }),
          where("notificationSource", "==", "groupMessage")
        )
      );

      const batch = writeBatch(FirestoreDB);
      snapshot.forEach((doc) => {
        const messageData = doc.data();
        const updatedReadStatus = messageData.readStatus.map((statusObj) => {
          if (statusObj.id === user?.uid) {
            return { ...statusObj, status: true };
          }
          return statusObj;
        });
        batch.update(doc.ref, { readStatus: updatedReadStatus });
      });

      await batch.commit();
      console.log("Messages marked as read");
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  const fetchMessages = async (showLoader = true) => {
    if (showLoader) {
      setLoading(true); // Set loading to true before fetching data
    }
    try {
      let fetchedMessages;
      if (typeOfUser === "DOCTOR") {
        fetchedMessages = await fetchDoctorAndPatientMessages(
          user?.uid,
          user?.uid,
          currentPatient?.patientId
        );
      } else if (typeOfUser === "PATIENT") {
        fetchedMessages = await fetchDoctorAndPatientMessages(
          user?.uid,
          currentDoctor?.doctorId,
          user?.uid
        );
      }
      if (fetchedMessages) {
        // Optimize sorting by using the stableSort function
        const sortedMessages = stableSort(fetchedMessages, (a, b) => {
          const dateA = b.createdDate?._seconds || b.createdDate?.seconds;
          const dateB = a.createdDate?._seconds || a.createdDate?.seconds;
          return dateB - dateA;
        });
        setMessages(sortedMessages);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoading(false); // Set loading to false after data fetching (whether successful or not)
    }
  };

  useEffect(() => {
    if (currentPatient || currentDoctor) {
      // user && fetchMessages();
    }

    if (typeOfUser === "DOCTOR" && patientData.length > 0 && !currentPatient) {
      const patientInfo = locationPatientId
        ? patientData.find(
            (patient) => patient?.patientId === locationPatientId
          )
        : patientData[0];
      setSelectedChat(patientInfo);
      showPatientConversation(patientInfo); // Choose the first patient by default
    }
    if (typeOfUser === "PATIENT" && !currentDoctor) {
      const doctorInfo = locationDoctorId
        ? user?.doctorDetails.find(
            (doctor) => doctor?.doctorId === locationDoctorId
          )
        : user.doctorDetails[0];
      setSelectedChat(doctorInfo); // Choose the first doctor by default

      showDoctorConversation(doctorInfo);
    }
  }, [
    user,
    currentPatient,
    patientData,
    currentDoctor,
    locationPatientId,
    locationDoctorId,
    typeOfUser,
  ]);

  const SaveNoteHandler = async (e) => {
    e.preventDefault();
    if (!input.trim()) {
      setInputError("Message cannot be empty");
      return;
    }
    // setLocalMessage({
    //   description: input,
    //   sentBy: user.email,
    //   userName: user.fullname,
    //   createdDate: Timestamp.now(),
    //   userPhotoURL: user?.photoURL,
    // });
    let newMessage;
    if (typeOfUser === "DOCTOR" && currentPatient) {
      newMessage = {
        description: input,
        sentBy: user.email,
        userName: user.fullname,
        createdDate: Timestamp.now(),
        userPhotoURL: user?.photoURL,
        patientId: currentPatient?.patientId,
        doctorId: currentDoctor?.doctorId,
      };
    } else if (typeOfUser === "PATIENT" && currentDoctor) {
      newMessage = {
        description: input,
        sentBy: user.email,
        userName: user.fullname,
        createdDate: Timestamp.now(),
        userPhotoURL: user?.photoURL,
        patientId: user?.uid,
        doctorId: currentDoctor?.doctorId,
      };
    }
    setMessages([...messages, newMessage]);

    if (typeOfUser === "DOCTOR" && currentPatient) {
      const params = {
        title: "Healthcare Professional has sent you a message",
        description: input,
        notifyTo: currentPatient?.patientInfo?.email,
        sentBy: user?.email,
        userName: user?.fullname,
        userPhotoURL: user?.photoURL,
      };

      const message = {
        infoMessage: btoa(JSON.stringify(params)),
        notificationSource: "doctorMessage",
        userId: user?.uid,
        isEncrypted: false,
        readStatus: false,
        createdDate: Timestamp.now(),
      };
      await addDoc(
        collection(
          FirestoreDB,
          "notifications",
          currentPatient?.patientId,
          "messages"
        ),
        message
      );
      toast.success("Message sent successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (typeOfUser === "PATIENT" && currentDoctor) {
      if (currentDoctor) {
        const params = {
          title: "Patient has sent you a message",
          description: input,
          notifyTo: currentDoctor?.email,
          sentBy: user?.email,
          userName: user?.fullname,
          userPhotoURL: user?.photoURL,
        };

        const message = {
          infoMessage: btoa(JSON.stringify(params)),
          notificationSource: "patientMessage",
          userId: user?.uid,
          isEncrypted: false,
          readStatus: false,
          createdDate: Timestamp.now(),
        };

        await addDoc(
          collection(
            FirestoreDB,
            "notifications",
            currentDoctor?.doctorId,
            "messages"
          ),
          message
        );
        toast.success("Message sent successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
    setInput("");
    setInputError("");
    // await fetchMessages(false); // Call fetchMessages without showing the loader
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
    setInputError("");
  };

  // useEffect(() => {
  //   // If local message exists, add it to messages state and reset local message
  //   if (localMessage) {
  //     setMessages([...messages, localMessage]);
  //     setLocalMessage(null);
  //   }
  // }, [localMessage]); // Run this effect when localMessage changes

  const filteredPatients = patientData?.filter((patient) =>
    patient?.patientInfo?.fullname
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const patientsWithUnread = filteredPatients?.filter((item) => {
    // First, filter patients with unread counts
    return unreadCounts.some(
      (countItem) =>
        countItem.patientId === item.patientId && countItem.count > 0
    );
  });

  const patientsWithoutUnread = filteredPatients?.filter((item) => {
    // Then, filter patients without unread counts
    return !unreadCounts.some(
      (countItem) =>
        countItem.patientId === item.patientId && countItem.count > 0
    );
  });

  const sortedFilteredPatients = patientsWithUnread?.concat(
    patientsWithoutUnread
  );

  const filteredDoctors = user?.doctorDetails?.filter((doctor) =>
    doctor?.fullname?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // First, filter doctors with unread countssetUnreadCountssetUnreadCounts
  const doctorsWithUnread = filteredDoctors?.filter((doc) => {
    return unreadCounts.some(
      (countItem) => countItem.doctorId === doc.doctorId && countItem.count > 0
    );
  });

  // Then, filter doctors without unread counts
  const doctorsWithoutUnread = filteredDoctors?.filter((doc) => {
    return !unreadCounts.some(
      (countItem) => countItem.doctorId === doc.doctorId && countItem.count > 0
    );
  });

  // Concatenate the two filtered arrays, with doctors having unread counts first
  const sortedFilteredDoctors = doctorsWithUnread?.concat(doctorsWithoutUnread);

  useEffect(() => {
    if (scroll.current) {
      scroll.current.scrollTop = scroll.current.scrollHeight;
    }
  }, [messages]);

  if (loading) {
    return <Progress />;
  }

  const chatWithUnreadCount = unreadCounts.filter(
    (item) => item.count > 0
  ).length;
  const groupChatWithUnreadCount = unreadCountsForGroup.filter(
    (item) => item.count > 0
  ).length;

  const capitalizeWords = (name) => {
    return name
      ?.split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  return (
    <MainLayout>
      <UnreadMessageCounts
        unreadCounts={unreadCounts}
        setUnreadCounts={setUnreadCounts}
        user={user}
        typeOfUser={typeOfUser}
        patientData={patientData}
      />

      <Box className="fullWidth fullHeight messaging">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card
              className="flexBox flexColumn"
              sx={{
                height: "89.5vh",
              }}
            >
              <CardHeader
                sx={{ p: 0 }}
                title={
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    centered
                    textColor="secondary"
                    indicatorColor="secondary"

                    // className="tabs"
                  >
                    <Tab
                      label={
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Chat />
                          <Typography
                            sx={{ marginLeft: 1, textTransform: "capitalize" }}
                          >
                            Chats
                          </Typography>
                          {chatWithUnreadCount > 0 && (
                            <Chip
                              label={chatWithUnreadCount}
                              size="small"
                              sx={{
                                backgroundColor: "var(--clr-theme-purple)",
                                color: "var(--clr-theme-white)",
                              }}
                            />
                          )}
                        </Box>
                      }
                      iconPosition="start"
                      sx={{ marginRight: 12, textTransform: "capitalize" }}
                    />

                    <Tab
                      label={
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Groups2 />
                          <Typography
                            sx={{ marginLeft: 1, textTransform: "capitalize" }}
                          >
                            Group Chats
                          </Typography>
                          {groupChatWithUnreadCount > 0 && (
                            <Chip
                              label={groupChatWithUnreadCount}
                              size="small"
                              sx={{
                                backgroundColor: "var(--clr-theme-purple)",
                                color: "var(--clr-theme-white)",
                              }}
                            />
                          )}
                        </Box>
                      }
                      iconPosition="start"
                      // icon={<Groups2 />}
                      sx={{ marginRight: 2, textTransform: "capitalize" }}
                    />
                  </Tabs>
                }
              />

              <CardContent>
                {selectedTab === 0 && (
                  <>
                    <Box>
                      <TextField
                        placeholder="Search patient"
                        fullWidth
                        sx={{ borderRadius: "10px" }}
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setErrorMessage("");
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment>
                              <IconButton>
                                <Search />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <List
                      component="div"
                      disablePadding
                      sx={{
                        overflowY: "auto",
                        flexGrow: 1,
                        mt: 1,
                      }}
                    >
                      {typeOfUser === "DOCTOR"
                        ? sortedFilteredPatients?.map((item, i) => (
                            <ListItemButton
                              sx={{
                                borderBottom: "1px solid lightgray",
                                height: 75,
                              }}
                              key={i}
                              className={
                                selectedPatientId === item.patientId
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => showPatientConversation(item)}
                            >
                              <ListItemIcon>
                                {item.icon ||
                                  (item?.patientInfo?.photoURL && (
                                    <Avatar src={item?.patientInfo?.photoURL} />
                                  )) || <Avatar />}
                              </ListItemIcon>

                              <ListItemText
                                primary={
                                  <BootstrapTooltip
                                    title={
                                      item?.patientInfo?.parentName
                                        ? `${item.patientInfo.parentName} (${item.patientInfo.fullname})`
                                        : item.patientInfo.fullname
                                    }
                                  >
                                    <span style={{ cursor: "pointer" }}>
                                      {" "}
                                      {/* Add a span to wrap the text */}
                                      {item?.patientInfo?.parentName
                                        ? `${truncateName(
                                            item.patientInfo.parentName
                                          )} (${truncateName(
                                            item.patientInfo.fullname
                                          )})`
                                        : truncateName(
                                            item.patientInfo.fullname
                                          )}
                                    </span>
                                  </BootstrapTooltip>
                                }
                              />

                              {unreadCounts &&
                                unreadCounts?.map((countItem) => {
                                  if (countItem.patientId === item.patientId) {
                                    return (
                                      countItem.count > 0 && (
                                        <Chip
                                          label={countItem.count}
                                          sx={{
                                            backgroundColor:
                                              "var(--clr-theme-purple)",
                                            color: "var(--clr-theme-white)",
                                          }}
                                        />
                                      )
                                    );
                                  }
                                  return null;
                                })}
                            </ListItemButton>
                          ))
                        : sortedFilteredDoctors?.map((doc, i) => {
                            return (
                              <ListItemButton
                                sx={{
                                  borderBottom: "1px solid lightgray",
                                  height: 75,
                                }}
                                key={i}
                                // className={
                                //   selectedPatientId === item.patientId
                                //     ? "selected"
                                //     : ""
                                // }
                                onClick={() => showDoctorConversation(doc)}
                              >
                                <ListItemIcon>
                                  {doc.icon ||
                                    (doc?.photoURL && (
                                      <Avatar src={doc?.photoURL} />
                                    )) || <Avatar />}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    doc?.fullname
                                      ? `${doc?.fullname
                                          .split(/\s+/)
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1).toLowerCase()
                                          )
                                          .join(" ")}`
                                      : ""
                                  }
                                  sx={{}}
                                />
                                {unreadCounts &&
                                  unreadCounts?.map((countItem) => {
                                    if (countItem?.doctorId === doc?.doctorId) {
                                      return (
                                        countItem?.count > 0 && (
                                          <Chip
                                            label={countItem?.count}
                                            color="secondary"
                                          />
                                        )
                                      );
                                    }
                                    return null;
                                  })}
                              </ListItemButton>
                            );
                          })}
                    </List>
                  </>
                )}
                {selectedTab === 1 && (
                  <>
                    <List>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: "var(--clr-theme-purple)!important",
                          color: "var(--clr-theme-white)",
                          textTransform: "capitalize",
                          mb: 1,
                          // border: "1px solid var(--clr-theme-purple)",
                        }}
                        startIcon={<Groups />}
                        onClick={handleClickDialogOpen}
                      >
                        Create Group
                      </Button>
                      {groups?.map((group) => (
                        <>
                          <ListItemButton
                            sx={{
                              borderBottom: "1px solid lightgray",
                              height: 75,
                            }}
                            key={group.groupId}
                            onClick={() => showGroupConversation(group)}
                          >
                            <ListItemIcon>
                              <Groups2 />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                group.groupName
                                  ? group.groupName
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1).toLowerCase()
                                      )
                                      .join(" ")
                                  : ""
                              }
                              sx={{}}
                            />
                            {unreadCountsForGroup &&
                              unreadCountsForGroup?.map((countItem) => {
                                if (countItem.groupId === group.groupId) {
                                  return (
                                    countItem.count > 0 && (
                                      <Chip
                                        label={countItem.count}
                                        sx={{
                                          backgroundColor:
                                            "var(--clr-theme-purple)",
                                          color: "var(--clr-theme-white)",
                                        }}
                                      />
                                    )
                                  );
                                }
                                return null;
                              })}
                          </ListItemButton>
                        </>
                      ))}
                    </List>

                    <Dialog
                      open={openDialog}
                      onClose={handleDialogClose}
                      maxWidth="lg"
                    >
                      <DialogTitle>
                        <TextField
                          fullWidth
                          label="Enter group name"
                          variant="outlined"
                          value={groupName}
                          onChange={handleGroupNameChange}
                          // sx={{ mb: 2 }}
                          error={!!errorMessage}
                          helperText={errorMessage}
                        />
                      </DialogTitle>
                      <DialogContent sx={{ width: "42vw" }}>
                        {typeOfUser === "DOCTOR"
                          ? patientData?.map((item, i) => (
                              <ListItemButton
                                sx={{
                                  borderBottom: "1px solid lightgray",
                                  height: 75,
                                }}
                                key={i}
                                className={
                                  selectedPatientId === item.patientId
                                    ? "selected"
                                    : ""
                                }
                              >
                                <ListItemIcon>
                                  {item.icon ||
                                    (item?.patientInfo?.photoURL && (
                                      <Avatar
                                        src={item?.patientInfo?.photoURL}
                                      />
                                    )) || <Avatar />}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <BootstrapTooltip
                                      title={
                                        item?.patientInfo?.parentName
                                          ? `${item.patientInfo.parentName} (${item.patientInfo.fullname})`
                                          : item.patientInfo.fullname
                                      }
                                    >
                                      <span style={{ cursor: "pointer" }}>
                                        {" "}
                                        {/* Add a span to wrap the text */}
                                        {item?.patientInfo?.parentName
                                          ? `${truncateName(
                                              item.patientInfo.parentName
                                            )} (${truncateName(
                                              item.patientInfo.fullname
                                            )})`
                                          : truncateName(
                                              item.patientInfo.fullname
                                            )}
                                      </span>
                                    </BootstrapTooltip>
                                  }
                                />
                                <Button
                                  onClick={() =>
                                    handlePatientCheckboxChange({
                                      id: item.patientId,
                                      name: item.patientInfo?.fullname,
                                      parentName:
                                        item.patientInfo?.parentName || "",
                                      photoURL: item.patientInfo?.photoURL,
                                      typeOfUser: "PATIENT",
                                    })
                                  }
                                  variant={
                                    selectedPatients.find(
                                      (member) => item.patientId === member?.id
                                    )
                                      ? "contained"
                                      : "outlined"
                                  }
                                  sx={{
                                    bgcolor: selectedPatients.find(
                                      (member) => item.patientId === member?.id
                                    )
                                      ? "green"
                                      : "transparent",
                                    color: selectedPatients.find(
                                      (member) => item.patientId === member?.id
                                    )
                                      ? "white"
                                      : "primary.main",
                                    "&:hover": {
                                      bgcolor: selectedPatients.find(
                                        (member) =>
                                          item.patientId === member?.id
                                      )
                                        ? "green"
                                        : "transparent",
                                      // Set color when hovered (optional)
                                      color: selectedPatients.find(
                                        (member) =>
                                          item.patientId === member?.id
                                      )
                                        ? "white"
                                        : "primary.main",
                                    },
                                  }}
                                >
                                  {selectedPatients.find(
                                    (member) => item.patientId === member?.id
                                  )
                                    ? "Add"
                                    : "Add"}
                                </Button>
                              </ListItemButton>
                            ))
                          : sortedFilteredDoctors?.map((doc, i) => {
                              return (
                                <ListItemButton
                                  sx={{
                                    borderBottom: "1px solid lightgray",
                                    height: 75,
                                  }}
                                  key={doc?.doctorId}
                                >
                                  <ListItemIcon>
                                    {doc.icon ||
                                      (doc?.photoURL && (
                                        <Avatar src={doc?.photoURL} />
                                      )) || <Avatar />}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      doc?.fullname
                                        ? `${doc?.fullname
                                            .split(/\s+/)
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1).toLowerCase()
                                            )
                                            .join(" ")}`
                                        : ""
                                    }
                                  />
                                  <Button
                                    onClick={() => {
                                      console.log(" DOC : ", doc);
                                      handlePatientCheckboxChange({
                                        id: doc.doctorId,
                                        name: doc?.fullname,
                                        photoURL: doc?.photoURL,
                                        typeOfUser: "DOCTOR",
                                      });
                                    }}
                                    variant={
                                      selectedPatients.find(
                                        (member) => doc.doctorId === member?.id
                                      )
                                        ? "contained"
                                        : "outlined"
                                    }
                                    sx={{
                                      bgcolor: selectedPatients.find(
                                        (member) => doc.doctorId === member?.id
                                      )
                                        ? "green"
                                        : "transparent",
                                      color: selectedPatients.find(
                                        (member) => doc.doctorId === member?.id
                                      )
                                        ? "white"
                                        : "primary.main",
                                      "&:hover": {
                                        bgcolor: selectedPatients.find(
                                          (member) =>
                                            doc.patientId === member?.id
                                        )
                                          ? "green"
                                          : "transparent",
                                        // Set color when hovered (optional)
                                        color: selectedPatients.find(
                                          (member) =>
                                            doc.patientId === member?.id
                                        )
                                          ? "white"
                                          : "primary.main",
                                      },
                                    }}
                                  >
                                    {selectedPatients.find(
                                      (member) => doc.doctorId === member?.id
                                    )
                                      ? "Add"
                                      : "Add"}
                                  </Button>
                                </ListItemButton>
                              );
                            })}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleDialogClose}
                          sx={{
                            color: "var(--clr-theme-purple)!important",
                            textTransform: "capitalize",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          onClick={handleCreateGroup}
                          variant="contained"
                          color="primary"
                          sx={{
                            backgroundColor:
                              "var(--clr-theme-purple)!important",
                            textTransform: "capitalize",
                            fontFamily: "Lato",
                            fontSize: "1rem",
                          }}
                        >
                          Create Group
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Conversations
              onChatNameUpdate={(newName) =>
                setSelectedChat({ ...selectedChat, groupName: newName })
              }
              key={
                selectedChat?.patientId ||
                selectedChat?.groupId ||
                selectedChat?.doctorId
              }
              chatId={
                selectedChat?.patientId ||
                selectedChat?.groupId ||
                selectedChat?.doctorId
              }
              chatPhotoURL={
                selectedChat?.patientInfo?.photoURL || selectedChat?.photoURL
              }
              chatName={
                selectedChat?.patientInfo?.parentName
                  ? `${
                      selectedChat?.patientInfo?.parentName
                        ? selectedChat.patientInfo.parentName
                        : ""
                    } (${selectedChat.patientInfo.fullname
                      .split(/\s+/)
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      )
                      .join(" ")})`
                  : selectedChat?.patientInfo?.fullname ||
                    selectedChat?.groupName ||
                    selectedChat?.fullname
              }
              isGroupChat={selectedChat?.groupName ? true : false}
              chatUserInfo={selectedChat}
              navigateProfile={goToPatient}
            />
            {/* <Card sx={{ height: "89.5vh" }}>
              <CardHeader
                avatar={
                  <Avatar
                    src={
                      currentPatient?.patientInfo?.photoURL ||
                      currentDoctor?.photoURL
                    }
                    aria-label="recipe"
                  />
                }
                title={
                  currentPatient?.patientInfo?.fullname
                    .split(/\s+/)
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ") ||
                  currentDoctor?.fullname
                    .split(/\s+/)
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ") ||
                  currentDoctor?.groupName
                    .split(/\s+/)
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")
                }
                subheader={
                  typeOfUser === "DOCTOR" && (
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline", cursor: "pointer" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                        onClick={() => goToPatient(currentPatient)}
                      >
                        View profile
                      </Typography>
                    </React.Fragment>
                  )
                }
                action={
                  <>
                    {typeOfUser === "DOCTOR" && (
                      <>
                        <Button
                          sx={{
                            backgroundColor:
                              "var(--clr-theme-purple)!important",
                            color: "white",
                            textTransform: "capitalize",
                            width: "240px",
                          }}
                          onClick={handleClickOpen}
                        >
                          Set Reminder
                        </Button>
                        <Dialog
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={open}
                          maxWidth="lg"
                        >
                          <AppBar
                            sx={{
                              position: "relative",
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            }}
                          >
                            <Toolbar>
                              <Typography
                                sx={{
                                  flex: 1,
                                  color: "black",
                                  fontSize: "1.8rem",
                                  fontFamily: "Lato",
                                }}
                                variant="h6"
                                component="div"
                              >
                                Reminders
                              </Typography>
                              <Button
                                autoFocus
                                color="inherit"
                                onClick={handleClose}
                              >
                                <Close sx={{ color: "black" }} />
                              </Button>
                            </Toolbar>
                          </AppBar>
                          <DialogContent sx={{ width: "42vw" }}>
                            <Reminders currentPatient={currentPatient} />
                          </DialogContent>
                        </Dialog>
                      </>
                    )}
                  </>
                }
              />
              <Divider sx={{ border: "1px solid lightgray" }} />

              <CardContent>
                <Box
                  sx={{
                    height: "79vh",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{ flexGrow: 1, overflow: "auto", p: 2 }}
                    ref={scroll}
                  >
                    {loading ? (
                      <CircularProgress
                        size={"5rem"}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          background: "transparent !important",
                          color: "var(--clr-theme-purple) !important",
                        }}
                      />
                    ) : (
                      messages?.map((message, index) => (
                        <Chatconversation
                          key={index}
                          message={message}
                          typeOfUser={typeOfUser}
                          user={user}
                        />
                      ))
                    )}
                    {messages && (
                      <Chatconversation
                        message={messages}
                        typeOfUser={typeOfUser}
                        user={user}
                      />
                    )}
                  </Box>
                  <form onSubmit={SaveNoteHandler}>
                    <Box sx={{ p: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={9}>
                          <TextField
                            size="small"
                            fullWidth
                            placeholder="Type a message..."
                            variant="outlined"
                            value={input}
                            onChange={handleInputChange}
                            error={!!inputError}
                            helperText={inputError}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              backgroundColor:
                                "var(--clr-theme-purple) !important",
                            }}
                            endIcon={<Send />}
                          ></Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                </Box>
              </CardContent>
            </Card> */}
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

const Chatconversation = ({ message, typeOfUser, user }) => {
  if (!message.description) {
    return null;
  }

  console.log("messagessssssssssssssssssssssssssssssss", message);

  // Determine if the message is from the doctor or the patient
  const isDoctorMessage = message?.notificationSource === "doctorMessage";
  const isPatientMessage = message?.notificationSource === "patientMessage";

  // Determine the alignment of the message content
  // const contentAlignment = isDoctorMessage ? "flex-end" : "flex-start";
  const isCurrentUserMessage =
    (typeOfUser === "DOCTOR" && message.sentBy === user.email) ||
    (typeOfUser === "PATIENT" && message.sentBy === user.email);

  // Determine the alignment of the message content
  const contentAlignment = isCurrentUserMessage ? "flex-end" : "flex-start";

  // Convert createdDate to a human-readable format
  const formattedDate = new Date(
    message?.createdDate?.seconds * 1000 ||
      message?.createdDate?._seconds * 1000
  ).toLocaleString();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isCurrentUserMessage ? "row-reverse" : "row",
        alignItems: contentAlignment,
        padding: "10px",

        marginBottom: 5,
      }}
    >
      <img
        src={
          message?.userPhotoURL ||
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
        }
        alt="User Avatar"
        style={{ width: "30px", height: "30px", borderRadius: "50%" }}
      />
      <div
        style={{
          marginLeft: "10px",
          width: "70%",
          backgroundColor: "var(--clr-theme-purple-20)",
          padding: "10px",
          borderRadius: isDoctorMessage
            ? "20px 5px 20px 20px"
            : "20px 20px 20px 5px",
        }}
      >
        <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
          {message?.userName
            .split(/\s+/)
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ") || "NA"}
        </p>
        <p style={{ color: "#454545", margin: "0" }}>{message?.description}</p>
        <p
          style={{
            fontSize: "12px",
            color: "#999",
            margin: "0",
            marginTop: "5px",
          }}
        >
          {formattedDate}
        </p>
      </div>
    </div>
  );
};

export default Message;
