import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";
import DataProvider from "../contexts/DataProvider";
import { FirebaseAuth } from "../utils/firebase/firebase";
import { UIContext } from "../contexts/UIProvider";

const PrivateRoute = ({ children }) => {
  const { isUserAuthenticated, login, logout } = useContext(AuthContext);
  const [initialNavigation, setInitialNavigation] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!initialNavigation) {
  //     navigate("/");
  //     setInitialNavigation(true);
  //   }
  // }, [initialNavigation, navigate,]);

  useEffect(() => {
    if (!initialNavigation) {
      if (
        !isUserAuthenticated &&
        // FirebaseAuth.currentUser ||
        !validateSession()
      ) {
        // If the user is authenticated and the path is "/", redirect to homepage
        navigate("/main");
      }
      // else {
      //   // If not authenticated, redirect to the login or main page
      //   navigate("/main");
      // }
      setInitialNavigation(true);
    }
  }, [initialNavigation, navigate, isUserAuthenticated]);

  const validateSession = () => {
    const itemStr = localStorage.getItem("session");
    if (!itemStr) {
      return false;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem("session");
      logout(); // Clear session if expired
      return false;
    }
    return true;
  };
  
  return isUserAuthenticated ||
    // FirebaseAuth.currentUser ||
    // localStorage.getItem('authenticated') ||
    validateSession() ? (
    <>
      <DataProvider>{children}</DataProvider>
    </>
  ) : (
    <>
      <Navigate to={"/main"} />
    </>
  );
};

export default PrivateRoute;
