import React, { useContext } from "react";
import "../Footer/Footer.css";
import {
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import FacebookIcon from "../SocialLinks/Facebook";
import TwitterIcon from "../SocialLinks/Twitter";
import LinkedInIcon from "../SocialLinks/LinkedIn";
import OurStory from "../OurStory";
import OurTeam from "../OurTeam";
import Contact from "../Contact";
import Blog from "../Blog";
import MenuContext from "../../../contexts/MenuContext";
import { useNavigate } from "react-router-dom";
import PrivacyLandingpage from "../PrivacyLandingpage";
import TermconditionLandingpage from "../TermconditionLandingpage";

const Footer = (props) => {
  const { setCurrentPage, setActiveButton } = useContext(MenuContext);

  const navigate = useNavigate();

  const getCurrentYear = () => new Date().getFullYear();

  const handleFootermenuclick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handlePrivacy = () => {
    navigate('/privacy-policy');
  }

  const handleTermsConditions = () => {
    navigate('/terms-and-conditions');
  }

  return (
    <Box className="footer fullWidth">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        padding={"1.3rem 3.8rem"}
      >
        <List
          className="footerCss"
          subheader={
            <Typography variant="h6" className="footerGrid">
              Mindly
            </Typography>
          }
        >
          <ListItem
            disablePadding
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentPage(<OurStory />);
              handleFootermenuclick("about");
            }}
          >
            <ListItemText primary="About" />
          </ListItem>
          <ListItem
            disablePadding
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentPage(<OurTeam />);
              handleFootermenuclick("team");
            }}
          >
            <ListItemText primary="Team" />
          </ListItem>
          <ListItem
            disablePadding
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentPage(<Blog />);
              handleFootermenuclick("blog");
            }}
          >
            <ListItemText primary="Blog" />
          </ListItem>
          
          <ListItem
            disablePadding
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentPage(<Contact />);
              handleFootermenuclick("Contact");
            }}
          >
            <ListItemText primary="Contact Us" />
          </ListItem>
        </List>

        <List
          subheader={
            <Typography variant="h6" className="footerGrid">
              Connect
            </Typography>
          }
        >
          <ListItem disablePadding>
            <ListItemText primary="1138 Desmond Ct" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="Fremont CA, 94539" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="support@mindly.care" />
          </ListItem>
        </List>
        <List
          subheader={
            <Typography variant="h6" className="footerGrid">
              Follow us
            </Typography>
          }
        >
          <Box className="flexBox flexRow" sx={{ gap: 2, fontSize: "1.7rem" }}>
            <ListItem disablePadding>
              <FacebookIcon />
            </ListItem>
            <ListItem disablePadding>
              <TwitterIcon size={28} />
            </ListItem>
            <ListItem disablePadding>
              <LinkedInIcon />
            </ListItem>
          </Box>
        </List>
      </Grid>
      <Box
        className="flexBox"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "2rem 3.8rem",
          fontFamily: "Karla",
          lineHeight: "1.5rem",
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <span className="text">© {getCurrentYear()} All rights reserved</span>
        <span className="text" style={{ cursor: "pointer" }}>
          <Button
            sx={{
              color: "black",
              textTransform: "capitalize",
              fontFamily: "Lato",
              fontSize: "1.1rem",
            }}
            onClick={handlePrivacy}
            // onClick={() => {
            //   setCurrentPage(<PrivacyLandingpage footerRequired={true} />);
            // }}
          >
            Privacy Policy
          </Button>
          /
          <Button
            sx={{
              color: "black",
              textTransform: "capitalize",
              fontFamily: "Lato",
              fontSize: "1.1rem",
            }}
            // onClick={() => {
            //   setCurrentPage(
            //     <TermconditionLandingpage footerRequired={true} />
            //   );
            // }}
            onClick={handleTermsConditions}
          >
            Term and Conditions
          </Button>
        </span>
      </Box>
    </Box>
  );
};

export default Footer;
